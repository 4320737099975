import { Popup, Text } from "@prosapient/prosapient-styleguide"
import { MIcon } from "../../../shared/MIcon/MIcon"
import { PopupPlacement } from "@prosapient/prosapient-styleguide/dist/components/shared/Popup"
import styled from "styled-components"

const DefaultContent = ({ text = "", children }: { text?: string; children?: React.ReactNode }) => (
  <Text fontWeight={400} fontSize={5}>
    {text || children}
  </Text>
)

export const StyledPopup = styled(Popup)`
  & > *:first-child {
    margin-left: ${props => ((props.placement || "").includes("left") ? "-10px" : "0")};
    margin-right: ${props => ((props.placement || "").includes("right") ? "-10px" : "0")};
  }
`

type Props = {
  width?: number | string | undefined
  placement?: PopupPlacement
  text?: string
  content?: (props: { isOpen: boolean; toggle?: () => void }) => React.ReactNode
}

export const ToolTip = ({ width = "200px", placement = "top-left", text = "", content }: Props) => {
  const _content = content || (() => <DefaultContent text={text} />)

  return (
    <StyledPopup
      width={width}
      placement={placement}
      onClick={e => e.preventDefault()}
      contentStyle={{
        overflowY: "auto",
        padding: "14px 12px",
        whiteSpace: "break-spaces",
      }}
      content={_content}
      maxWidth="90vw"
    >
      <MIcon
        v={2}
        icon="info"
        color="beta.300"
        hoverColor="beta.700"
        style={{ cursor: "pointer", transition: ".2s ease-io-out" }}
      />
    </StyledPopup>
  )
}
