import { Box, Dropdown, DropdownItem } from "@prosapient/prosapient-styleguide"
import { useMe } from "hooks"
import { ROUTES } from "components/constants"
import { useHistory } from "react-router"
import { useMemo } from "react"
import styled from "styled-components"

const StyledDropdown = styled(Dropdown)`
  font-weight: 400;

  & + * {
    right: -2rem;
  }
`

export const UserDropdown = () => {
  const { push } = useHistory()
  const { me } = useMe()

  const userName = useMemo(() => {
    return [me?.firstName || "", me?.lastName || ""].filter(s => !!s.trim()).join(" ")
  }, [me])

  const handleLogout = () => {
    // @ts-ignore
    window.location = ROUTES.authLogout
  }

  return (
    <Box ml="auto">
      <StyledDropdown text={userName} placement="bottom-left">
        <DropdownItem onClick={() => push(ROUTES.myProfile)}>My Profile</DropdownItem>
        <DropdownItem onClick={handleLogout}>Log Out</DropdownItem>
      </StyledDropdown>
    </Box>
  )
}
