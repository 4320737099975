import { Label } from "@prosapient/prosapient-styleguide"
import styled, { css } from "styled-components"

export const StyledFormLabel = styled(Label)<{ disabled?: boolean }>`
  font-size: ${props => props.theme.newFontSizes[6]};
  font-weight: 600;
  margin-bottom: ${props => props.theme.newSpace[2]};

  ${props =>
    props.disabled &&
    css`
      &,
      * {
        color: ${props => props.theme.colors.beta[400]};
      }
    `}

  ${props =>
    props.invalid &&
    css`
      &,
      * {
        color: ${props => props.theme.colors.delta[600]};
      }
    `}
`
