import { useMe } from "hooks"
import { FieldView } from "./FieldView"
import { ActionPanel } from "./ActionPanel"
import { Contacts } from "./Contacts"

type IProps = {
  isMobile: boolean
  onClose: () => void
}

export const ViewInfo = ({ isMobile, onClose }: IProps) => {
  const { me } = useMe()

  return (
    <>
      <FieldView label="Contacts" subLabel={<Contacts />} />

      {/*<FieldView label="Languages" subLabel="No languages" />*/}

      <FieldView label="Expertise" subLabel="Your expertise" tags={me?.expertise} />

      <FieldView label="Industry" subLabel="Industries of expertise" tags={me?.industries} />

      <ActionPanel isMobile={isMobile} onClose={onClose} />
    </>
  )
}
