import { Route, Switch } from "react-router-dom"
import { Box, Button, VStack } from "@prosapient/prosapient-styleguide"
import { ROUTES } from "components/constants"
import { useHistory } from "react-router"
import { PageContainer } from "components/shared/Layout/PageContainer"
import { Header } from "components/shared/Header"
import { DocumentsOverview } from "./@overview"
import { AuthGuard } from "hocs/AuthGuard"
import { IAuthTokenType } from "../../api/__generated__/schema"

const routes = [{ path: ROUTES.documents, title: "Documents", component: DocumentsOverview }]

export const Documents = () => {
  const { push } = useHistory()

  return (
    <AuthGuard tokenTypes={[IAuthTokenType.Auth]}>
      <Header navbar />

      <Switch>
        {routes.map(({ path, title, component }) => (
          <Route key={title} path={path} component={component} />
        ))}

        <Route path="*">
          <PageContainer hasBackground={false}>
            <VStack p={10}>
              {routes.map(({ path, title }) => (
                <Box key={title} my={6} style={{ textAlign: "center" }}>
                  <Button onClick={() => push(path)}>{title}</Button>
                </Box>
              ))}
            </VStack>
          </PageContainer>
        </Route>
      </Switch>
    </AuthGuard>
  )
}
