import { Paragraph, SubTitle, Title } from "../../../../shared/Layout"
import { Box, Text } from "@prosapient/prosapient-styleguide"
import { RecentEmploymentType } from "../../types"

type IProps = { employment: RecentEmploymentType }

export const Single = ({ employment }: IProps) => {
  return (
    <>
      <Title mb={0} textAlign="center">
        {employment.position}
      </Title>

      <Box>
        <SubTitle mt={0} mb={6}>
          <Text color="beta.600" inline fontWeight={1} children="at" /> {employment.company}
        </SubTitle>

        {employment.displayDate && <Paragraph m={0}>{employment.displayDate}</Paragraph>}
      </Box>
    </>
  )
}
