import React from "react"
import { FIcon, Text } from "@prosapient/prosapient-styleguide"
import styled from "styled-components"
import { components } from "react-select"
import slugify from "slugify"

const StyledInputFile = styled.label`
  input {
    opacity: 0;
    position: absolute;
    left: -1000em;
  }
`

export const InputFile = ({
  whiteListedExtensions = [],
  ...props
}: React.ComponentPropsWithRef<"input"> & { whiteListedExtensions?: string[] }) => {
  const formattedWhiteListedExtensions = whiteListedExtensions.map(ext => `.${ext}`).join(",")

  return (
    <StyledInputFile>
      <input {...props} accept={formattedWhiteListedExtensions} type="file" />
      <span>
        <FIcon cursor="pointer" color="beta.700" hoverColor="alpha.800" icon="paperclip" size="lg" mr={2} />
      </span>
    </StyledInputFile>
  )
}

export const ReactSelectPasteInput = (props: any) => {
  const onPaste = (event: any) => {
    const value = event.clipboardData.getData("text").replaceAll("\n", ";")
    event.currentTarget.value = value
    props.onChange(event)
    event.preventDefault()
  }

  return <components.Input {...props} onPaste={onPaste} />
}

const GroupHeading = styled(components.GroupHeading)`
  padding: 0 10px !important;
  font-weight: 500 !important;
  color: ${props => props.theme.colors.beta["600"]} !important;
  margin: 6px 0 !important;
`

export const ReactSelectGroupHeading = (props: any) => {
  return <GroupHeading {...props} />
}

const Group = styled(components.Group)`
  padding: 0 !important;
`

export const ReactSelectGroup = (props: any) => {
  return <Group {...props} />
}

const Option = styled(components.Option)<{ fontWeight?: number; isFocused?: boolean }>`
  font-weight: ${({ fontWeight }) => fontWeight || 400} !important;

  span {
    background-color: ${props => (props.isFocused ? "initial" : "#8EFFC0")}!important;
  }
`
// background-color: ${props => (props.isFocused ? "initial" : props.theme.colors.alpha["200"])}!important;

export const ReactSelectOption = (props: any) => {
  const str = props.data.label ?? ""
  const input = props.selectProps.inputValue ?? ""

  const highlightStartIndex = slugify(str)
    .toLowerCase()
    .indexOf(slugify(input).toLowerCase())
  const highlightEndIndex = highlightStartIndex + input.length

  return (
    <Option isFocused={props.isFocused} fontWeight={props.data.isBold ? 600 : undefined} {...props}>
      <Text>
        {highlightStartIndex >= 0 ? (
          <>
            {str.substring(0, highlightStartIndex)}
            <span>{str.substring(highlightStartIndex, highlightEndIndex)}</span>
            {str.substring(highlightEndIndex)}
          </>
        ) : (
          str
        )}
      </Text>
    </Option>
  )
}
