import styled from "styled-components"
import { Box, Flex, Image } from "@prosapient/prosapient-styleguide"
import { Paragraph } from "components/shared/Layout"
import ImageBG from "accets/images/projects.svg"

const StyledParagraph = styled(Paragraph).attrs({ px: 12, my: 10 })`
  @media only screen and (max-width: 640px) {
    font-weight: bold;
    padding: 0;
  }
`

export const EmptyList = ({ children }: { children: any }) => (
  <Flex height="calc(100% - 62px)">
    <Box m="auto" p={10} maxWidth={640} width="100%" style={{ textAlign: "center" }}>
      <Image src={ImageBG} alt="Invitation declined image" width="100%" />

      <StyledParagraph>{children}</StyledParagraph>
    </Box>
  </Flex>
)
