import { Box } from "@prosapient/prosapient-styleguide"
import { RecentEmploymentType } from "../../types"
import { Single } from "./Single"
import { ListItem } from "./ListItem"

type IProps = { employments: RecentEmploymentType[] }

export const Employments = ({ employments }: IProps) => {
  if (employments.length === 1) return <Single employment={employments[0]} />

  return (
    <>
      {employments?.map((item, idx) => (
        <Box key={idx}>
          {idx > 0 && <Box width={160} height="1px" backgroundColor="#D4D2DA" my="0.85rem" mx="auto" />}

          <ListItem employment={item} />
        </Box>
      ))}
    </>
  )
}
