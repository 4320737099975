// Redesigned version of shared/LocationsSelect
import { Select as UIKitSelect, SelectOption, SelectProps as UIKitSelectProps } from "@prosapient/prosapient-styleguide"
import { useMemo, useState } from "react"
import { ReactSelectOption } from "shared/controls"
import countries from "shared/countryCodes"
import { OnChangeValue, ActionMeta } from "react-select"
import slugify from "slugify"

const locations: SelectOption<string>[] = JSON.parse(JSON.stringify(countries.listAlpha3))

export const LocationsSelect = (props: UIKitSelectProps<SelectOption<string>, false>) => {
  const [search, setSearch] = useState("")

  const resetSearch = () => setSearch("")

  const onChange = (value: OnChangeValue<SelectOption<string>, false>, meta: ActionMeta<SelectOption<string>>) => {
    resetSearch()
    props.onChange && props.onChange(value, meta)
  }

  const options = useMemo(() => {
    return locations.sort(countries.sortByAlphabet).sort(({ label: a }, { label: b }) => {
      return (
        Number(slugify((b || "").toLowerCase()).startsWith(search)) -
        Number(slugify((a || "").toLowerCase()).startsWith(search))
      )
    })
  }, [search, locations])

  const countryCodeAlpha3 =
    props.value?.value.length === 3 ? props.value?.value : locations.find(i => i.label === props.value?.value)?.value

  const value = options.find(({ value }) => value === countryCodeAlpha3) || null

  return (
    <UIKitSelect
      {...props}
      isClearable
      value={value}
      options={options}
      placeholder="Type here..."
      onBlur={resetSearch}
      onMenuClose={resetSearch}
      onChange={props.onChange && onChange}
      onInputChange={newValue => {
        setSearch(newValue.toLowerCase())
      }}
      components={{
        ...props.components,
        Option: ReactSelectOption,
      }}
    />
  )
}
