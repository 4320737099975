import { createContext } from "react"

export type ContextType = {
  displaySpinner: boolean
  showSpinner: () => void
  hideSpinner: () => void
}

export const GlobalSpinnerContext = createContext<ContextType>({
  displaySpinner: false,
  showSpinner: () => {},
  hideSpinner: () => {},
})
