import { SelectOption } from "@prosapient/prosapient-styleguide"

export const splitBySeparator = (value: Readonly<SelectOption<string>[]>) => {
  const nextValue: SelectOption<string>[] = []

  value.forEach(({ value }) => {
    value
      .split(";")
      .filter(str => str.trim().length > 0)
      .forEach(str => {
        if (nextValue.findIndex(i => i.value === str) === -1) {
          nextValue.push({ label: str.trim(), value: str.trim() })
        }
      })
  })

  return nextValue
}
