const useWindowSize = () => {
  window.addEventListener("resize", () => setDocHeight())

  window.addEventListener("orientationchange", () => {
    scrollToTop()
    setDocHeight()
  })

  setDocHeight()

  return {
    calcHeight: setDocHeight,
  }
}

function scrollToTop() {
  setTimeout(() => window.scrollTo(0, 1), 500)
}

function detectMobile(): boolean {
  return "ontouchstart" in document.documentElement || typeof window.orientation !== "undefined"
}

function detectIOSOrSafari(): boolean {
  // https://stackoverflow.com/q/9038625/3569421
  if (
    (!navigator.userAgent.includes("Chrome") && navigator.userAgent.includes("Safari")) ||
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  ) {
    return true
  }

  switch (navigator.platform) {
    case "iPad Simulator":
    case "iPhone Simulator":
    case "iPod Simulator":
    case "iPad":
    case "iPhone":
    case "iPod":
      return true
  }

  return false
}

function setDocHeight() {
  let widthCss, heightCss

  widthCss =
    window.innerWidth && document.documentElement.clientWidth
      ? Math.min(window.innerWidth, document.documentElement.clientWidth)
      : window.innerWidth ||
        document.documentElement.clientWidth ||
        document.getElementsByTagName("body")[0].clientWidth

  heightCss =
    window.innerHeight && document.documentElement.clientHeight
      ? Math.min(window.innerHeight, document.documentElement.clientHeight)
      : window.innerHeight ||
        document.documentElement.clientHeight ||
        document.getElementsByTagName("body")[0].clientHeight

  const isMobile = detectMobile()
  const isIOSSafari = detectIOSOrSafari()

  if (isIOSSafari || isMobile) {
    widthCss = window.innerWidth
    heightCss = window.innerHeight
  }

  document.documentElement.style.setProperty("--vw", `${widthCss}px`)
  document.documentElement.style.setProperty("--vh", `${heightCss}px`)
}

export default useWindowSize
