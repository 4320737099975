import React from "react"
import validatejs from "validate.js"
import { format, parseISO, isAfter, isSameDay } from "date-fns/esm"
import { Field as RField } from "react-final-form"
import * as R from "ramda"
import { AsYouType, parsePhoneNumberFromString, PhoneNumber } from "libphonenumber-js"
import { OnChange } from "react-final-form-listeners"
import { undef } from "utils"

const DEFAULT_OPTIONS = { fullMessages: false, format: "prosapient" }

const parseNumberInput = (value?: string, onlyPositive = false): number | undefined => {
  if (value == null) return undefined
  const parsedValue = parseInt(value, 10)
  if (isNaN(parsedValue)) return undefined
  return onlyPositive ? Math.abs(parsedValue) : parsedValue
}

const parseNullNumberInput = (value?: string): number | null => {
  if (value == null) return null
  const parsedValue = parseInt(value, 10)
  if (isNaN(parsedValue)) return null
  return parsedValue
}

/*
 * Override the default behaviour that doesn't send an input value if it's empty.
 * It allows a user to erase the persisted value.
 *
 * Note: It works only with primitives like strings and numbers.
 * Probably, should be extended to handle other structures.
 *
 * See: https://github.com/final-form/react-final-form/issues/130
 */
export const erasableValueIdentity = (value: any) => (value === "" ? null : value)

const parsePhoneNumberInput = (value?: string): string | null => {
  if (value === "" || value === undefined) {
    return null
  }
  return value
}

const formatPhone = (value: string) => new AsYouType().input(value)

const validatePhone = (value: string, cb?: (result: PhoneNumber) => void) => {
  if (!value) return undefined
  const result = parsePhoneNumberFromString(value.toString())
  if (result?.country && cb) cb(result)
  return result && result!.isValid() ? undefined : "should match format +X XXX XXX XXXX"
}

export const futureDateContraint = (dependantFieldName: string) => ({
  datetime: true,
  equality: {
    attribute: dependantFieldName,
    message: "should be later than starting date",
    comparator: (endsAt: Date, startsAt?: Date) => {
      if (!startsAt) return true
      return isAfter(endsAt, startsAt) || isSameDay(endsAt, startsAt)
    },
  },
})

/*
 * Use custom error formatter because the default one returns an array for a field.
 * final-form re-render all fields with constraints because it cannot figure out
 * if the error is the same or not.
 * Currently, it returns only the last error message
 */
validatejs.formatters.prosapient = (errors: ValidateJSError[]) => {
  const result: { [index: string]: string } = {}
  for (const error of errors) {
    result[error.attribute] = error.error
  }
  return result
}

validatejs.extend(validatejs.validators.datetime, {
  parse: (value: string | Date) => {
    if (value instanceof Date) return value
    return parseISO(value)
  },
  format: (value: Date | number, options: { dateOnly: boolean }) => {
    const formatType = options.dateOnly ? "yyyy-MM-dd" : "yyyy-MM-dd kk:mm:SS"
    format(value, formatType)
  },
})

validatejs.validators.array = (arrayItems: any, itemConstraints: any) => {
  if (!arrayItems) return

  const arrayItemErrors = arrayItems.reduce((errors: any, item: any, index: any) => {
    const error = validate(item, itemConstraints)
    if (error) errors[index] = { error }
    return errors
  }, {})

  return R.isEmpty(arrayItemErrors) ? null : { errors: arrayItemErrors }
}

validatejs.validators.list = (arrayItems: any, itemConstraints: any) => {
  if (!arrayItems) return

  const arrayItemErrors = arrayItems.reduce((errors: any, item: any, index: any) => {
    const error = validatejs.single(item, itemConstraints, DEFAULT_OPTIONS)
    if (error) {
      errors[index] = error
    } else {
      errors[index] = undefined
    }
    return errors
  }, {})

  return R.isEmpty(arrayItemErrors) ? null : arrayItemErrors
}

validatejs.validators.file = (
  value: File,
  options: { sizeLessThanInKB?: number; whitelistedExtensions?: string[] }
) => {
  if (value) {
    const errors: { size?: string; extension?: string } = {}

    if (options.sizeLessThanInKB && value.size > options.sizeLessThanInKB) {
      errors.size = `should be less than ${options.sizeLessThanInKB / 1000000}MB`
    }

    if (options.whitelistedExtensions && value.name) {
      const ext = value.name.substring(value.name.lastIndexOf(".") + 1, value.name.length) || value.name

      if (!options.whitelistedExtensions.includes(ext)) {
        errors.extension = `.${ext} are not allowed`
      }
    }

    return errors
  }
}

type ValidateJSError = {
  attribute: string
  error: string
}

const validate = (values: any, constraints: any, opts?: any) => {
  return validatejs(values, constraints, { ...DEFAULT_OPTIONS, ...opts })
}

type WhenFieldChangesProps = {
  field: string
  target: string
  children: (props: { current: string; previous: string; onTargetChange: (e: any) => void }) => void
}

const WhenFieldChanges = ({ field, target, children }: WhenFieldChangesProps) => {
  return (
    <RField
      name={target}
      subscription={{}}
      render={(
        // No subscription. We only use Field to get to the change function
        { input: { onChange } }
      ) => (
        <OnChange name={field}>
          {(current, previous) => {
            children({ current, previous, onTargetChange: onChange })
          }}
        </OnChange>
      )}
    />
  )
}

const WhenFieldIsPresent = ({ field, children }: React.PropsWithChildren<{ field: string }>) => (
  <RField name={field} subscription={{ value: true }}>
    {({ input: { value } }) => (undef(value) ? null : children)}
  </RField>
)

export {
  validatejs,
  validate,
  parseNumberInput,
  parseNullNumberInput,
  formatPhone,
  validatePhone,
  parsePhoneNumberInput,
  WhenFieldChanges,
  WhenFieldIsPresent,
}
