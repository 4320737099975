import { Box, Flex, H4 } from "@prosapient/prosapient-styleguide"
import { ComponentPropsWithRef, useMemo } from "react"
import { BoxCoreProps } from "@prosapient/prosapient-styleguide/src/components/Box"
import styled from "styled-components"
import { PaymentSummary } from "./PaymentSummary"
import { PhoneReminder } from "./PhoneReminder"
import { Checklist } from "./Checklist"
// import { Events } from "./Events"
import { useLocation } from "react-router"
import { useMe } from "hooks"
import { EmploymentReminder } from "./EmploymentReminder"
import { SkillsAndTechReminder } from 'components/shared/Sidebar/SkillsAndTechReminder'

type BoxProps = ComponentPropsWithRef<"div"> & BoxCoreProps

const SectionHead = styled(H4).attrs({ lineHeight: 2, m: 0, mb: 2 })`
  font-size: 1.15rem;
`

const SectionBox = styled(Flex).attrs({ m: "auto", shadow: "light", borderRadius: 2, bg: "white", p: 9, mb: "8px" })``

export const Sidebar = (props: BoxProps) => {
  const { pathname } = useLocation()
  const {
    me: { state },
    loggedIn,
  } = useMe()

  // no is MVP
  // const showPayments = useMemo(() => pathname.startsWith(ROUTES.payments), [pathname])
  const showPayments = useMemo(() => false, [pathname])

  // no is MVP
  // const showEvent = useMemo(() => true, [])

  const showChecklist = useMemo(() => {
    return (
      loggedIn && (!state?.isCompliancePassed || !state?.isRecentEmploymentsConfirmed || !state?.isAllDocumentsSigned)
    )
  }, [state])

  return (
    <Box {...props}>
      <SectionHead>Current Checklist</SectionHead>

      {showChecklist && (
        <SectionBox>
          <Checklist />
        </SectionBox>
      )}

      <SectionBox>
        <PhoneReminder />
      </SectionBox>

      {state?.isRecentEmploymentsConfirmed && (
        <SectionBox>
          <EmploymentReminder />
        </SectionBox>
      )}

      {/*{showEvent && (*/}
      {/*  <>*/}
      {/*    <SectionHead>Upcoming Event</SectionHead>*/}
      {/*    <SectionBox stripedLeft stripedLeftColor="gamma.400">*/}
      {/*      <Events />*/}
      {/*    </SectionBox>*/}
      {/*  </>*/}
      {/*)}*/}

      {showPayments && (
        <>
          <SectionHead>Payment Summary</SectionHead>
          <SectionBox mb="0px">
            <PaymentSummary />
          </SectionBox>
        </>
      )}

      <SectionBox>
        <SkillsAndTechReminder />
      </SectionBox>
    </Box>
  )
}
