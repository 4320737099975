// import { Decimal } from "decimal.js"

const NOT_AVAILABLE = "-"

const CURRENCY_SYMBOLS: { [key: string]: string } = {
  usd: "$",
  gbp: "£",
  eur: "€",
  aud: "A$",
  chf: "Fr.",
  jpy: "¥",
}

export type Currency = string

export const format = (value: number | string, currency: Currency = "usd") => {
  return value === "" || value === NOT_AVAILABLE
    ? value
    : new Intl.NumberFormat("en-US", {
        style: "currency",
        currency,
        currencyDisplay: "symbol",
        minimumFractionDigits: 0,
      }).format(Number(value))
}

export const getCurrencyLabel = (currency?: Currency | null) => {
  return currency ? `${CURRENCY_SYMBOLS[currency]}` : ""
}
