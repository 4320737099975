import styled from "styled-components"
import { Drawer, Flex, Image, NavLink, VStack } from "@prosapient/prosapient-styleguide"
import { IHeaderProps } from "../types"
import { useToggle } from "react-use"
import { useLocation } from "react-router"
import { ComponentPropsWithRef, useMemo } from "react"
import { ROUTES } from "components/constants"
import { Logo } from "../Logo"
import BurgerImage from "accets/icons/burger.svg"
import { MIcon } from "../../MIcon/MIcon"

const MobileWrapper = styled(Flex)`
  height: 100%;
  align-items: center;
  max-width: 100vw;
  display: none;

  @media only screen and (max-width: 1024px) {
    display: block;
  }
`

const Menu = styled(Drawer).attrs({ width: "230px" })`
  display: none;

  @media only screen and (max-width: 1024px) {
    display: block;
  }
`

const StackItem = styled(NavLink)`
  padding: 0;

  span {
    width: 100%;
  }

  a {
    display: inline-block;
    padding: 20px;
    width: 100%;
    text-decoration: none;
    font-weight: 700;
  }
`

const Burger = (props: ComponentPropsWithRef<"img">) => <Image src={BurgerImage} alt="menu" {...props} />

const CloseButton = (props: ComponentPropsWithRef<"div">) => (
  <MIcon
    v={2}
    icon="close"
    color="beta.300"
    hoverColor="beta.800"
    style={{ position: "absolute", top: 0, right: 0, padding: "17px", lineHeight: "9px", cursor: "pointer" }}
    {...props}
  />
)

export const MobileHeader = ({ navbar: showNavbar, isPublic: isNoAIMAuthenticatedUser }: IHeaderProps) => {
  const { pathname } = useLocation()
  const [open, toggleOpen] = useToggle(false)

  const displayRoutes = useMemo(() => {
    const routes = [
      { label: "My profile", path: ROUTES.myProfile, show: showNavbar },
      // { label: "Workspace", path: ROUTES.workspace, show: showNavbar },
      { label: "Projects", path: ROUTES.projects, show: showNavbar },
      { label: "Payment", path: ROUTES.payments, show: showNavbar },
      { label: "Documents", path: ROUTES.documents, show: showNavbar },
      { label: "Log Out", path: ROUTES.authLogout, show: !isNoAIMAuthenticatedUser },
    ]

    return routes
      .filter(({ show }) => show)
      .map(params => {
        const active = pathname.startsWith(params.path) || params.path.startsWith(pathname)
        return { active, ...params }
      })
  }, [pathname, isNoAIMAuthenticatedUser, showNavbar])

  return (
    <MobileWrapper>
      <Flex justifyContent="space-between" height="100%" alignItems="center" position="relative">
        {isNoAIMAuthenticatedUser && <Logo style={{ cursor: "default", height: "24px", flex: 1 }} />}

        {!isNoAIMAuthenticatedUser && (
          <>
            <Burger style={{ cursor: "pointer", marginLeft: "-2px" }} onClick={toggleOpen} />
            <Logo withLink style={{ cursor: "default", height: "24px", flex: 1 }} />
            <Burger style={{ opacity: 0, cursor: "default" }} />
          </>
        )}
      </Flex>

      <Menu open={open} placement="left" onClose={toggleOpen}>
        <Logo style={{ cursor: "default", padding: "24px 20px" }} />

        <CloseButton onClick={toggleOpen} />

        <VStack gutter={2} mb={5} flexWrap="wrap">
          {displayRoutes.map(({ active, label, path }) => (
            <StackItem key={label} name={label} to={path} active={active}>
              {label}
            </StackItem>
          ))}
        </VStack>
      </Menu>
    </MobileWrapper>
  )
}
