// LEGACY, new component placed here -> shared/selects/LocationsSelect
import { Select, SelectOption } from "@prosapient/prosapient-styleguide"
import styled from "styled-components"
import { useMemo, useState } from "react"
import { ReactSelectOption } from "./controls"
import countries from "shared/countryCodes"
import slugify from "slugify"

const StyledSelect = styled(Select)`
  flex: 1;
`

type Props = {
  name: string
  label?: string
  format: "alpha3" | "label"
  valueKey: string
  isMulti?: boolean
} & any

export const LocationsSelect = ({ format, valueKey, ...props }: Props) => {
  const [search, setSearch] = useState("")
  // const [options, setOptions] = useState<SelectOption<string>[]>([])

  const locations: SelectOption<string>[] =
    format === "alpha3"
      ? JSON.parse(JSON.stringify(countries.listAlpha3))
      : JSON.parse(JSON.stringify(countries.list)) ?? []

  const resetSearch = () => {
    setSearch("")
  }

  const onChange = (value: any) => {
    resetSearch()
    props.onChange(value)
  }

  const options = useMemo(() => {
    // if (!search.trim()) return locations.sort(countries.sortByAlphabet)

    return locations.sort(countries.sortByAlphabet).sort(({ label: a }, { label: b }) => {
      // @ts-ignore
      return slugify((b || "").toLowerCase()).startsWith(search) - slugify((a || "").toLowerCase()).startsWith(search)
    })
  }, [search, locations])

  const fillValue = useMemo(() => {
    if (valueKey) {
      const searchKeys = [valueKey, valueKey?.toUpperCase()]
      return options.find(({ value }) => searchKeys.includes(value))
    }

    return props.value || ""
  }, [props.value, format, valueKey, options])

  return (
    <StyledSelect
      {...props}
      isClearable
      value={fillValue}
      options={options}
      placeholder="Type here..."
      onBlur={resetSearch}
      onMenuClose={resetSearch}
      onChange={props.onChange && onChange}
      onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
        setTimeout(() => {
          // @ts-ignore
          setSearch((event.target?.value || "").toLowerCase())
        }, 0)
      }}
      components={{
        ...props.components,
        Option: ReactSelectOption,
      }}
    />
  )
}
