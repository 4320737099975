import React from "react"
import {
  Box,
  Flex,
  Hint as UIKitHint,
  AsyncCreatableSelect as UIKitAsyncCreatableSelect,
  AsyncCreatableSelectProps as UIKitAsyncCreatableSelectProps,
  AsyncSelect as UIKitAsyncSelect,
  AsyncSelectProps as UIKitAsyncSelectProps,
  AutocompleteSelect as UIKitAutocompleteSelect,
  CreatableSelect as UIKitCreatableSelect,
  CreatableSelectProps as UIKitCreatableSelectProps,
  Select as UIKitSelect,
  SelectProps as UIKitSelectProps,
  AutocompleteSelectProps as UIKitAutocompleteSelectProps,
  Tooltip as UIKitTooltip,
  SelectOption,
} from "@prosapient/prosapient-styleguide"
import { useField } from "react-final-form"
import { useTranslation } from "react-i18next"
import { FormBindings } from "shared/form-bindings-new"
import { GroupBase } from "react-select"
import styled from "styled-components"

type HintProps = {
  hint?: string
  hintPositionBehaviour?: "floating" | "fixed"
}

export interface CommonFormSelectProps extends HintProps {
  tooltip?: string
  label?: string
  innerSelectLabel?: string
}

export interface FormSelectProps<
  Option extends SelectOption<unknown> = SelectOption<unknown>,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
> extends FormBindings.Props<HTMLSelectElement, UIKitSelectProps<Option, IsMulti, Group>>,
    CommonFormSelectProps {}

export interface FormAsyncSelectProps<
  Option extends SelectOption<unknown> = SelectOption<unknown>,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
> extends FormBindings.Props<HTMLSelectElement, UIKitAsyncSelectProps<Option, IsMulti, Group>>,
    CommonFormSelectProps {}

export interface FormCreatableSelectProps<
  Option extends SelectOption<unknown> = SelectOption<unknown>,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
> extends FormBindings.Props<HTMLSelectElement, UIKitCreatableSelectProps<Option, IsMulti, Group>>,
    CommonFormSelectProps {}

export interface FormAsyncCreatableSelectProps<
  Option extends SelectOption<unknown> = SelectOption<unknown>,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
> extends FormBindings.Props<HTMLSelectElement, UIKitAsyncCreatableSelectProps<Option, IsMulti, Group>>,
    CommonFormSelectProps {}

export interface FormAutocompleteSelectProps<Option extends SelectOption<unknown> = SelectOption<unknown>>
  extends FormBindings.Props<HTMLSelectElement, UIKitAutocompleteSelectProps<Option>>,
    CommonFormSelectProps {}

const StyledSelectContainer = styled(Flex)`
  width: 100%;
  > div {
    width: 100%;
  }
`

export const FormSelectWrapper = <SelectProps extends UIKitSelectProps<any, any, any>>({
  SelectComponent,
  name,
  onChange,
  tooltip,
  label,
  hintPositionBehaviour,
  ...props
}: FormBindings.Props<HTMLSelectElement, SelectProps> & {
  SelectComponent: (props: SelectProps) => JSX.Element
}) => {
  const { t } = useTranslation()
  const { input, meta } = useField(name, props)
  const fieldIsInvalid = FormBindings.isFieldInvalid(meta)

  return (
    <Flex flexDirection="column" flex="1">
      <Box position={hintPositionBehaviour ? "relative" : "static"}>
        {tooltip && (
          <Flex alignItems="baseline">
            {label && (
              <FormBindings.StyledLabel disabled={props.isDisabled} required={props.required} invalid={fieldIsInvalid}>
                {label}
              </FormBindings.StyledLabel>
            )}
            <UIKitTooltip label={tooltip} />
          </Flex>
        )}
        {label && !tooltip && (
          <FormBindings.StyledLabel disabled={props.isDisabled} required={props.required} invalid={fieldIsInvalid}>
            {label}
          </FormBindings.StyledLabel>
        )}
        <StyledSelectContainer>
          {/* Since we don't know exactly type of passed select, compiler tells that SelectProps could be instantiated with a different subtype of our constraint */}
          {/*@ts-expect-error*/}
          <SelectComponent
            {...FormBindings.omitFieldConfigProperties(props)}
            {...input}
            onChange={(value, meta) => {
              onChange && onChange(value, meta)
              input.onChange(value)
            }}
            invalid={fieldIsInvalid}
            required={false}
            label={props.innerSelectLabel}
            classNamePrefix="select"
          />
        </StyledSelectContainer>
        <Box position={hintPositionBehaviour ? "absolute" : "static"}>
          {fieldIsInvalid ? (
            <UIKitHint invalid={fieldIsInvalid}>{t(meta.error || meta.submitError)}</UIKitHint>
          ) : (
            props.hint && <UIKitHint style={{ whiteSpace: "pre" }}>{props.hint}</UIKitHint>
          )}
        </Box>
      </Box>
    </Flex>
  )
}

export const FormSelect = <
  Option extends SelectOption<unknown>,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: FormSelectProps<Option, IsMulti, Group>
) => <FormSelectWrapper {...props} SelectComponent={UIKitSelect} />

export const FormAsyncCreatableSelect = <
  Option extends SelectOption<unknown>,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: FormAsyncCreatableSelectProps<Option, IsMulti, Group>
) => <FormSelectWrapper {...props} SelectComponent={UIKitAsyncCreatableSelect} />

export const FormAsyncSelect = <
  Option extends SelectOption<unknown>,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: FormAsyncSelectProps<Option, IsMulti, Group>
) => <FormSelectWrapper {...props} SelectComponent={UIKitAsyncSelect} />

export const FormCreatableSelect = <
  Option extends SelectOption<unknown>,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: FormCreatableSelectProps<Option, IsMulti, Group>
) => <FormSelectWrapper {...props} SelectComponent={UIKitCreatableSelect} />

export const FormAutocompleteSelect = <Option extends SelectOption<unknown>>(
  props: FormAutocompleteSelectProps<Option>
) => <FormSelectWrapper {...props} SelectComponent={UIKitAutocompleteSelect} />
