import { Box, Button, Image } from "@prosapient/prosapient-styleguide"
import ImageBG from "accets/images/invitation_declined.svg"
import { useHistory, useLocation } from "react-router"
import { HEADER_MOBILE_HEIGHT, ROUTES } from "components/constants"
import { Paragraph, SubTitle, Title } from "components/shared/Layout"
import { PageContainer } from "components/shared/Layout/PageContainer"
import styled from "styled-components"
import { useMemo } from "react"
import { useRevertProjectDeclineMutation } from "api/__generated__/schema"

const StyledBox = styled(Box).attrs({ p: 10, maxWidth: 640, width: "100vw" })`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;

  // @media only screen and (max-width: 1024px) {
  //   min-height: calc(var(--vh) - ${HEADER_MOBILE_HEIGHT}px);
  // }

  @media only screen and (max-width: 640px) {
    max-width: 100%;
    margin: 0 auto;
    align-self: flex-start;
    box-shadow: none;
    //min-height: calc(var(--vh) - 56px);
    min-height: calc(var(--vh) - ${HEADER_MOBILE_HEIGHT}px);
    padding: 1rem;
  }
`

const StyledButton = styled(Button)`
  @media only screen and (max-width: 640px) {
    width: 100%;
    margin-top: auto;
    padding-top: 1px;
    padding-bottom: 1px;
    min-height: 40px;
  }
`

const StyledParagraph = styled(Paragraph).attrs({ px: 12, my: 4 })`
  @media only screen and (max-width: 640px) {
    padding-right: 0;
    padding-left: 0;
  }
`

export const InvitationDeclined = () => {
  const { push } = useHistory()
  const { search } = useLocation()

  const [revertProjectDecline, { loading }] = useRevertProjectDeclineMutation()

  const externalId = useMemo(() => new URLSearchParams(search).get("project_external_id") || "", [search])

  const handleClick = async () => {
    try {
      const result = await revertProjectDecline({ variables: { externalId } })

      if (result?.data?.revertProjectDecline?.success) {
        push(`${ROUTES.screeningMain}?project_external_id=${externalId}`)
      } else {
        push(ROUTES.linkExpired)
      }
    } catch (e) {
      /*eslint-disable */
      console.error(e)
    }
  }

  return (
    <PageContainer hasBackground={false}>
      <StyledBox>
        <Box width="100%">
          <Title>You have declined to participate on this project</Title>

          <StyledParagraph>
            Thank you for your time. We’re looking forward to working together on future projects
          </StyledParagraph>

          <Image src={ImageBG} alt="Invitation declined image" width="100%" />

          <SubTitle mt={10} mb={6}>
            If you clicked to decline by mistake, you can re-apply to the project in the next 5 minutes
          </SubTitle>
        </Box>

        <StyledButton loading={loading} onClick={handleClick}>
          Return to questions
        </StyledButton>
      </StyledBox>
    </PageContainer>
  )
}
