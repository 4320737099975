import { Alert, Box, Flex, Text, useAlert, VStack } from "@prosapient/prosapient-styleguide"
import { MIcon } from "components/shared/MIcon/MIcon"
import { useMe } from "hooks"
import { useEffect, useState } from "react"
import { ActionBox, StyledButton, SubLabel } from "components/@profile/@my-profile/sections/BasicInformation/styles"
import { PhoneSelect } from "../PhoneSelect"
import { IExpertPayload, useUpdateProfileMutation } from "api/__generated__/schema"
import styled from "styled-components"

export const PhoneReminder = () => {
  const [showEditPhone, setShowEditPhone] = useState(false)
  const [isPhoneValid, setIsPhoneValid] = useState(true)
  const [newPhone, setNewPhone] = useState("")

  const alert = useAlert(Alert.Context)
  const { me, refetch } = useMe()

  const [updateProfile, { loading }] = useUpdateProfileMutation()

  useEffect(() => {
    if (me?.phone) setNewPhone(me?.phone)
  }, [me?.phone])

  const handleSubmit = async () => {
    try {
      const payload: IExpertPayload = {
        phone: newPhone ? `+${newPhone.replaceAll("+", "")}` : "",
      }

      const { data } = await updateProfile({ variables: { payload } })

      if (data?.updateProfile.success) {
        alert.success("Phone updated successfully")
        await refetch()
        setShowEditPhone(false)
      } else {
        alert.error(data?.updateProfile.description)
      }
    } catch (e) {
      /*eslint-disable */
      console.error(e)
      /*eslint-enable */

      // @ts-ignore
      alert.error(e?.message || e)
    }
  }

  return (
    <Flex style={{ gridGap: "10px" }}>
      <Box color="alpha.1000">
        <MIcon v={2} icon="phone" fontSize="1.5rem" style={{ width: "20px" }} />
      </Box>

      <VStack style={{ lineHeight: `${25 / 14}rem` }}>
        <Text>Please, always keep your phone number updated</Text>
        <Text fontWeight={2}>{me?.phone || ""}</Text>

        {!showEditPhone && <StyledLink onClick={() => setShowEditPhone(true)}>Edit my phone number</StyledLink>}

        {showEditPhone && (
          <>
            <SubLabel fontWeight={4}>Phone number</SubLabel>

            <PhoneSelect
              mandatory
              phone={newPhone}
              setPhone={setNewPhone}
              isValid={isPhoneValid}
              setIsValid={setIsPhoneValid}
            />

            <ActionBox mt={7}>
              <StyledButton disabled={!isPhoneValid} loading={loading} type="button" onClick={handleSubmit}>
                Save changes
              </StyledButton>
              <Box m={2} />
              <StyledButton
                type="button"
                variant="secondary"
                disabled={loading}
                onClick={() => {
                  setShowEditPhone(false)
                  setNewPhone(me?.phone || "")
                }}
              >
                Discard
              </StyledButton>
            </ActionBox>
          </>
        )}
      </VStack>
    </Flex>
  )
}

const StyledLink = styled(Text).attrs({ color: "alpha.600", fontWeight: 2 })`
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.colors.alpha["800"]};
    text-decoration: underline;
  }
}
`
