import { useMemo } from "react"
import { Box, Grid } from "@prosapient/prosapient-styleguide"
import { useTrainingContext } from "../hooks/useTraining"
import styled from "styled-components"

const Ul = styled(Grid)`
  grid-gap: 6px;
  min-height: 6px;

  @media only screen and (max-width: 640px) {
    display: flex;
    justify-content: center;
    padding: 16px 0;
    border-radius: 50px;
    width: 100% !important;
  }
`

const Li = styled(Box)`
  border-radius: 0 0 10px 10px;
  transition: 0.2s ease-in-out;

  @media only screen and (max-width: 640px) {
    border-radius: 50px;
    width: 8px !important;
    height: 8px !important;
  }
`

export const Progress = ({ maxWidth }: { maxWidth: number }) => {
  const {
    state: { count, currentStep },
  } = useTrainingContext()

  const list = useMemo(
    () =>
      Object.keys(new Array(count).fill(null)).map((key, index) => ({
        key: index,
        isNext: index > currentStep,
        isCurrent: index === currentStep,
      })),
    [count, currentStep]
  )

  return (
    <Box maxWidth={maxWidth} mx="auto">
      <Ul gridTemplateColumns={`repeat(${count}, 1fr)`}>
        {list.map(item => (
          <Li
            key={item.key}
            backgroundColor={item.isNext ? "alpha.50" : "alpha.600"}
            height={item.isCurrent ? "6px" : "4px"}
          />
        ))}
      </Ul>
    </Box>
  )
}
