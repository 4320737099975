import React from "react"
import {
  Box,
  Hint as UIKitHint,
  Textarea as UIKitTextarea,
  TextareaProps as UIKitTextareaProps,
} from "@prosapient/prosapient-styleguide"
import { useField } from "react-final-form"
import { useTranslation } from "react-i18next"
import { FormBindings } from "shared/form-bindings-new"

type HintProps = {
  hintPositionBehaviour?: "floating" | "fixed"
}

type CustomProps = {
  label?: string
  displayError?: boolean
  validateOnBlur?: boolean
  name: string
} & HintProps

export type FormTextareaProps = FormBindings.Props<HTMLTextAreaElement, UIKitTextareaProps> & CustomProps

export const FormTextarea = React.forwardRef<HTMLTextAreaElement, FormTextareaProps>(
  ({ validateOnBlur, name, hintPositionBehaviour = "fixed", style = {}, ...props }, ref) => {
    const { t } = useTranslation()
    const { input, meta } = useField(name, props)
    const fieldIsInvalid = props.displayError && FormBindings.isFieldInvalid(meta)
    const inputProps = validateOnBlur ? input : { ...input, onBlur: () => null }

    const textareaProps = {
      ...inputProps,
      ...FormBindings.omitFieldConfigProperties(props),
      onBlur: validateOnBlur ? input.onBlur : () => {},
      invalid: fieldIsInvalid,
      required: false,
      ref,
      style,
    }

    return (
      <Box width={1}>
        {props.label && (
          <FormBindings.StyledLabel disabled={props.disabled} required={props.required} invalid={fieldIsInvalid}>
            {props.label}
          </FormBindings.StyledLabel>
        )}
        <UIKitTextarea {...textareaProps} />
        {fieldIsInvalid && (
          <Box position={hintPositionBehaviour === "floating" ? "absolute" : "static"}>
            <UIKitHint invalid={fieldIsInvalid}>{t(meta.error || meta.submitError)}</UIKitHint>
          </Box>
        )}
      </Box>
    )
  }
)

FormTextarea.defaultProps = {
  displayError: true,
}
