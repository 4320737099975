import { Label, StackItem, SubLabel } from "../styles"
import { Box } from "@prosapient/prosapient-styleguide"
import { ReactNode } from "react"
import styled from "styled-components"

type Tags = {
  tags?: string[]
}

type IProps = {
  label: string
  subLabel: ReactNode
} & Tags

const TagsBox = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`

const Tag = styled(Box)`
  text-align: left;
  white-space: nowrap;
  border: 1px solid ${props => props.theme.colors.beta[300]};
  border-radius: 2px;
  color: ${props => props.theme.colors.beta[800]};
  background-color: #fff;
  padding: 1px 12px;
  font-size: 12px;
  line-height: 18px;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
`

export const FieldView = ({ label, subLabel, tags }: IProps) => (
  <StackItem>
    <Label>{label}</Label>
    <SubLabel style={{ wordBreak: "break-all" }}>
      {tags?.length ? (
        <TagsBox>
          {tags.map((tag, idx) => (
            <Tag key={[tag, idx].join("__")} title={tag}>
              {tag}
            </Tag>
          ))}
          {/*{tags.join("; ")}*/}
        </TagsBox>
      ) : (
        <>{subLabel}</>
      )}
    </SubLabel>
  </StackItem>
)
