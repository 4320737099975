import { useMemo } from "react"
import { Box, TableBody, TableHeader, TableRow, Text } from "@prosapient/prosapient-styleguide"
import { format } from "shared/date"
import NumberFormat from "react-number-format"
import { Title } from "components/shared/Layout"
import { IPaymentsQuery, IPaymentStatus } from "api/__generated__/schema"
import { StyledDataCell, StyledHeaderCell, StyledTable, StyledTableRow } from "./styles"
import { MIcon } from "components/shared/MIcon/MIcon"
import { getCurrencyLabel } from "shared/currency"

const paymentStatusLabel: { [status: string]: string } = {
  [IPaymentStatus.Failed]: "Failed",
  [IPaymentStatus.InPayment]: "In Payment",
  [IPaymentStatus.NotPaid]: "Not Paid",
  [IPaymentStatus.Paid]: "Paid",
}
const paymentStatusColor: { [status: string]: string | null } = {
  [IPaymentStatus.Failed]: "delta.600",
  [IPaymentStatus.InPayment]: "epsilon.600",
  [IPaymentStatus.NotPaid]: "beta.800",
  [IPaymentStatus.Paid]: "alpha.600",
}

type PaymentOverviewProps = {
  data: IPaymentsQuery["payments"]
}

export const Overview = ({ data }: PaymentOverviewProps) => {
  const rows = useMemo(() => {
    return data.map(({ amount, paymentDate, status, currency, ...rest }) => {
      return {
        action: (
          <Text className="hoverOnly" color="alpha.600" fontWeight={2} cursor="pointer">
            <Text color="inherit" style={{ display: "inline-block", verticalAlign: "bottom", lineHeight: 0 }} mr={1}>
              <MIcon icon="download" />
            </Text>
            Download
          </Text>
        ),
        payDate: format(paymentDate, "ll"),
        status: <Text color={paymentStatusColor[status] || null}>{paymentStatusLabel[status] || status}</Text>,
        amount: (
          <NumberFormat
            value={amount || 0}
            displayType={"text"}
            thousandSeparator={" "}
            prefix={getCurrencyLabel(currency) || ""}
            fixedDecimalScale={true}
            renderText={value => <Text>{value}</Text>}
          />
        ),
        ...rest,
      }
    })
  }, [data])

  return (
    <Box width="100%" mt="-12px" style={{ overflow: "hidden" }}>
      <Title mb={8}>Payment</Title>
      <Box style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
        <StyledTable>
          <TableHeader>
            <TableRow>
              <StyledHeaderCell>Invoice</StyledHeaderCell>
              {/*<StyledHeaderCell>Status</StyledHeaderCell>*/}
              {/*<StyledHeaderCell>Pay Date</StyledHeaderCell>*/}
              <StyledHeaderCell>Amount earned</StyledHeaderCell>
              {/*<StyledHeaderCell />*/}
            </TableRow>
          </TableHeader>
          <TableBody>
            {rows.map(({ externalId, invoicePublicId, amount }) => (
              <StyledTableRow key={externalId}>
                <StyledDataCell width="auto">{invoicePublicId || ""}</StyledDataCell>
                {/*<StyledDataCell width="auto">{externalId}</StyledDataCell>*/}
                {/*<StyledDataCell>{status}</StyledDataCell>*/}
                {/*<StyledDataCell>{payDate}</StyledDataCell>*/}
                <StyledDataCell>{amount}</StyledDataCell>
                {/*<StyledDataCell style={{ textAlign: "right" }}>{action}</StyledDataCell>*/}
              </StyledTableRow>
            ))}
          </TableBody>
        </StyledTable>
      </Box>
    </Box>
  )
}
