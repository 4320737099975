import { Box, Button, Flex, Modal, Text } from "@prosapient/prosapient-styleguide"
import { Paragraph, Title } from "components/shared/Layout"
import styled from "styled-components"
import { MIcon } from "components/shared/MIcon/MIcon"

const StyledButton = styled(Button)`
  @media only screen and (max-width: 640px) {
    padding-top: 1px;
    padding-bottom: 1px;
    min-height: 40px;
  }
`

const ButtonWrapper = styled(Flex)`
  grid-gap: 4px;

  @media only screen and (max-width: 640px) {
    flex-direction: column;
  }
`

const ContentBody = styled(Box)`
  padding: 30px;

  @media only screen and (max-width: 640px) {
    padding: 10px;
  }
`

const CloseIcon = () => (
  <Text
    color="beta.300"
    hoverColor="beta.600"
    style={{ position: "absolute", right: "0.5rem", top: "0.5rem", padding: "0.5rem" }}
  >
    <MIcon icon="close" />
  </Text>
)

type Props = {
  isOpen: boolean
  loading: boolean
  onSubmit: () => void
  onClose: () => void
}

export const AccountDeactivationSentModal = ({ isOpen, loading, onSubmit, onClose }: Props) => {
  return (
    <>
      <Modal
        closeable
        type="blank"
        onRequestClose={onClose}
        isOpen={isOpen}
        width={688}
        ariaHideApp={false}
        shouldCloseOnOverlayClick
        style={{
          borderRadius: "2px",
          maxWidth: "100%",
          padding: "10px",
        }}
        closeIcon={CloseIcon}
      >
        <ContentBody>
          <Title>Account deactivation request sent</Title>

          <Paragraph mt={6} mb={8}>
            Thank you for your request, it will be reviewed by the admin. We will get back to you in 2-3 business days
          </Paragraph>

          <ButtonWrapper>
            <StyledButton disabled={loading} onClick={onClose}>
              OK
            </StyledButton>
            <StyledButton loading={loading} variant="secondary" onClick={onSubmit}>
              Cancel deactivation request
            </StyledButton>
          </ButtonWrapper>
        </ContentBody>
      </Modal>
    </>
  )
}
