import { createGlobalStyle, css } from "styled-components"

const isMac = !!navigator.platform.toUpperCase().match(/MAC/)

const GlobalStyle = createGlobalStyle`
  html, body, #root {
    font-size: ${props => {
      return props.theme.baseFontSize
    }}px;
    background-color: ${({ theme }) => theme.colors.beta[100]};
    color: ${({ theme }) => theme.colors.beta[800]};
  }

  a {
    text-decoration-color: ${({ theme }) => theme.colors.alpha[800]};
  }

  body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
  }

  * {
    box-sizing: border-box;

    scrollbar-color: ${({ theme }) => theme.colors.beta[300]} transparent;
    scrollbar-width: thin;
  }
  
  button {
    border-width: 1px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }


  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.beta[300]};
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #b2b3b3;
  }

  ::-webkit-scrollbar-button {
  }

  ::-webkit-scrollbar-track-piece {
  }

  ::-webkit-scrollbar-corner {
  }

  ::-webkit-resizer {
  }

  [data-reach-tooltip] {
    z-index: 999999 !important;
  }

  [data-reach-tooltip-trigger] {
    display: flex;
  }

  ${!isMac &&
    css`
      ::-webkit-scrollbar {
        height: 6px;
        width: 6px;
      }
    `}
  .ReactModalPortal > .ReactModal__Overlay {
    padding: 10px !important;
  }

  .drawer {
    outline: none;
  }
`

export default GlobalStyle
