import { useEffect, useState, useCallback } from "react"
import { useThrottle } from "hooks/useThrottle"
import * as R from "ramda"
import { DeviceScreenPredicates } from "./types"

const breakpoints = [425, 768, 1024, 1440, 1800, 2560]
export const breakpointsPx = breakpoints.map(br => `${br}px`)

const normalizeSizeToBreakpoint = (size: number) =>
  R.find((breakpoint: number) => size <= breakpoint)(breakpoints) || breakpoints[breakpoints.length - 1]

const useScreenSize = (): DeviceScreenPredicates => {
  const [size, setSize] = useState(normalizeSizeToBreakpoint(window.innerWidth))
  const onResize = useCallback(() => {
    setSize(normalizeSizeToBreakpoint(window.innerWidth))
  }, [setSize])

  const [throttledResize] = useThrottle(onResize, 100, [])

  useEffect(() => {
    window.addEventListener("resize", throttledResize)

    return () => {
      window.removeEventListener("resize", throttledResize)
    }
  }, [throttledResize])

  return {
    isMobile: size === breakpoints[0],
    isTabletSm: size === breakpoints[1],
    isTabletMd: size === breakpoints[2],
    isTablet: size === breakpoints[1] || size === breakpoints[2],
    isTabletOrMobile: size === breakpoints[0] || size === breakpoints[1] || size === breakpoints[2],
    isDesktopSm: size === breakpoints[3],
    isDesktopMd: size === breakpoints[4],
    isDesktop: size === breakpoints[3] && size === breakpoints[4],
  }
}

export default useScreenSize
