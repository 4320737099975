import { Box, Flex, Link, Text, VStack } from "@prosapient/prosapient-styleguide"
import { MIcon } from "components/shared/MIcon/MIcon"
import { ROUTES } from "components/constants"
import styled from "styled-components"
import { useMe } from "hooks"
import { useHistory } from "react-router"

const StyledLink = styled(Link).attrs({ color: "alpha.600", hoverColor: "alpha.800", fontWeight: 2 })`
  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`

type CardProps = {
  show: boolean
  text: string
  linkUrl: string
  linkLabel: string
  description: string
}
const StackItem = ({ show, text, linkUrl, linkLabel, description }: CardProps) => {
  const { push } = useHistory()

  if (!show) return null

  return (
    <Flex style={{ gridGap: "10px" }}>
      <Box>
        <MIcon v={2} icon="error" color="epsilon.600" style={{ width: "20px" }} />
      </Box>

      <Box>
        <Text lineHeight={9}>
          {text}{" "}
          <StyledLink
            to={linkUrl}
            onClick={e => {
              push(linkUrl)
              e.preventDefault()
              e.stopPropagation()
              return null
            }}
          >
            {linkLabel}
          </StyledLink>
        </Text>
        <Text color="delta.800" lineHeight={9}>
          {description}
        </Text>
      </Box>
    </Flex>
  )
}

export const Checklist = () => {
  const { me, loggedIn } = useMe()

  if (!loggedIn) return null

  return (
    <VStack style={{ gridGap: "1rem" }}>
      <StackItem
        show={!me?.state?.isCompliancePassed}
        text="Pass"
        linkUrl={ROUTES.complianceTraining}
        linkLabel="Compliance training"
        description="You won’t be able to join the call if you don’t pass the Compliance training. Please, make sure to complete it before the call."
      />

      <StackItem
        show={!me?.state?.isRecentEmploymentsConfirmed}
        text="Confirm your"
        linkUrl={ROUTES.mostRecentEmployment}
        linkLabel="recent employment"
        description="Please ensure that your profile information is up to date."
      />

      <StackItem
        show={!me?.state?.isAllDocumentsSigned}
        text="Sign the updated"
        linkUrl={ROUTES.documents}
        linkLabel="documents"
        description="You won’t be able to join the call if you don’t sign the documents. Please, make sure to complete it before the call."
      />
    </VStack>
  )
}
