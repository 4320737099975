import { Box, Text } from "@prosapient/prosapient-styleguide"
import { EditContactItem } from "../styles"

export const ErrorHint = ({ show, text }: { show: boolean; text: string }) => {
  if (!show) return null

  return (
    <EditContactItem>
      <Box width={21} />
      <Text fontSize={5} color="delta.600">
        {text}
      </Text>
    </EditContactItem>
  )
}
