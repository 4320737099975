import { Box, Flex, Link, Text, VStack } from "@prosapient/prosapient-styleguide"
import { MIcon } from "components/shared/MIcon/MIcon"
import { ROUTES } from "components/constants"
import styled from "styled-components"

export const SkillsAndTechReminder = () => {
  return (
    <Flex style={{ gridGap: "10px" }}>
      <Box>
        <MIcon v={2} icon="label" fontSize="1.5rem" style={{ width: "20px" }} />
      </Box>

      <VStack style={{ lineHeight: `${25 / 14}rem` }}>
        <Text>
          Please, keep your Industries and Expertise tags up-to-date. It increases your chances to get more projects
          with us.
        </Text>

        <StyledLink to={`${ROUTES.myProfile}?tab=info`}>Go to my profile</StyledLink>
      </VStack>
    </Flex>
  )
}

const StyledLink = styled(Link).attrs({ color: "alpha.600", hoverColor: "alpha.800", fontWeight: 2 })`
  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`
