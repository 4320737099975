import { EditEmployment } from "../types"
import dayjs from "dayjs"
import { isFuture } from "shared/date"

const isValidStartDate = (year: number | null, month: number | null): boolean => {
  if (year === null || month === null) return false
  return !isFuture(`${year}-${month}-01`)
}

const isEndYearValid = ({
  isCurrent,
  endYear,
  startYear,
}: {
  isCurrent: boolean
  endYear: null | number
  startYear: null | number
}) => {
  if (isCurrent || endYear === null || startYear === null) return false

  return endYear < startYear && endYear <= dayjs().year()
}

const isEndMonthValid = ({
  startMonth,
  endMonth,
  endYear,
  startYear,
  isCurrent,
}: {
  isCurrent: boolean
  startMonth: null | number
  endMonth: null | number
  endYear: null | number
  startYear: null | number
}) => {
  if (isCurrent || endMonth === null || startMonth === null || endYear === null || startYear === null) return false

  return (endYear === startYear && endMonth < startMonth) || isFuture(`${endYear}-${endMonth}-01`)
}

export const isEmploymentValid = (employment: EditEmployment) => {
  let valid =
    employment.company.trim().length > 0 &&
    (employment?.location || "").trim().length > 0 &&
    (employment?.position || "").trim().length > 0 &&
    (employment?.responsibilities || "").trim().length > 0 &&
    employment?.startMonth !== null &&
    employment?.startYear !== null

  const isCurrent = employment.isCurrent

  if (valid) {
    valid = isValidStartDate(employment?.startYear || null, employment?.startMonth || null)
  }

  if (valid && (employment?.endMonth || employment?.endYear)) {
    const _endYear = employment?.endYear || null
    const _startYear = employment?.startYear || null
    const _endMonth = employment?.endMonth || null
    const _startMonth = employment?.startMonth || null

    if ([_endYear, _startYear, _endMonth, _startMonth].some(v => v === null)) {
      valid = false
    } else {
      const _isEndMonthValid = isEndMonthValid({
        isCurrent,
        endYear: employment?.endYear || null,
        startYear: employment?.startYear || null,
        endMonth: employment?.endMonth || null,
        startMonth: employment?.startMonth || null,
      })
      const _isEndYearValid = isEndYearValid({
        isCurrent,
        endYear: employment?.endYear || null,
        startYear: employment?.startYear || null,
      })

      if (_isEndMonthValid || _isEndYearValid) {
        valid = false
      }
    }
  }

  if (valid && !isCurrent && (!employment?.endYear || !employment?.endMonth)) {
    valid = false
  }

  return valid
}

export default {
  isEmploymentValid,
  isEndYearValid,
  isEndMonthValid,
  isValidStartDate,
}
