import { Header } from "components/shared/Header"
import { ComplianceTraining } from "./@training"
import { ROUTES } from "components/constants"
import { Redirect, Route, Switch } from "react-router-dom"
import { ComplianceComplete } from "./@complete"
import { AuthGuard } from "hocs/AuthGuard"
import { useEffect, useMemo } from "react"
import { useHistory, useLocation } from "react-router"
import { useMe } from "hooks"
import { IAuthTokenType } from "api/__generated__/schema"
import { useGlobalSpinner } from "hooks/useGlobalSpinner"

const routes = [
  { path: ROUTES.complianceReminder, component: ComplianceTraining },
  { path: ROUTES.complianceTraining, component: ComplianceTraining },
  { path: ROUTES.complianceComplete, component: ComplianceComplete },
]

export const Compliance = () => {
  const { pathname } = useLocation()
  const { push } = useHistory()
  const { showSpinner, hideSpinner } = useGlobalSpinner()
  const {
    me: { state },
  } = useMe()

  const isComplianceReminder = useMemo(() => {
    return (state?.authTokenType && state?.authTokenType === IAuthTokenType.Compliance) || false
  }, [state?.authTokenType])

  useEffect(() => {
    if (!state?.authTokenType) showSpinner()

    if (
      pathname.startsWith(ROUTES.complianceReminder) &&
      state?.authTokenType &&
      state?.authTokenType !== IAuthTokenType.Compliance
    ) {
      state?.authTokenType === IAuthTokenType.Auth
        ? push(ROUTES.complianceTraining)
        : (window.location.href = ROUTES.authLogin)
    }

    if (state?.authTokenType) hideSpinner()
  }, [pathname, state, isComplianceReminder])

  return (
    <AuthGuard
      tokenTypes={[IAuthTokenType.Auth, IAuthTokenType.Compliance]}
      sessionRequired={false}
      phoneRequired={state?.authTokenType !== IAuthTokenType.Compliance}
    >
      <Header isPublic={isComplianceReminder} />
      <Switch>
        {routes.map(({ path, component }) => (
          <Route key={path} path={path} component={component} />
        ))}

        <Route path="*" render={() => <Redirect to={{ pathname: ROUTES.complianceTraining }} />} />
      </Switch>
    </AuthGuard>
  )
}
