import { useContext } from "react"
import { UserContext } from "hooks/useMe/Me"
import { IMeQuery } from "api/__generated__/schema"
import { ApolloQueryResult } from "@apollo/client/core/types"
import { ApolloError } from "@apollo/client"

type Output = {
  me: IMeQuery["expert"]
  loggedIn: boolean
  loading: boolean
  error?: ApolloError
  refetch: () => Promise<ApolloQueryResult<IMeQuery>>
}

export const useMe = (): Output => useContext(UserContext)
