import { Box, Button, Image } from "@prosapient/prosapient-styleguide"
import ImageBG from "accets/images/answers_submitted.svg"
import { SubTitle } from "components/shared/Layout"
import { PageContainer } from "components/shared/Layout/PageContainer"
import styled from "styled-components"
import { PageMain } from "../components/PageMain"
import { useMe } from "hooks"
import { useMemo } from "react"
import { ROUTES } from "../../constants"

const StyledButton = styled(Button)`
  @media only screen and (max-width: 640px) {
    width: 100%;
    margin-top: auto;
    padding-top: 1px;
    padding-bottom: 1px;
    min-height: 40px;
  }
`

export const InviteAccepted = () => {
  const {
    me: {
      state: { isSignedUp },
    },
    loading,
  } = useMe()

  const content = useMemo(() => {
    let text, button

    if (isSignedUp) {
      text =
        "Thank you for your interest in the current project. We will get back to you shortly. Meanwhile, you can go back to Expert Portal"
      button = "Back to Expert Portal"
    } else {
      text =
        "Thank you for your interest in the current project. We will get back to you shortly. Meanwhile, you can join LEK Expert Portal"
      button = "Join Expert Portal"
    }

    return { text, button }
  }, [isSignedUp, loading])

  const clickHandler = () => {
    window.location.href = isSignedUp ? ROUTES.authLogin : ROUTES.authSignUp
  }

  return (
    <PageContainer>
      <PageMain width={540} style={{ textAlign: "center", justifyContent: "center" }}>
        <Box>
          <Image src={ImageBG} alt="answers submitted image" width="100%" />

          <SubTitle mt={10} mb={6}>
            {content.text}
          </SubTitle>
        </Box>

        <StyledButton onClick={clickHandler}>{content.button}</StyledButton>
      </PageMain>
    </PageContainer>
  )
}
