import { Alert, Box, Button, Flex, Modal, Text, useAlert } from "@prosapient/prosapient-styleguide"
import { Paragraph, Title } from "components/shared/Layout"
import styled from "styled-components"
import { MIcon } from "components/shared/MIcon/MIcon"
import { useRequestDeactivationMutation } from "api/__generated__/schema"

const StyledButton = styled(Button)`
  @media only screen and (max-width: 640px) {
    padding-top: 1px;
    padding-bottom: 1px;
    min-height: 40px;
  }
`

const ButtonWrapper = styled(Flex)`
  grid-gap: 4px;
`

const ContentBody = styled(Box)`
  padding: 30px;
  @media only screen and (max-width: 640px) {
    padding: 10px;
  }
`

const CloseIcon = () => (
  <Text
    color="beta.300"
    hoverColor="beta.600"
    style={{ position: "absolute", right: "0.5rem", top: "0.5rem", padding: "0.5rem" }}
  >
    <MIcon icon="close" />
  </Text>
)

type Props = {
  isOpen: boolean
  onSubmit: () => void
  onClose: () => void
}

export const AccountDeactivationConfirmModal = ({ isOpen, onSubmit, onClose }: Props) => {
  const alert = useAlert(Alert.Context)
  const [requestDeactivation, { loading }] = useRequestDeactivationMutation()

  const submitDeactivation = async () => {
    try {
      const result = await requestDeactivation()

      if (result?.data?.requestDeactivation?.success) {
        onSubmit()
      } else {
        alert.error(result?.data?.requestDeactivation?.description)
      }
    } catch (e) {
      /*eslint-disable */
      console.error(e)
      /*eslint-enable */

      // @ts-ignore
      alert.error(e?.message || e)
    }
  }

  return (
    <Modal
      closeable
      type="blank"
      onRequestClose={onClose}
      isOpen={isOpen}
      width={395}
      ariaHideApp={false}
      shouldCloseOnOverlayClick
      style={{
        borderRadius: "2px",
        maxWidth: "100%",
        padding: "10px",
      }}
      closeIcon={CloseIcon}
    >
      <ContentBody>
        <Title>Account deactivation</Title>

        <Paragraph mt={6} mb={8}>
          Are you sure, you want to deactivate your account?
        </Paragraph>

        <ButtonWrapper>
          <StyledButton disabled={loading} onClick={onClose}>
            Go back
          </StyledButton>
          <StyledButton loading={loading} variant="secondary" onClick={submitDeactivation}>
            Deactivate account
          </StyledButton>
        </ButtonWrapper>
      </ContentBody>
    </Modal>
  )
}
