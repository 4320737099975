import { Box, Button, Flex, Modal, Text } from "@prosapient/prosapient-styleguide"
import { Paragraph, Title } from "components/shared/Layout"
import styled from "styled-components"
import { MIcon } from "components/shared/MIcon/MIcon"

const StyledButton = styled(Button)`
  @media only screen and (max-width: 640px) {
    padding-top: 1px;
    padding-bottom: 1px;
    min-height: 40px;
  }
`
const DeclineButton = styled(Button).attrs({ variant: "secondary" })`
  color: ${props => props.theme.colors.delta[600]};
  border-color: ${props => props.theme.colors.delta[600]};

  &:hover,
  &.-active,
  &:focus:not([disabled]) {
    color: ${props => props.theme.colors.delta[600]};
    border-color: ${props => props.theme.colors.delta[600]};
  }

  @media only screen and (max-width: 640px) {
    padding-top: 1px;
    padding-bottom: 1px;
    min-height: 40px;
  }
`

const ButtonWrapper = styled(Flex)`
  @media only screen and (max-width: 640px) {
    flex-direction: column;
  }
`

const ContentBody = styled(Box)`
  padding: 30px;
  @media only screen and (max-width: 640px) {
    padding: 10px;
  }
`

const CloseIcon = () => (
  <Text
    color="beta.300"
    hoverColor="beta.600"
    style={{ position: "absolute", right: "0.5rem", top: "0.5rem", padding: "0.5rem" }}
  >
    <MIcon icon="close" />
  </Text>
)

type Props = {
  isOpen: boolean
  close: () => void
  submit: () => void
}

export const DeclineProjectModal = ({ isOpen, close, submit }: Props) => (
  <>
    <Modal
      closeable
      type="blank"
      onRequestClose={close}
      isOpen={isOpen}
      width={516}
      ariaHideApp={false}
      shouldCloseOnOverlayClick
      style={{ borderRadius: "2px", maxWidth: "100%", padding: "10px" }}
      closeIcon={CloseIcon}
    >
      <ContentBody>
        <Title>Decline project</Title>

        <Paragraph mt={6} mb={8}>
          Are you sure you want to decline the project? You cannot change your mind later.
        </Paragraph>

        <ButtonWrapper>
          <StyledButton onClick={close}>Go back</StyledButton>

          <Box m={2} />

          {/*<DeclineButton onClick={submit}>Decline project</DeclineButton>*/}
          <DeclineButton onClick={submit}>Decline project</DeclineButton>
        </ButtonWrapper>
      </ContentBody>
    </Modal>
  </>
)
