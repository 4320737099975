import { prosapientTheme, ThemeInterface } from "@prosapient/prosapient-styleguide"
import deepmerge from "deepmerge"

// type Foo = {colors: {alpha: {50: string}}} ThemeInterface

// interface ClientTheme {
//   colors?: {
//     alpha?: {
//       50?: string
//     }
//   }
// }

const theme = {
  baseFontSize: 14,
  colors: {
    alpha: {
      50: "#E6EFED",
      100: "#B2FFD5",
      200: "#8EFFC0",
      300: "#00F46D",
      400: "#00DE63",
      500: "#00CA5A",
      600: "#00B852",
      700: "#00A64A",
      800: "#009543",
      900: "#00863C",
      1000: "#007936",
    },
    beta: {
      50: "#faf9fa",
      100: "#F3F3F3",
      200: "#EEEDF0",
      300: "#D4D2DA",
      400: "#C5C2CD",
      500: "#B5B1BF",
      600: "#A29DAF",
      700: "#6D667F",
      800: "#413D4E",
    },
    gamma: {
      50: "#F4FBF8",
      100: "#DDF2EB",
      200: "#C4E9DC",
      300: "#89D2BA",
      400: "#64C4A4",
      500: "#37B38A",
      600: "#0B9C6C",
      700: "#005C42", // new color
      800: "#054932",
    },
  },
  components: {
    button: {
      primary: {
        //   spinnerColor: "alpha.600",
        regular: {
          //     bg: "alpha.500",
          //     color: "white",
          //     hover: {
          //       bg: "alpha.400",
          //       color: "white",
          //     },
          disabled: {
            // bg: "gamma.300",
            // color: "white",
          },
          //     loading: {
          //       bg: "beta.200",
          //     },
          //     selected: {
          //       bg: "alpha.600",
          //     },
        },
        //   ghost: {
        //     bg: "white",
        //     color: "alpha.500",
        //     hover: {
        //       bg: "white",
        //       color: "alpha.400",
        //     },
        //     disabled: {
        //       bg: "white",
        //       color: "alpha.300",
        //     },
        //     loading: {
        //       bg: "transparent",
        //     },
        //   },
      },
      secondary: {
        regular: {
          bg: "newTransparent.0",
          color: "alpha.500",
          hover: {
            bg: "newTransparent.0",
            color: "alpha.600",
          },
          disabled: {
            bg: "newTransparent.0",
            color: "beta.600",
          },
          loading: {
            bg: "newTransparent.0",
          },
        },
      },
      // disabled: {
      //   regular: {
      //     bg: "alpha.600",
      //     color: "white",
      //     hover: {
      //       bg: "alpha.600",
      //       color: "white",
      //     },
      //     disabled: {
      //       bg: "newTransparent.0",
      //       color: "white",
      //     },
      //     loading: {
      //       bg: "newTransparent.0",
      //     },
      //   },
      // },
    },
    modal: {
      bg: "alpha.700",
      borderTopColor: "alpha.700",
      boxShadowColor: "beta.800",
      borderRadius: "60px",
      danger: {
        borderTopColor: "delta.700",
        danger: {
          borderTopColor: "delta.700",
        },
      },
      input: {
        iconColor: "beta.600",
      },
      label: {
        fontSize: 6,
      },
      tooltip: {
        bg: "white",
        color: "beta.800",
        boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.08)",
        borderRadius: "2px",
      },
      tabs: {
        bg: "white",
        color: "beta.400",
        borderColor: "beta.400",
        active: {
          bg: "white",
          color: "beta.900",
        },
      },
      table: {
        bg: "white",
        th: {
          color: "beta.600",
        },
        tr: {
          hover: {
            bg: "alpha.50",
          },
        },
      },
    },
    navItem: {
      color: "beta.800",
    },
    link: {
      color: "alpha.600",
    },
  },
}

// @ts-ignore
export const appTheme = deepmerge<ThemeInterface>(prosapientTheme, theme)
