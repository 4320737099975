import { SelectOption } from "@prosapient/prosapient-styleguide"
import {
  FormValue,
  FormValueObject,
  ArrayKeysFromObject,
  SerializeFormValue,
  SerializeFormValues,
  WideSelectOption,
} from "shared/form/types"
import { format } from "date-fns/esm"
import * as R from "ramda"

const isSimpleSelect = (v: any): v is WideSelectOption => {
  if (
    v &&
    typeof v !== "boolean" &&
    typeof v !== "string" &&
    typeof v !== "number" &&
    !(v instanceof Date) &&
    !Array.isArray(v) &&
    v.label &&
    v.value !== undefined
  ) {
    return true
  }

  return false
}

const isMultiSelect = (v: any): v is WideSelectOption[] => {
  if (Array.isArray(v) && v.length > 0) {
    const [firstValue] = v

    if (isSimpleSelect(firstValue)) {
      return true
    }
  }

  return false
}

export function serializeValue<V extends FormValue>(v: V): SerializeFormValue<V>
export function serializeValue(v: FormValue) {
  if (R.isNil(v)) {
    return null
  }

  if (isSimpleSelect(v)) {
    return v.value
  }

  if (
    typeof v === "string" ||
    typeof v === "boolean" ||
    typeof v === "number" ||
    typeof v === "bigint" ||
    typeof v === "symbol"
  ) {
    return v
  }

  if (v instanceof Date) {
    return format(v, "yyyy-MM-dd")
  }

  if (isMultiSelect(v)) {
    return v.map(option => option.value)
  }

  if (Array.isArray(v)) {
    return v.filter(v => !R.isNil(v))
  }

  return serializeValues(v)
}

export const serializeValues = <V extends FormValueObject, PK extends ArrayKeysFromObject<V>>(
  values: V,
  permittedKeys?: PK
) => {
  const serializedValues = {} as SerializeFormValues<V, PK>

  for (const key in values) {
    if (!permittedKeys || permittedKeys?.includes(key)) {
      serializedValues[key as keyof typeof serializedValues] = serializeValue(
        values[key as keyof typeof serializedValues]
      )
    }
  }

  return serializedValues
}
