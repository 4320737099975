import { Box, Flex, Grid, Text } from "@prosapient/prosapient-styleguide"
import styled from "styled-components"
import { HEADER_MOBILE_HEIGHT } from "../../constants"

export const Title = styled(Text).attrs({ color: "beta.800", fontSize: 9, fontWeight: 3 })``

export const SubTitle = styled(Text).attrs({ color: "beta.800", fontWeight: 3, fontSize: 7, lineHeight: 1.75 })``

export const Paragraph = styled(Text).attrs({ color: "beta.800", fontSize: 6 })``

export const NavTabs = styled(Flex)`
  display: none;
  justify-content: space-between;
  text-align: center;
  font-weight: 500;

  @media only screen and (max-width: 1024px) {
    display: flex;
    margin-top: -0.5rem;
  }
`
export const NavTab = styled(Box)`
  flex: 1;
  cursor: pointer;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  line-height: 40px;
  height: 40px;
`

/*
 *
 * Projects, Workspace, Payments
 *
 * */

export const PageGridContainer = styled(Grid).attrs({ mb: 8, gridGap: 20 })`
  grid-template-columns: auto 452px;
  grid-template-areas: "main sidebar";

  & > *:nth-child(1) {
    grid-area: main;
  }

  & > *:nth-child(2) {
    grid-area: sidebar;
  }

  @media only screen and (max-width: 1024px) {
    grid-template-areas:
      "sidebar"
      "main";
    grid-template-columns: auto;
  }
`

export const PageMain = styled(Grid).attrs({ width: "100vw" })`
  padding: 2rem;
  min-height: 100%;

  @media only screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    min-height: calc(var(--vh) - ${HEADER_MOBILE_HEIGHT}px);
  }

  @media only screen and (max-width: 640px) {
    max-width: 100%;
    padding: 1rem;
    margin: 0 auto;
  }
`

export const MobilePageTitle = styled(Title).attrs({ mb: 8 })`
  display: none;
  text-align: left;

  @media only screen and (max-width: 1024px) {
    display: block;
  }
`
