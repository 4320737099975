import { ComponentPropsWithRef } from "react"
import { Image, Link } from "@prosapient/prosapient-styleguide"
import LogoImage from "accets/images/logo.svg"

export const Logo = ({ withLink, ...props }: { withLink?: boolean } & ComponentPropsWithRef<"img">) => {
  const logo = <Image src={LogoImage} alt="Logo" {...props} />

  if (withLink) {
    return <Link to="/">{logo}</Link>
  } else {
    return logo
  }
}
