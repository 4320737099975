import { Redirect, Route, Switch } from "react-router-dom"
import { ROUTES } from "components/constants"
import { RecentEmployment } from "./@recent-employment"
import { MyProfile } from "./@my-profile"
import { AuthGuard } from "hocs/AuthGuard"
import { IAuthTokenType } from "api/__generated__/schema"
import { useMe } from "hooks"
import { useEffect } from "react"
import { useGlobalSpinner } from "../../hooks/useGlobalSpinner"
import { ExpertTags } from "./@expert-tags"

const getRoutes = ({ showExpertTags }: { showExpertTags: boolean }) => [
  { path: ROUTES.mostRecentEmployment, title: "Current employment confirmation", component: RecentEmployment },
  { path: ROUTES.myProfile, title: "My Profile", component: MyProfile },
  {
    path: ROUTES.expertTags,
    title: "Expertise and industries",
    component: showExpertTags ? ExpertTags : () => <Redirect to={ROUTES.projects} />,
  },
]

export const ProfilePages = () => {
  const {
    me: { state, industries, expertise },
  } = useMe()
  const { showSpinner, hideSpinner } = useGlobalSpinner()

  useEffect(() => {
    if (!state?.authTokenType) showSpinner()

    if (state?.authTokenType && state?.authTokenType !== IAuthTokenType.Auth) {
      window.location.href = state?.isSignedUp ? ROUTES.authLogin : ROUTES.authSignUp
    }

    if (state?.authTokenType) hideSpinner()
  }, [state])

  const routes = getRoutes({
    showExpertTags: !state.isTagsSkipped && expertise.length === 0 && industries.length === 0,
  })

  return (
    <AuthGuard tokenTypes={[IAuthTokenType.Auth]} renderAfterValidation>
      <Switch>
        {routes.map(({ path, title, component }) => (
          <Route key={title} path={path} component={component} />
        ))}
      </Switch>
    </AuthGuard>
  )
}
