import { Box, Button } from "@prosapient/prosapient-styleguide"
import { Banner } from "./Banner"
import styled from "styled-components"
import { MIcon } from "components/shared/MIcon/MIcon"

const ButtonBox = styled(Box)`
  display: flex;
  grid-gap: 4px 8px;

  @media only screen and (max-width: 1200px) {
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  @media only screen and (max-width: 1024px) {
    flex-wrap: nowrap;
    justify-content: normal;
  }

  @media only screen and (max-width: 640px) {
    flex: 1 1 100%;
    flex-wrap: wrap;
  }
`

const TextBox = styled(Box)`
  display: flex;
  align-items: center;

  @media only screen and (max-width: 1200px) {
    flex: 1 1 100%;
    justify-content: center;
  }

  @media only screen and (max-width: 1024px) {
    flex: initial;
    justify-content: normal;
  }
`

const BannerButton = styled(Button)`
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.beta[800]};
  border-color: ${({ theme }) => theme.colors.beta[800]};
  border-width: 1px;

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.alpha[800]};
    border-color: ${({ theme }) => theme.colors.beta[800]};
  }

  &:disabled,
  &[disabled] {
    background: ${({ theme }) => theme.colors.white}!important;
  }

  @media only screen and (max-width: 640px) {
    width: 100%;
    margin-top: auto;
    padding-top: 1px;
    padding-bottom: 1px;
    min-height: 40px;
  }
`

type IProps = {
  show: boolean
  loading: boolean
  onConfirm: () => void
  onAdd: () => void
}

export const BannerConfirmRecent = ({ show = true, loading = false, onConfirm, onAdd }: IProps) => {
  if (!show) return null

  return (
    <Banner show={show} variant="error" hideIcon>
      <TextBox>
        <MIcon icon="info" color="white" style={{ marginRight: "8px" }} />
        Is your most recent employment up-to-date?
      </TextBox>
      <ButtonBox>
        <BannerButton variant="secondary" loading={loading} disabled={loading} type="submit" onClick={onConfirm}>
          Yes, confirm
        </BannerButton>
        <BannerButton variant="secondary" disabled={loading} type="submit" onClick={onAdd}>
          No, add recent employment
        </BannerButton>
      </ButtonBox>
    </Banner>
  )
}
