import { IProjectStatus } from "api/__generated__/schema"
import { Box, Flex, Text } from "@prosapient/prosapient-styleguide"
import React from "react"
import styled from "styled-components"

export const CardTitleWrapper = styled(Flex).attrs({
  justifyContent: "space-between",
  alignItems: "flex-start",
  // [EP-344]
  // mb: "14px",
  flexWrap: "nowrap",
})`
  border-radius: 2px;
  overflow: hidden;

  @media only screen and (max-width: 640px) {
    flex-wrap: wrap;

    & > *:first-child {
      order: 1;
      width: 100%;
    }

    & > *:last-child {
      order: -1;
      margin-bottom: 6px;
    }
  }
`

export const CardTitle = ({ title, status }: { title: string; status?: IProjectStatus }) => {
  return (
    <CardTitleWrapper>
      <Text fontSize="16px" fontWeight={4} pr="20px">
        {title}
      </Text>

      {status && [IProjectStatus.Accepted, IProjectStatus.Qualified].includes(status) && (
        <Box px="6px" py="2px" borderRadius="2px" color="white" bg="gamma.400">
          <Text fontWeight={4} fontSize={5} color="inherit" lineHeight="initial" style={{ whiteSpace: "nowrap" }}>
            You’re qualified
          </Text>
        </Box>
      )}

      {status === IProjectStatus.Archived && (
        <Box px="6px" py="2px" borderRadius="2px" color="white" bg="#642DB4">
          <Text fontWeight={4} fontSize={5} color="inherit" lineHeight="initial" style={{ whiteSpace: "nowrap" }}>
            Completed call
          </Text>
        </Box>
      )}
    </CardTitleWrapper>
  )
}
