import { useMemo } from "react"
import { PageContainer } from "components/shared/Layout/PageContainer"
import { Header } from "components/shared/Header"
import { Image, Box, Text, Flex, Button, SelectOption, useAlert, Alert } from "@prosapient/prosapient-styleguide"
import { Title } from "components/shared/Layout"
import { Form } from "react-final-form"
import styled from "styled-components"
import { FormBindings } from "shared/form-bindings-new"
import { TwoColumnStack, TwoColumnStackItem } from "shared/TwoColumnStack"
import { ROUTES } from "components/constants"
import { useSkipExpertTagsMutation, useUpdateProfileMutation } from "api/__generated__/schema"
import { serializeValues } from "shared/form/serializer"
import { ApolloError } from "@apollo/client"
import ImageBG from "accets/images/industries_and_technologies.svg"
import { useMe } from "hooks/useMe"

const StyledCard = styled(Box).attrs({ py: 9, px: 9, shadow: "light", borderRadius: "2px" })`
  @media screen and (max-width: 640px) {
    padding: 0 0 ${props => props.theme.newSpace[9]} 0;
    box-shadow: unset;
    border-radius: 0px;
  }
`

const StyledPageContainer = styled(PageContainer)`
  @media screen and (max-width: 640px) {
    padding: 0 ${props => props.theme.newSpace[8]} ${props => props.theme.newSpace[9]}
      ${props => props.theme.newSpace[8]};
  }
`

const StyledTitle = styled(Text).attrs({ fontWeight: 3 })`
  @media screen and (max-width: 640px) {
    font-size: ${props => props.theme.newFontSizes[7]};
  }
`

const StyledHeroContainer = styled(Flex).attrs({ maxWidth: "470px", mx: "auto", flexDirection: "column" })`
  @media screen and (max-width: 640px) {
    flex-direction: column-reverse;
    gap: 25px;
  }
`

const ActionButtonsContainer = styled(Flex)`
  gap: 5px;
  justify-content: center;

  @media only screen and (max-width: 640px) {
    flex-direction: column;
    gap: 8px;
  }
`

type FormValues = {
  expertise: SelectOption<string>[]
  industries: SelectOption<string>[]
}

export const ExpertTags = () => {
  const [skipExpertTags] = useSkipExpertTagsMutation()
  const [updateProfile] = useUpdateProfileMutation()
  const alert = useAlert(Alert.Context)
  const { me } = useMe()

  const initialValues = useMemo(
    () => ({
      expertise: me.expertise.map(e => ({ value: e, label: e })),
      industries: me.industries.map(e => ({ value: e, label: e })),
    }),
    [me.expertise, me.industries]
  )

  const onSubmit = async (values: FormValues) => {
    const serializedValues = serializeValues(values)

    try {
      await updateProfile({ variables: { payload: serializedValues } })
      await skipExpertTags()

      redirectToProfile()
    } catch (e) {
      const error = e as ApolloError

      alert.error(error?.message || "Something went wrong")
    }
  }

  const redirectToProfile = () => {
    window.location.href = `${ROUTES.myProfile}?tab=history`
  }

  return (
    <>
      <Header />
      {/* @ts-ignore */}
      <StyledPageContainer hasBackground={false} pb={0} pt={8}>
        <StyledHeroContainer>
          <Box>
            <Image src={ImageBG} style={{ margin: "0 auto" }} />
          </Box>
          <Box>
            <Title textAlign="center" mt={9} mb={6}>
              Add expertise and industry tags
            </Title>
            <Text textAlign="center" px={4} lineHeight={5}>
              By filling in your relevant Expertise and the Industries you are familiar with, you increase your chances
              of being matched with the projects most relevant to you.
            </Text>
          </Box>
        </StyledHeroContainer>
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={({ values, submitting, handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Box py={9}>
                  <TwoColumnStack hGutter={3} maxWidth={690}>
                    <TwoColumnStackItem>
                      <StyledCard>
                        <StyledTitle fontWeight={3}>Expertise</StyledTitle>
                        <Text py={4} lineHeight={5}>
                          For example, expertise like Marketing strategy, Financial analysis, or Healthcare consulting
                        </Text>
                        <FormBindings.ExpertisesSelect
                          name="expertise"
                          placeholder="Use ; to add several"
                          menuPlacement="top"
                        />
                      </StyledCard>
                    </TwoColumnStackItem>
                    <TwoColumnStackItem>
                      <StyledCard>
                        <StyledTitle fontWeight={3}>Industries</StyledTitle>
                        <Text py={4} lineHeight={5}>
                          For example, Go-To-Market strategies or Manufacturing optimisation
                        </Text>
                        <FormBindings.IndustriesSelect
                          name="industries"
                          placeholder="Use ; to add several"
                          menuPlacement="top"
                        />
                      </StyledCard>
                    </TwoColumnStackItem>
                  </TwoColumnStack>
                </Box>
                <ActionButtonsContainer mb={9}>
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={async () => {
                      await skipExpertTags()
                      redirectToProfile()
                    }}
                  >
                    Skip for now
                  </Button>
                  <Button
                    type="submit"
                    loading={submitting}
                    disabled={values.expertise.length === 0 && values.industries.length === 0}
                  >
                    Continue
                  </Button>
                </ActionButtonsContainer>
              </form>
            )
          }}
        />
      </StyledPageContainer>
    </>
  )
}
