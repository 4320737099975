import { Button, Flex } from "@prosapient/prosapient-styleguide"
import styled from "styled-components"

const Wrapper = styled(Flex)`
  flex-wrap: nowrap;
  grid-gap: 4px;

  @media only screen and (max-width: 1280px) {
    flex-wrap: wrap;
    & > * {
      flex: 1;
    }
  }
  @media only screen and (max-width: 1024px) {
    flex-wrap: nowrap;
    justify-content: flex-start;
    & > * {
      flex: none;
    }
  }
  @media only screen and (max-width: 640px) {
    flex-wrap: wrap;

    & > * {
      flex: 1;
    }
  }
`

type IProps = {
  complete: boolean
  link: string | null
  completeLabel: string
  loading: boolean
  actionLabel: string
  onAction: () => void
}

export const Actions = ({ complete, link, completeLabel, loading, actionLabel, onAction }: IProps) => {
  return complete ? (
    <>
      {link ? (
        <a href={link} target="_blank" style={{ textDecoration: "none" }}>
          <Button variant="secondary" onClick={() => {}}>
            {completeLabel}
          </Button>
        </a>
      ) : (
        <Button onClick={onAction}>{completeLabel}</Button>
      )}
    </>
  ) : (
    <Wrapper>
      {link && (
        <a href={link} target="_blank" style={{ textDecoration: "none", display: "inline-block" }}>
          <Button variant="secondary" onClick={() => {}}>
            {completeLabel}
          </Button>
        </a>
      )}
      <Button loading={loading} onClick={onAction}>
        {actionLabel}
      </Button>
    </Wrapper>
  )
}
