import React from "react"
import { FIcon } from "@prosapient/prosapient-styleguide"
import styled from "styled-components"

const StyledInputFile = styled.label`
  input {
    opacity: 0;
    position: absolute;
    left: -1000em;
  }
`

export const InputFile = (props: React.ComponentPropsWithRef<"input">) => {
  return (
    <StyledInputFile>
      <input {...props} type="file" />
      <span>
        <FIcon cursor="pointer" color="beta.700" hoverColor="alpha.800" icon="paperclip" size="lg" mr={2} />
      </span>
    </StyledInputFile>
  )
}
