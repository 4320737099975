import { Box, Button } from "@prosapient/prosapient-styleguide"
import ImageBG from "accets/images/opt-out_of_the_project.svg"
import { useHistory } from "react-router"
import { ROUTES } from "components/constants"
import { Title } from "components/shared/Layout"
import { PageContainer } from "components/shared/Layout/PageContainer"
import styled from "styled-components"
import { Helmet } from "react-helmet"
import { useEffect, useMemo, useState } from "react"
import { useLocation } from "react-use"
import { useGlobalSpinner } from "../../../hooks/useGlobalSpinner"

const StyledBox = styled(Box).attrs({ p: 10, maxWidth: 640, width: "100vw" })`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  margin: auto;

  @media only screen and (max-width: 640px) {
    max-width: 100%;
    margin: 0 auto;
    align-self: flex-start;
    box-shadow: none;
    padding: 1rem;
    min-height: calc(var(--vh));
  }
`

const StyledButton = styled(Button)`
  @media only screen and (max-width: 640px) {
    width: 100%;
    margin-top: auto;
    padding-top: 1px;
    padding-bottom: 1px;
    min-height: 40px;
  }
`

const Wrapper = styled(PageContainer).attrs({ hasBackground: false })`
  //background-color: #;
  min-height: var(--vh) !important;
`

export const DevLogin = () => {
  const { push } = useHistory()
  const [token, setToken] = useState("")
  const { hideSpinner } = useGlobalSpinner()

  const { hostname } = useLocation()
  const isLocal = useMemo(() => hostname === "localhost", [hostname])

  const handleLogin = () => {
    if (isLocal) {
      document.cookie = `JWT_AUTH_TOKEN=${token || ""}; path=/`
    }

    push(isLocal ? ROUTES.workspace : ROUTES.authLogin)
  }

  useEffect(() => hideSpinner(), [])

  return (
    <Wrapper>
      <Helmet>
        <title>Welcome</title>
        <link rel="preload" as="image" href={ImageBG} />
      </Helmet>

      <StyledBox>
        <Box width="100%">
          <Title mb={6}>Welcome to Expert Portal</Title>
        </Box>
        <StyledButton onClick={handleLogin}>Login</StyledButton>
      </StyledBox>

      {isLocal && <textarea cols={30} rows={10} style={{ width: "100%" }} onChange={e => setToken(e.target.value)} />}
    </Wrapper>
  )
}
