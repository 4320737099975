import { useEffect, useState } from "react"
import { Grid } from "@prosapient/prosapient-styleguide"
import { PageContainer } from "components/shared/Layout/PageContainer"
import { PageMainBox } from "components/shared/Layout/PageMainBox"
import styled from "styled-components"
import useMediaQuery from "@mui/material/useMediaQuery"

import { PageNav } from "./components/PageNav"
import { BasicInformation } from "./sections/BasicInformation"
import { EmploymentHistory } from "./sections/EmploymentHistory"
import { NavTab, NavTabs } from "components/shared/Layout"
import { Header } from "../../shared/Header"
import { useLocation } from "react-router"
import { Footer } from "components/shared/Footer"

const GridContainer = styled(Grid).attrs({ gridGap: 20 })`
  grid-template-columns: 452px auto;
  grid-template-areas: "info history";
  flex: 1;
  display: flex;

  & > *:nth-child(1) {
    grid-area: info;
    width: 100%;
    max-width: 452px;
    margin-top: 0;

    @media only screen and (max-width: 1024px) {
      grid-area: mobile;
      max-width: 100%;
    }
  }

  & > *:nth-child(2) {
    grid-area: history;
    width: 100%;

    @media only screen and (max-width: 1024px) {
      grid-area: mobile;
    }
  }

  @media only screen and (max-width: 1024px) {
    grid-template-areas: "mobile";
    grid-template-columns: auto;
    display: flex;

    & > * {
      width: 100%;
      margin: 0 auto auto;
      box-shadow: none;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;

      display: flex;
      justify-content: space-between;
      flex-direction: column;

      min-height: unset;
      //height: 100%;
      margin-bottom: 0;
    }
  }
`

type SectionTab = "info" | "history"

const tabs: { key: SectionTab; title: string }[] = [
  {
    key: "info",
    title: "Basic information",
  },
  {
    key: "history",
    title: "Employment history",
  },
]

export const MyProfile = () => {
  const { search } = useLocation()
  const hideMobile = useMediaQuery("(max-width:1024px)")

  const [tab, setTab] = useState<SectionTab>("info")

  useEffect(() => {
    const tab = new URLSearchParams(search).get("tab") || ""

    if (["info", "history"].includes(tab)) setTab(tab as SectionTab)
  }, [search])

  return (
    <>
      <Header navbar />
      <PageContainer hasBackground={false} centered={false} pb={0}>
        <PageMainBox>
          <PageNav />

          <NavTabs>
            {tabs.map(({ key, title }) => (
              <NavTab
                key={key}
                color={tab === key ? "alpha.600" : "beta.800"}
                hoverColor={tab === key ? "alpha.700" : "beta.700"}
                borderColor={tab === key ? "alpha.700" : "beta.300"}
                onClick={() => setTab(key)}
              >
                {title}
              </NavTab>
            ))}
          </NavTabs>

          <GridContainer>
            <BasicInformation style={tab !== "info" && hideMobile ? { display: "none" } : {}} />

            <EmploymentHistory style={tab !== "history" && hideMobile ? { display: "none" } : {}} />
          </GridContainer>
        </PageMainBox>

        <Footer />
      </PageContainer>
    </>
  )
}
