import { PageContainer } from "components/shared/Layout/PageContainer"
import { Header } from "components/shared/Header"
import { Sidebar } from "components/shared/Sidebar"
import { MobilePageTitle, PageGridContainer } from "components/shared/Layout"
import { ProjectList } from "./sections/ProjectList"
import { AuthGuard } from "hocs/AuthGuard"
import styled from "styled-components"
import { IAuthTokenType } from "../../api/__generated__/schema"
import { Footer } from "components/shared/Footer"
import { PageMainBox } from "../shared/Layout/PageMainBox"

const Body = styled(PageContainer)`
  @media only screen and (max-width: 1024px) {
    & > div {
      align-self: flex-start;
    }
  }
`

export const Projects = () => {
  return (
    <AuthGuard tokenTypes={[IAuthTokenType.Auth]}>
      <Header navbar />

      <Body hasBackground={false} centered={false} pb={0} position="relative">
        <PageMainBox display="grid" minHeight="100%">
          <MobilePageTitle>Projects</MobilePageTitle>

          <PageGridContainer>
            <ProjectList />

            <Sidebar pb={5} />
          </PageGridContainer>
        </PageMainBox>

        <Footer />
      </Body>
    </AuthGuard>
  )
}
