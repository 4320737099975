import { Alert, Box, Button, Popup, Spinner, Text, useAlert } from "@prosapient/prosapient-styleguide"
import { useMe } from "hooks"
import styled from "styled-components"
import { MIcon } from "components/shared/MIcon/MIcon"
import { useUploadCvMutation } from "api/__generated__/schema"
import { useState } from "react"
import { DeleteConfirmModal } from "./DeleteConfirmModal"

const ToolTipBox = styled(Box).attrs({ cursor: "pointer", color: "beta.400", hoverColor: "beta.800", p: 2 })`
  display: none;
  line-height: 0;
  position: relative;

  :before {
    content: "";
    background: blue;
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
  }
`

const FileName = styled("a").attrs({ target: "_blank" })`
  max-width: calc(100% - 26px);
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
    // color: ${props => props.theme.colors.beta["600"]};
  text-decoration: none;
  font-weight: 500;
  color: inherit;
`

const ToolTipWrapper = styled(Box)`
  display: flex;
  color: ${props => props.theme.colors.beta["600"]};

  .show-on-hover,
  .popup {
    display: none !important;
  }

  .show-on-hover {
    &:hover + .popup {
      display: block !important;
    }
  }

  &:hover {
    color: ${props => props.theme.colors.beta["800"]};

    .show-on-hover {
      display: inline-block !important;

      //&:hover + .popup {
      //  display: block !important;
      //}
    }
  }

  ${FileName} //}: {
          //}
`

const UploadButton = styled(Button).attrs({ ghost: true, inline: true, variant: "secondary", color: "beta.600" })`
  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.beta["800"]};
  }
`

const isInvalidExtension = (filename: string): boolean => {
  const extension = (filename.split(".").pop() || "").toLowerCase()

  const validExt = ["pages", "pdf", "jpg", "png", "jpeg", "doc", "docx", "docm", "dotx", "dotm", "wll", "wwl"]
  // get file extension

  return !validExt.includes(extension)
}

export const UploadCV = () => {
  const alert = useAlert(Alert.Context)
  const [uploadCV, { loading }] = useUploadCvMutation()

  const [deleteLoading, setDeleteLoading] = useState(false)
  const [openConfirmModal, setOpenConfirmModal] = useState(false)

  const { me, refetch: refetchMe } = useMe()

  const closeModal = () => {
    setOpenConfirmModal(false)
  }

  const deleteHandler = async () => {
    try {
      setDeleteLoading(true)
      await refetchMe()
      closeModal()
    } finally {
      setDeleteLoading(false)
    }
  }

  const changeHandler = async ({ target }: any) => {
    try {
      const {
        validity,
        files: [file],
      } = target

      if (!validity.valid) {
        alert.error("Upload failed. Invalid file")
        target.value = ""
        return
      }

      /*Maximum allowed size in bytes
   10MB Example
   Change first operand(multiplier) for your needs*/
      const maxAllowedSize = 10 * 1024 * 1024
      if (file.size > maxAllowedSize) {
        alert.error("Upload failed. Maximum allowed size 10MB")

        target.value = ""
        return
      }

      if (isInvalidExtension(file.name)) {
        alert.error("This format is not supported. Please, use PDF, Word, Pages, Jpeg or Png.")
        target.value = ""
        return
      }
      // eslint-disable-next-line
      console.log(file)

      const { data } = await uploadCV({ variables: { file } })

      if (data?.uploadCv.success) {
        alert.success("CV updated successfully")
        await refetchMe()
      } else {
        alert.error(data?.uploadCv.description)
      }
    } catch (e) {
      // eslint-disable-next-line
      console.error(e)

      // @ts-ignore
      alert.error(e?.message || e)
    } finally {
      target.value = ""
    }
  }

  return (
    <Box position="relative">
      {me?.cvFilename && me?.cvUrl && (
        <ToolTipWrapper>
          <FileName title={me?.cvFilename || ""} href={me?.cvUrl || ""}>
            {me?.cvFilename}
          </FileName>

          {deleteLoading ? (
            <Box style={{ display: "inline-block", marginLeft: "16px", paddingRight: "10px" }}>
              <Spinner />
            </Box>
          ) : (
            <>
              <ToolTipBox className="show-on-hover" onClick={() => setOpenConfirmModal(true)}>
                <MIcon v={2} icon="close" color="inherit" hoverColor="inherit" style={{}} />
              </ToolTipBox>
              <Popup
                className="popup"
                // width={popupSettings.width}
                width={130}
                placement="right"
                isOpen
                triggerAction="none"
                onClick={e => e.preventDefault()}
                contentStyle={{
                  overflowY: "auto",
                  padding: "14px 12px",
                }}
                content={() => (
                  <Text fontWeight={400} fontSize={5}>
                    Delete CV/Resume
                  </Text>
                )}
                children={<Box />}
              />
            </>
          )}
        </ToolTipWrapper>
      )}

      {(!me?.cvFilename || !me?.cvUrl) && (
        <UploadButton disabled={loading} loading={loading}>
          <label htmlFor="upload_CV_file" style={{ cursor: "pointer" }} onClick={e => e.stopPropagation()}>
            Upload CV/Resume
          </label>
        </UploadButton>
      )}

      <input
        id="upload_CV_file"
        type="file"
        name="upload_CV_file"
        required
        style={{ display: "none" }}
        accept=".pages, .pdf, .jpg, .png, .jpeg, .image/*,, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword"
        onChange={changeHandler}
      />

      <DeleteConfirmModal isOpen={openConfirmModal} onSubmit={deleteHandler} onClose={closeModal} />
    </Box>
  )
}
