import { Box, Button, Image, Spinner } from "@prosapient/prosapient-styleguide"
import ImageBG from "accets/images/link_expired.svg"
import { ROUTES } from "components/constants"
import { SubTitle } from "components/shared/Layout"
import { PageContainer } from "components/shared/Layout/PageContainer"
import styled from "styled-components"
import { PageMain } from "../components/PageMain"
import { useMe } from "hooks"
import { useMemo } from "react"

const StyledButton = styled(Button)`
  @media only screen and (max-width: 640px) {
    width: 100%;
    margin-top: auto;
    padding-top: 1px;
    padding-bottom: 1px;
    min-height: 40px;
  }
`

export const LinkExpired = () => {
  const { me, loading } = useMe()

  const button = useMemo(() => {
    if (loading) return ""

    return me?.state?.isSignedUp ? "Back to Expert Portal" : "Join Expert Portal"
  }, [me?.state?.isSignedUp, loading])

  const clickHandler = () => {
    window.location.href = me?.state?.isSignedUp ? ROUTES.authLogin : ROUTES.authSignUp
  }

  if (loading)
    return (
      <PageContainer>
        <Spinner sizing="lg" />{" "}
      </PageContainer>
    )

  return (
    <PageContainer>
      <PageMain width={540} style={{ textAlign: "center", justifyContent: "center" }}>
        <Box>
          <Image src={ImageBG} alt="answers submitted image" width="100%" />

          <SubTitle mt={10} mb={6}>
            The link has expired.
            {!me?.state?.isSignedUp && (
              <>
                <br />
                But, you still can join LEK Expert Portal
              </>
            )}
          </SubTitle>
        </Box>

        <StyledButton onClick={clickHandler}>{button}</StyledButton>
      </PageMain>
    </PageContainer>
  )
}
