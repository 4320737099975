import { FieldMetaState } from "react-final-form"
import { validate } from "shared/form/utils"
import { omit as ramdaOmit } from "ramda"

export const isFormFieldInvalid = (meta: FieldMetaState<any>): boolean => false
// (meta.touched && meta.error) || (meta.submitError && !meta.dirtySinceLastSubmit)

export const omitFormFieldConfigProperties = <O extends Record<string, any>>(object: O) => {
  const properties = [
    "afterSubmit",
    "allowNull",
    "beforeSubmit",
    "format",
    "formatOnBlur",
    "initialValue",
    "isEqual",
    "parse",
    "subscription",
    "validate",
    "validateFields",
  ] as const

  return ramdaOmit(properties, object)
}

export const inlineEmailValidation = (email?: string | null) => {
  const errorMessage = validate({ email }, { email: { email: { message: "The email is not valid" } } })

  if (errorMessage) {
    return errorMessage.email
  }
}

export const inlineLinkedinValidation = (linkedinUrl?: string | null) => {
  const errorMessage = validate(
    { linkedinUrl },
    { linkedinUrl: { linkedinUrl: { message: "The LinkedIn link is not valid" } } }
  )

  if (errorMessage) {
    return errorMessage.linkedinUrl
  }
}
