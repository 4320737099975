import { useEffect, useMemo, useRef } from "react"
import { Box } from "@prosapient/prosapient-styleguide"
import { useTrainingContext } from "../hooks/useTraining"
import { ComplianceMarkdown } from "./ComplianceMarkdown"

export const Steps = ({ maxWidth }: { maxWidth: number }) => {
  const {
    state: { currentStep, steps },
  } = useTrainingContext()

  const myRef = useRef(null)
  const markdown = useMemo(() => steps[currentStep] || "", [steps, currentStep])

  useEffect(() => {
    // @ts-ignore
    myRef?.current?.scrollIntoView()
  }, [markdown])

  return (
    <Box width="100%" maxWidth={maxWidth} mx="auto" ref={myRef}>
      <ComplianceMarkdown md={markdown} />
    </Box>
  )
}
