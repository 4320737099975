import {
  Alert,
  Box,
  Button,
  Flex,
  Label,
  Popup,
  PopupPlacement,
  Text,
  useAlert,
  VStack,
} from "@prosapient/prosapient-styleguide"
import { useHistory, useLocation } from "react-router"
import { ROUTES } from "components/constants"
import { Paragraph, SubTitle, Title } from "components/shared/Layout"
import { PageContainer } from "components/shared/Layout/PageContainer"
import { PageMain } from "components/@screening/components/PageMain"
import { DeclineProjectModal } from "./DeclineProjectModal"
import { useEffect, useMemo, useState } from "react"
import { validate } from "shared/form/utils"
import { Textarea } from "shared/form/bindings"
import { Form as FinalForm } from "react-final-form"
import styled from "styled-components"
import { useResizeDetector } from "react-resize-detector"
import { MIcon } from "components/shared/MIcon/MIcon"
import { PhoneSelect } from "../../shared/PhoneSelect"
import {
  IProjectStatus,
  IScreeningQuestionAnswer,
  useAcceptProjectMutation,
  useProjectQuery,
  useRejectProjectMutation,
} from "api/__generated__/schema"
import { useMe } from "hooks"
import { serializeValues } from "shared/form/serializer"

const StyledButton = styled(Button)`
  @media only screen and (max-width: 640px) {
    width: 100%;
    margin-top: auto;
    padding-top: 1px;
    padding-bottom: 1px;
    min-height: 40px;
  }
`

const ActionBox = styled(Flex).attrs({ mt: 9 })`
  @media only screen and (max-width: 640px) {
    width: 100%;
    flex-wrap: wrap;
  }
`

const PageMainSQ = styled(PageMain).attrs({ width: 690 })`
  text-align: left;

  @media only screen and (max-width: 640px) {
    flex-direction: column;
    overflow: hidden;

    .form {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
    }
  }
`

// FIX fro https://prosapient-prod.atlassian.net/browse/EP-196
const QUESTION_PREFIX = "PREFIX"

export const Main = () => {
  const { push } = useHistory()
  const [orientation, setOrientation] = useState(window?.screen?.orientation?.type)
  const { width, ref } = useResizeDetector({
    handleWidth: true,
    onResize: () => {},
  })

  const [openModal, setOpenModal] = useState(false)
  const [phone, setPhone] = useState(" ")
  const [isPhoneValid, setIsPhoneValid] = useState(false)

  const alert = useAlert(Alert.Context)

  const { search } = useLocation()
  const { me } = useMe()

  const [acceptProject] = useAcceptProjectMutation()
  const [rejectProject] = useRejectProjectMutation()

  const externalId = new URLSearchParams(search).get("project_external_id") || ""
  const { data, loading } = useProjectQuery({ variables: { externalId }, fetchPolicy: "network-only" })

  const popupSettings = useMemo(() => {
    const placement: PopupPlacement = width && width < 600 ? "top" : "right"
    const popupWidth = width && width < 480 ? "70vw" : "360px"

    return { placement, width: popupWidth }
  }, [width, orientation])

  const constraints = useMemo(() => {
    const defaultField: any = {}

    if (!data?.project?.screeningQuestions?.length) {
      return defaultField
    }

    data?.project?.screeningQuestions.forEach((question, index) => {
      defaultField[QUESTION_PREFIX + index] = JSON.parse(JSON.stringify({ presence: { allowEmpty: false } }))
    })

    return defaultField
  }, [data?.project?.screeningQuestions])

  const initFormValues = useMemo(() => {
    const defaultField: any = {}

    if (!data?.project?.screeningQuestions?.length) {
      return defaultField
    }

    data?.project?.screeningQuestions.forEach((question, index) => {
      defaultField[QUESTION_PREFIX + index] = question?.answer || ""
    })

    return defaultField
  }, [data?.project?.screeningQuestions])

  const onValidate = (values: any) => {
    return validate(values, constraints)
  }

  const onSubmit = async (values: any) => {
    try {
      const input = Object.entries({ ...serializeValues(values) })
      const answers: IScreeningQuestionAnswer[] = input.map(
        ([question, answer]) =>
          ({
            question: data?.project?.screeningQuestions[+question.replace(QUESTION_PREFIX, "")]?.question,
            answer,
          } as IScreeningQuestionAnswer)
      )

      const _phone = (phone || "")?.replaceAll("+", "").replaceAll(" ", "")
      const phoneValue = _phone ? `+${_phone}` : null

      const result = await acceptProject({ variables: { externalId, answers, phone: phoneValue } })

      if (result?.data?.acceptProject?.success) {
        push(ROUTES.screeningSubmitted)
      } else {
        alert.error(result?.data?.acceptProject?.description)
      }
    } catch (e) {
      /*eslint-disable */
      console.error(e)
      /*eslint-enable */

      // @ts-ignore
      alert.error(e?.message || e)
    }
  }

  const handleRejectProject = async (values: any) => {
    try {
      const input = Object.entries({ ...serializeValues(values) })
      const answers: IScreeningQuestionAnswer[] = input.map(
        ([question, answer]) =>
          ({
            question: data?.project?.screeningQuestions[+question.replace(QUESTION_PREFIX, "")]?.question,
            answer,
          } as IScreeningQuestionAnswer)
      )

      const result = await rejectProject({ variables: { externalId, answers } })

      if (result?.data?.rejectProject?.success) {
        setOpenModal(false)
        push(ROUTES.projectInvitationDeclined + search)
      } else {
        alert.error(result?.data?.rejectProject?.description)
      }
    } catch (e) {
      /*eslint-disable */
      console.error(e)
      /*eslint-enable */

      // @ts-ignore
      alert.error(e?.message || e)
    }
  }

  useEffect(() => {
    if (loading || !data?.project) {
      if (data?.project === null) {
        push(ROUTES.linkExpired)
      }

      return
    }

    if (!loading && !data?.project?.status) push(ROUTES.linkExpired)

    const noScreeningQuestions = !data?.project?.screeningQuestions?.length

    if ([IProjectStatus.Accepted, IProjectStatus.Qualified].includes(data?.project?.status)) {
      return noScreeningQuestions ? push(ROUTES.inviteAccepted) : push(ROUTES.screeningSubmitted)
    }

    // if (data?.project?.status !== IProjectStatus.Pending || data?.project?.isLive === false) {
    if (data?.project?.status !== IProjectStatus.Pending) {
      return push(ROUTES.linkExpired)
    }

    if (noScreeningQuestions) {
      try {
        const _phone = (phone || "")?.replaceAll("+", "").replaceAll(" ", "")
        const phoneValue = _phone ? `+${_phone}` : null

        acceptProject({ variables: { externalId, answers: [], phone: phoneValue } }).then(() => {
          push(ROUTES.inviteAccepted)
        })
      } catch (e) {
        /*eslint-disable */
        console.error(e)
        /*eslint-enable */

        // @ts-ignore
        alert.error(e?.message || e)
      }
    }
  }, [data, loading])

  useEffect(() => {
    setPhone(me?.phone || "")
  }, [me?.phone])

  useEffect(() => {
    const handleOrientationChange = () => setOrientation(window?.screen?.orientation?.type)
    window.addEventListener("orientationchange", handleOrientationChange)
    return () => window.removeEventListener("orientationchange", handleOrientationChange)
  }, [])

  if (!data?.project?.screeningQuestions?.length) return null

  return (
    <PageContainer pb={0}>
      <PageMainSQ ref={ref}>
        <Box>
          <Title>You’ve been invited to the project{data?.project?.name ? ` ${data?.project?.name}` : ""}.</Title>

          {/*[EP-344]*/}
          {data?.project?.invitationBrief && (
            <>
              <SubTitle mt={6} mb={2}>
                Project Overview
              </SubTitle>

              <Paragraph preserveFormatting my={4}>
                {data?.project?.invitationBrief || ""}
              </Paragraph>
            </>
          )}

          <Box my={8} borderBottom="1px solid" borderBottomColor="beta.300" />

          <SubTitle mt={6} mb={2}>
            Screening Questions
          </SubTitle>

          <Paragraph my={4}>
            In order to participate in this project, can you please briefly answer the following questions?
            <br />
            We use this to highlight your experience
          </Paragraph>
        </Box>

        <FinalForm
          // initialValues={data?.project?.screeningQuestions || {}}
          initialValues={initFormValues}
          onSubmit={onSubmit}
          validate={onValidate}
          render={
            // @ts-ignore
            ({ handleSubmit, submitting, invalid = false, values }) => {
              return (
                <>
                  <form className="form" autoComplete="off" style={{ width: "100%" }} onSubmit={handleSubmit}>
                    <VStack mt={8}>
                      {data?.project?.screeningQuestions?.map(({ question }, idx) => (
                        <Box key={QUESTION_PREFIX + question + idx} mb={9}>
                          <Label
                            htmlFor={`textarea${idx}`}
                            color="beta.600"
                            style={{ width: "100%", fontSize: "1rem" }}
                          >
                            <Text color="inherit" inline preserveFormatting>
                              {idx + 1}. {question}*
                            </Text>
                          </Label>
                          <Textarea
                            id={`textarea${idx}`}
                            rows={1}
                            autoexpandable
                            name={QUESTION_PREFIX + idx}
                            required
                            maxLength={-1}
                            placeholder="Type answer here..."
                            maxRows={10}
                          />
                        </Box>
                      ))}

                      <Box mb={9}>
                        <Label color="beta.600" style={{ width: "100%", fontSize: "1rem" }}>
                          {1 + (data?.project?.screeningQuestions?.length || 0)}. Your mobile phone number*
                          <Popup
                            width={popupSettings.width}
                            placement={popupSettings.placement}
                            onClick={e => e.preventDefault()}
                            contentStyle={{
                              overflowY: "auto",
                              padding: "14px 12px",
                            }}
                            content={() => (
                              <Text fontWeight={400} fontSize={5}>
                                We use your number to contact you regarding projects you are on or projects we think
                                would interest you.
                                <br />
                                It will not be disclosed to any third party
                              </Text>
                            )}
                          >
                            <Box
                              cursor="pointer"
                              hoverColor={"beta.700"}
                              style={{
                                bottom: "-0.4rem",
                                position: "relative",
                                marginLeft: "3px",
                              }}
                            >
                              <MIcon icon="info" />
                            </Box>
                          </Popup>
                          <PhoneSelect
                            mandatory
                            dropdownPlacement="top"
                            phone={phone}
                            setPhone={setPhone}
                            isValid={isPhoneValid}
                            setIsValid={setIsPhoneValid}
                          />
                        </Label>
                      </Box>
                    </VStack>

                    <ActionBox>
                      <StyledButton
                        type="submit"
                        disabled={invalid || !isPhoneValid}
                        loading={submitting}
                        onClick={event => {
                          /* @ts-ignore */
                          handleSubmit(event)
                        }}
                      >
                        Accept the invitation & submit my answers
                      </StyledButton>
                      <Box m={2} />
                      <StyledButton type="button" variant="secondary" onClick={() => setOpenModal(true)}>
                        Decline project
                      </StyledButton>
                    </ActionBox>
                  </form>

                  <DeclineProjectModal
                    isOpen={openModal}
                    close={() => setOpenModal(false)}
                    submit={() => handleRejectProject(values)}
                  />
                </>
              )
            }
          }
        />
      </PageMainSQ>
    </PageContainer>
  )
}
