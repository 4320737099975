import { useLocation } from "react-router"
import { NavLink as ReactNavLink } from "react-router-dom"
import { Nav, NavPanel } from "@prosapient/prosapient-styleguide"
import { ROUTES } from "components/constants"
import { useMemo } from "react"
import styled from "styled-components"

const NavTab = styled(ReactNavLink)`
  position: relative;
  display: inline-block;
  padding: 20px;
  width: 100%;
  text-decoration: none;
  font-weight: 700;
  color: ${({ theme, isActive }) => (isActive ? theme.colors.alpha["600"] : theme.colors.beta["800"])};

  :hover {
    color: ${({ theme }) => theme.colors.alpha["600"]} !important;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    height: 0;
    left: 20px;
    right: 20px;

    background: ${({ theme }) => theme.colors.alpha["600"]};
    border-radius: 10px 10px 0 0;
    transition: height 0.3s ease-in-out;
  }

  &.active {
    color: ${({ theme }) => theme.colors.alpha["600"]};

    &:after {
      height: 4px;
    }
  }

  a {
    display: inline-block;
    padding: 20px;
    width: 100%;
    text-decoration: none;
    font-weight: 700;
  }
`

const routes = [
  // { label: "Workspace", path: ROUTES.workspace },
  { label: "Projects", path: ROUTES.projects },
  { label: "Payment", path: ROUTES.payments },
  { label: "Documents", path: ROUTES.documents },
]

export const Navbar = () => {
  const { pathname } = useLocation()

  const displayRoutes = useMemo(
    () =>
      routes.map(params => {
        const active = pathname.startsWith(params.path) || params.path.startsWith(pathname)
        return { active, ...params }
      }),
    [routes, pathname]
  )

  return (
    <Nav ml="5px">
      <NavPanel>
        {displayRoutes.map(({ active, label, path }) => (
          <NavTab key={label} to={path} className={active ? "active" : ""}>
            {label}
          </NavTab>
        ))}
      </NavPanel>
    </Nav>
  )
}
