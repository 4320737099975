import { Box, Button, Image } from "@prosapient/prosapient-styleguide"
import ImageBG from "accets/images/compliance_completed.svg"
import { useHistory } from "react-router"
import { ROUTES } from "components/constants"
import { SubTitle } from "components/shared/Layout"
import { PageContainer } from "components/shared/Layout/PageContainer"
import styled from "styled-components"
import { PageMain } from "components/@screening/components/PageMain"
import { useMe } from "hooks"
import { IAuthTokenType } from "api/__generated__/schema"

const StyledButton = styled(Button)`
  @media only screen and (max-width: 640px) {
    width: 100%;
    margin-top: auto;
    padding-top: 1px;
    padding-bottom: 1px;
    min-height: 40px;
  }
`

export const ComplianceComplete = () => {
  const { push } = useHistory()
  const {
    me: { state, expertise, industries },
  } = useMe()

  const shouldRedirectToExpertTags = !state?.isTagsSkipped && expertise.length === 0 && industries.length === 0

  const nextPage = () => {
    if (state?.authTokenType && state?.authTokenType === IAuthTokenType.Compliance) {
      window.location.href = ROUTES.authLogin
      return
    }

    if (shouldRedirectToExpertTags) {
      return push(ROUTES.expertTags)
    }

    return push(ROUTES.projects)
  }

  return (
    <PageContainer>
      <PageMain width={540} style={{ textAlign: "center", justifyContent: "center" }}>
        <Box>
          <Image src={ImageBG} alt="answers submitted image" width="100%" />

          <SubTitle mt={12} mb={6}>
            Compliance training is completed
          </SubTitle>
        </Box>

        <StyledButton onClick={nextPage}>
          {shouldRedirectToExpertTags ? "Continue" : "Go to Expert Portal"}
        </StyledButton>
      </PageMain>
    </PageContainer>
  )
}
