import { Flex, Spinner } from "@prosapient/prosapient-styleguide"
import * as React from "react"

export const GlobalSpinner = ({ show = true, children }: { show?: boolean; children?: JSX.Element }) => (
  <>
    {show && (
      <Flex
        style={{
          position: "fixed",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          transform: "scale(1.5)",
          backgroundColor: "#F3F3F3",
        }}
        zIndex="9999"
      >
        <Spinner sizing="lg" />
      </Flex>
    )}
    {children}
  </>
)
