import React from "react"
import {
  Hint as UIKitHint,
  Datepicker as UIKitDatepicker,
  DatepickerProps as UIKitDatepickerProps,
  Daterange as UIKitDaterange,
  DaterangeProps as UIKitDaterangeProps,
  Label as UIKitLabel,
} from "@prosapient/prosapient-styleguide"
import { useField } from "react-final-form"
import { FormBindings } from "shared/form-bindings-new"

type CustomProps = {
  label: string
  hint?: string
  disabled?: boolean
  required?: boolean
}

type DatepickerProps = FormBindings.Props<HTMLInputElement, UIKitDatepickerProps> & CustomProps
type DaterangeProps = FormBindings.Props<HTMLInputElement, UIKitDaterangeProps> & CustomProps

export const FormDatepicker = ({ name, ...props }: DatepickerProps) => {
  const { input, meta } = useField(name)
  const fieldIsInvalid = FormBindings.isFieldInvalid(meta) || meta.submitError

  return (
    <>
      {props.label && (
        <FormBindings.StyledLabel disabled={props.disabled} required={props.required} invalid={fieldIsInvalid}>
          {props.label}
        </FormBindings.StyledLabel>
      )}
      <UIKitDatepicker
        formatDate="do MMM y"
        {...FormBindings.omitFieldConfigProperties(props)}
        onChange={input.onChange}
        value={input.value}
        invalid={fieldIsInvalid}
      />
      {fieldIsInvalid ? (
        <UIKitHint invalid={fieldIsInvalid}>{meta.error || meta.submitError}</UIKitHint>
      ) : (
        props.hint && <UIKitHint>{props.hint}</UIKitHint>
      )}
    </>
  )
}

export const FormDaterange = ({ name, ...props }: DaterangeProps) => {
  const { input, meta } = useField(name, props)
  const fieldIsInvalid = FormBindings.isFieldInvalid(meta)

  return (
    <>
      {props.label && (
        <UIKitLabel required={props.required} invalid={fieldIsInvalid}>
          {props.label}
        </UIKitLabel>
      )}
      <UIKitDaterange {...props} onChange={input.onChange} value={input.value} invalid={fieldIsInvalid} />
      {fieldIsInvalid ? (
        <UIKitHint invalid={fieldIsInvalid}>{meta.error || meta.submitError}</UIKitHint>
      ) : (
        props.hint && <UIKitHint>{props.hint}</UIKitHint>
      )}
    </>
  )
}
