import { format as dateFnsFormat, utcToZonedTime } from "date-fns-tz"
import dayjs, { Dayjs } from "dayjs"

type TimeZoneProps = {
  timeZone: string
}

type OptionsProps = TimeZoneProps

export const formatToTimeZone = (date: Date | string, format: string, { timeZone }: OptionsProps): string => {
  return dateFnsFormat(utcToZonedTime(date, timeZone), format)
}

export const convertToTimeZone = (date: Date | string, { timeZone }: OptionsProps): Date => {
  return utcToZonedTime(date, timeZone)
}

export const dateToString = (date: string): string => {
  return dayjs(date).format()
}

// TODO rewrite
export const convertTime = (isoTime: string): string => {
  let hours = parseInt(isoTime.substring(0, 2), 10)
  const minutes = isoTime.substring(3, 5)
  let ampm = "am"

  if (hours === 12) {
    ampm = "pm"
  } else if (hours === 0) {
    hours = 12
  } else if (hours > 12) {
    hours -= 12
    ampm = "pm"
  }

  return `${hours}:${minutes} ${ampm}`
}

export const toDate = (date: string | Date | Dayjs): Date => {
  return dayjs(date).toDate()
}

const formats = {
  ll: "D MMM YYYY",
  date: "YYYY-MM-DD",
  dateISO: "YYYY-MM-DDTHH:mm:00Z",
  short: "DD.MM",
  datetime: "DD.MM.YY, HH:mm",
} as const

type DateFormatTypes = "date" | "dateISO" | "short" | "datetime" | "ll"

export const format = (date: Dayjs | Date | string, type: DateFormatTypes): string => {
  const dateFormat = formats[type] || "date"

  return dayjs(date).format(dateFormat)
}

export const isFuture = (date: string): boolean => {
  try {
    return dayjs().isBefore(dayjs(date))
  } catch (e) {
    return false
  }
}
