import { Box, Image } from "@prosapient/prosapient-styleguide"
import { Paragraph } from "components/shared/Layout"
import ImageBG from "accets/images/payment_history.svg"
import styled from "styled-components"

const StyledParagraph = styled(Paragraph).attrs({ px: 12, my: 10 })`
  @media only screen and (max-width: 640px) {
    font-weight: bold;
    padding: 0;
  }
`

export const Empty = () => (
  <Box m="auto" p={10} maxWidth={640} width="100%" style={{ textAlign: "center" }}>
    <Image src={ImageBG} alt="Invitation declined image" width="100%" />

    <StyledParagraph>Here you will find all your payment history.</StyledParagraph>
  </Box>
)
