import { Box, Flex } from "@prosapient/prosapient-styleguide"
import styled from "styled-components"
import { HEADER_DESKTOP_HEIGHT, HEADER_MOBILE_HEIGHT } from "components/constants"

const Wrapper = styled(Flex).attrs({ bg: "white" })`
  position: ${props => props.position || "static"};
  min-height: calc(var(--vh) - ${HEADER_DESKTOP_HEIGHT}px);

  @media only screen and (max-width: 1024px) {
    padding-bottom: 0 !important;
    min-height: calc(var(--vh) - ${HEADER_MOBILE_HEIGHT}px);
  }
`

const Content = styled(Box)`
  max-width: 100%;
  @media only screen and (max-width: 640px) {
    margin: 0 auto;
    align-self: flex-start;
    box-shadow: none;
    min-height: calc(var(--vh) - ${HEADER_MOBILE_HEIGHT}px);
  }
`

const ContentWithBackground = styled(Box).attrs({ shadow: "light", borderRadius: 2, bg: "white" })`
  padding: 2rem;
  max-width: 100%;

  @media only screen and (max-width: 640px) {
    margin: 0 auto;
    max-width: 100%;
    box-shadow: none;
    min-height: calc(var(--vh) - ${HEADER_MOBILE_HEIGHT}px);
    padding: 0;
  }
`

type Props = {
  children: any
  centered?: boolean
  hasBackground?: boolean
  pb?: number
  position?: "absolute" | "relative" | "static" | "fixed"
}

export const PageContainer = ({
  children,
  hasBackground = true,
  centered = true,
  position = "static",
  pb,
  ...rest
}: Props) => {
  return (
    <Wrapper style={{ position, paddingBottom: pb ?? 56 }} {...rest}>
      {hasBackground ? (
        <ContentWithBackground m={centered ? "auto" : null}>{children}</ContentWithBackground>
      ) : (
        <Content m={centered ? "auto" : null}>{children}</Content>
      )}
    </Wrapper>
  )
}
