import { useEffect, useState } from "react"
import { ApolloLink } from "@apollo/client"
import { Text, Box, Flex } from "@prosapient/prosapient-styleguide"
import { EventEmitter } from "fbemitter"
import styled from "styled-components"

type AppVersion = string | undefined

enum AppUpgradeStrategy {
  NoUpgrade = "no-upgrade", // nothing to do
  SilentUpgrade = "silent-upgrade", // handle in BrowserRoter (reload page on next page)
  SoftUpgrade = "soft-upgrade", // show notice + silent-upgrade
  HardUpgrade = "hard-upgrade", // reload page immediately
}

const DEV_VERSION = "development"
// we keep app version on __PT__ it sets in loader.js and build scripts
const getAppVersion = () => ((window as any).__PT__ || DEV_VERSION) as AppVersion

const emitter = new EventEmitter()

//on hard-upgrade reload the page
emitter.once(AppUpgradeStrategy.HardUpgrade, () => window.location.reload())

const checkUpgradeStrategy = (strategy: AppUpgradeStrategy | undefined | null) => {
  if (getAppVersion() === DEV_VERSION) return
  if (!strategy) return
  if (strategy === AppUpgradeStrategy.NoUpgrade) return

  emitter.emit(strategy)
}

//do not change headers it synchronized with backend
const VERSION_HEADER = "pt-fe-version"
const STRATEGY_HEADER = "pt-fe-upgrade-strategy"

const appVersionLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }: { headers: any }) => ({
    headers: {
      ...headers,
      [VERSION_HEADER]: getAppVersion(),
    },
  }))

  if (forward) {
    return forward(operation).map(response => {
      const context = operation.getContext()
      const headers = context.response && context.response.headers

      if (headers) {
        const strategy = headers.get(STRATEGY_HEADER)
        checkUpgradeStrategy(strategy as AppUpgradeStrategy)
      }

      return response
    })
  }

  return null
})

const VersionWarningBox = styled(Box)`
  z-index: 1000;
  position: fixed;
  top: 10px;
  left: 50vw;

  button {
    padding: 0;
    border: none;
    color: #fff;
    background: transparent;
    text-decoration: underline;
    line-height: 1.5;
    cursor: pointer;
    outline: none;

    &:hover {
      opacity: 0.8;
    }
  }
`

const useAppStaleCheck = (strategy: AppUpgradeStrategy) => {
  const [staleVersion, setStaleVersion] = useState<boolean>(false)

  useEffect(() => {
    const subscription = emitter.once(strategy, () => setStaleVersion(true))
    return () => subscription.remove()
  })

  return staleVersion
}

const AppVersionCheck = () => {
  const appIsStale = useAppStaleCheck(AppUpgradeStrategy.SoftUpgrade)

  return appIsStale ? (
    <VersionWarningBox rounded bg="purples.2" px={3} py={1}>
      <Flex alignItems="center">
        <Text color="whites.3" mr={2}>
          New release available
        </Text>
        <button onClick={() => window.location.reload()}>Update now</button>
      </Flex>
    </VersionWarningBox>
  ) : (
    <></>
  )
}

export { AppUpgradeStrategy, useAppStaleCheck, AppVersionCheck, appVersionLink }
