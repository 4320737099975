import { Flex } from "@prosapient/prosapient-styleguide"
import { PopupPlacement } from "@prosapient/prosapient-styleguide/dist/components/shared/Popup"
import { SubLabel, Sup } from "../../BasicInformation/styles"
import { ToolTip } from "../../../components/ToolTip"

type IProps = {
  text: string
  tip: string
  required?: boolean
  placement?: PopupPlacement | undefined
}

export const FormField = ({ text, tip, placement, required }: IProps) => {
  return (
    <Flex>
      <SubLabel fontWeight={4} mr={2}>
        {text}
        {required && <Sup children="*" />}
      </SubLabel>
      <ToolTip text={tip} placement={placement} />
    </Flex>
  )
}
