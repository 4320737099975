import { Box, Text } from "@prosapient/prosapient-styleguide"
import styled from "styled-components"

const PageTitle = styled(Text).attrs({ fontWeight: 4, mr: 5, inline: true })`
  font-size: 1.142rem;

  @media only screen and (max-width: 640px) {
    font-size: 2rem;
  }
`

export const PageNav = () => (
  <>
    <Box mb={8}>
      <PageTitle>My Profile</PageTitle>
      {/*<Text*/}
      {/*  fontSize={6}*/}
      {/*  fontWeight={2}*/}
      {/*  inline*/}
      {/*  color="alpha.600"*/}
      {/*  onClick={() => alert("You can't sync profile with LinkedIn. Try again later!")}*/}
      {/*>*/}
      {/*  Sync profile with Linkedin*/}
      {/*</Text>*/}
    </Box>
  </>
)
