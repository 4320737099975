import { Box, Checkbox, Grid, Select, Text, Textarea } from "@prosapient/prosapient-styleguide"
import { StackItem, StyledInput } from "../../BasicInformation/styles"
import { ChangeEvent, useEffect, useMemo, useState } from "react"
import { months as monthOptions } from "data/months"
import { IEmployment } from "api/__generated__/schema"
import validate from "../utils/validate"
import { fillEmploymentPayload } from "../utils/mapper"
import { FormField } from "./FormField"
import styled from "styled-components"
import { isFuture } from "shared/date"
import { EditEmployment } from "../types"
import { LocationsSelect } from "shared/selects/LocationsSelect"

export const StackItemCheckbox = styled(StackItem)`
  display: flex;
  border-radius: 2px;
  padding: 8px 4px;
  background: ${props => {
    // @ts-ignore
    return props.theme.colors.gamma[50]
  }};
`

type IProps = {
  isExists: boolean
  data?: IEmployment | EditEmployment | null
  mt: string
  onChange: (data: EditEmployment) => void
}

export const EmploymentForm = ({ isExists, data, mt = "0", onChange }: IProps) => {
  const [company, setCompany] = useState(data?.company || "")
  const [endMonth, setEndMonth] = useState<null | number>(data?.endMonth || null)
  const [endYear, setEndYear] = useState<null | number>(data?.endYear || null)
  const [isCurrent, setIsCurrent] = useState(isExists && endMonth === null && endYear === null)
  const [location, setLocation] = useState(data?.location || "")
  const [position, setPosition] = useState(data?.position || "")
  const [responsibilities, setResponsibilities] = useState(data?.responsibilities || "")
  const [startMonth, setStartMonth] = useState<null | number>(data?.startMonth || null)
  const [startYear, setStartYear] = useState<null | number>(data?.startYear || null)

  const yearOptions = useMemo(() => {
    const currentYear = new Date().getFullYear()
    const minYearAgo = 100

    return Object.keys(new Array(minYearAgo).fill(null)).map(idx => {
      const value = currentYear - +idx
      return { value, label: value }
    })
  }, [])

  const isValidStartDate = useMemo(() => {
    if (startYear === null || startMonth === null) return true
    return validate.isValidStartDate(startYear, startMonth)
  }, [startYear, startMonth])

  const isFutureEndDate = useMemo(() => {
    if (endYear === null || endMonth === null) return false
    return isFuture(`${endYear}-${endMonth}-01`)
  }, [endYear, endMonth])

  const isEndYearInvalid = useMemo(() => {
    return validate.isEndYearValid({ endYear, startYear, isCurrent }) && !isFutureEndDate
  }, [endYear, startYear, isCurrent, isFutureEndDate])

  const isEndMonthInvalid = useMemo(() => {
    return validate.isEndMonthValid({ startMonth, endMonth, endYear, startYear, isCurrent }) || isFutureEndDate
  }, [startMonth, endMonth, endYear, startYear, isCurrent, isFutureEndDate])

  useEffect(() => {
    const result = fillEmploymentPayload({
      company,
      location,
      position,
      responsibilities,
      isCurrent,
    })

    result.startMonth = typeof startMonth === "number" ? startMonth : null
    result.startYear = typeof startYear === "number" ? startYear : null
    result.endMonth = !isCurrent && typeof endMonth === "number" ? endMonth : null
    result.endYear = !isCurrent && typeof endYear === "number" ? endYear : null

    onChange(result)
  }, [company, endMonth, endYear, isCurrent, location, position, responsibilities, startMonth, startYear])

  return (
    <Box mt={mt}>
      <StackItem>
        <FormField text="Job title" required tip="Your full job title" />
        <StyledInput
          name="LinkedIn"
          placeholder="Type here..."
          value={position}
          onChange={({ target }) => setPosition(target.value)}
        />
      </StackItem>

      <StackItem>
        <FormField text="Company name" required tip="Full company name" />
        <StyledInput
          name="LinkedIn"
          placeholder="Type here..."
          value={company}
          onChange={({ target }) => setCompany(target.value)}
        />
      </StackItem>

      <StackItem>
        <FormField text="Start date" required tip="Please, let us know the dates of your employment" />
        <Grid gridGap={20} gridTemplateColumns={"repeat(2, 1fr)"}>
          <Box>
            <Select
              placeholder="Month"
              options={monthOptions}
              value={monthOptions.find(({ value }) => value === startMonth)}
              onChange={item => {
                if (item) setStartMonth(item.value)
              }}
            />
            {!isValidStartDate && (
              <Text mt={4} color="delta.800">
                Can’t be future date
              </Text>
            )}
          </Box>

          <Box>
            <Select
              placeholder="Year"
              options={yearOptions}
              value={yearOptions.find(({ value }) => value === startYear)}
              onChange={item => {
                if (item) setStartYear(item.value)
              }}
            />
          </Box>
        </Grid>
      </StackItem>

      <StackItemCheckbox>
        <label style={{ paddingRight: "8px", paddingTop: "1.5px" }}>This is my</label>
        <Checkbox
          label="current employment"
          checked={isCurrent}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setIsCurrent(e.target.checked)}
        />
      </StackItemCheckbox>

      {!isCurrent && (
        <StackItem>
          <FormField text="End date" required tip="Please, let us know the dates of your employment" />
          <Grid gridGap={20} gridTemplateColumns={"repeat(2, 1fr)"}>
            <Box>
              <Select
                invalid={isEndMonthInvalid && !isEndYearInvalid}
                placeholder="Month"
                options={monthOptions}
                value={monthOptions.find(({ value }) => value === endMonth)}
                onChange={item => {
                  if (item) setEndMonth(item.value)
                }}
              />

              {isEndMonthInvalid && (
                <Text mt={4} color="delta.800">
                  {isFutureEndDate ? "Can’t be future date" : "Should be after start date"}
                </Text>
              )}
            </Box>

            <Box>
              <Select
                invalid={isEndYearInvalid}
                placeholder="Year"
                options={startYear === null ? yearOptions : yearOptions.filter(({ value }) => value >= startYear)}
                value={yearOptions.find(({ value }) => value === endYear)}
                onChange={item => {
                  if (item) setEndYear(item.value)
                }}
              />
              {isEndYearInvalid && (
                <Text mt={4} color="delta.800">
                  {isFutureEndDate ? "Can’t be future date" : "Should be after start date"}
                </Text>
              )}
            </Box>
          </Grid>
        </StackItem>
      )}

      <StackItem>
        <FormField text="Country" required tip="Geography" />
        <LocationsSelect
          value={{ value: location, label: location }}
          onChange={item => {
            setLocation(item?.value || "")
          }}
        />
      </StackItem>

      <StackItem>
        <FormField
          text="Responsibilities"
          required
          tip="Add a brief description of the work that you do at this position"
        />
        <Textarea
          placeholder="Type here..."
          rows={1}
          name="responsibilities"
          required
          maxLength={-1}
          style={{ flex: 1 }}
          value={responsibilities}
          maxRows={8}
          onChange={({ target }) => setResponsibilities(target.value)}
        />
      </StackItem>
    </Box>
  )
}
