import { Box, Flex } from "@prosapient/prosapient-styleguide"
import { TrainingProvider } from "./hooks/useTraining"
import { Progress } from "./components/Progress"
import { Steps } from "./components/Steps"
import { Footer } from "./components/Footer"
import styled from "styled-components"
import { HEADER_DESKTOP_HEIGHT, HEADER_MOBILE_HEIGHT } from "components/constants"

const PageWrapper = styled(Flex)`
  flex-direction: column;
  justify-content: flex-start;
  height: calc(var(--vh) - ${HEADER_DESKTOP_HEIGHT}px - 40px);
  min-height: calc(var(--vh) - ${HEADER_DESKTOP_HEIGHT}px - 40px);
  max-height: calc(var(--vh) - ${HEADER_DESKTOP_HEIGHT}px - 40px);
  background: ${props => props.theme.colors.white};
  box-shadow: 0 0 28px rgba(0, 0, 0, 0.06);
  border-radius: 2px;
  margin: 40px 30px 0;

  @media only screen and (max-width: 1024px) {
      // min-height: ${HEADER_MOBILE_HEIGHT}px;
    height: calc(var(--vh) - ${HEADER_MOBILE_HEIGHT}px - 40px);
    min-height: calc(var(--vh) - ${HEADER_MOBILE_HEIGHT}px - 40px);
    max-height: calc(var(--vh) - ${HEADER_MOBILE_HEIGHT}px - 40px);
  }

  @media only screen and (max-width: 640px) {
    position: fixed;
    top: 40px;
    bottom: 0;
    height: calc(var(--vh) - ${HEADER_MOBILE_HEIGHT}px);
    min-height: calc(var(--vh) - ${HEADER_MOBILE_HEIGHT}px);
    max-height: calc(var(--vh) - ${HEADER_MOBILE_HEIGHT}px);
    margin: 0;
  }
`

const StepsWrapper = styled(Flex)`
  padding: 1rem;
  margin: 54px auto 2rem;
  overflow-y: auto;
  width: 100%;

  @media only screen and (max-width: 640px) {
    padding-top: 0;
    margin-top: 0;
    margin-bottom: 1rem;
  }
`

const MAX_WIDTH = 850

export const ComplianceTraining = () => {
  return (
    <TrainingProvider>
      <PageWrapper>
        <Box px="1rem" width="100%" m="0 auto">
          <Progress maxWidth={MAX_WIDTH} />
        </Box>

        <StepsWrapper>
          <Steps maxWidth={MAX_WIDTH} />
        </StepsWrapper>

        <Flex mt="auto" backgroundColor="beta.100" alignItems="center" px="1rem">
          <Footer maxWidth={MAX_WIDTH} />
        </Flex>
      </PageWrapper>
    </TrainingProvider>
  )
}
