import { Text } from "@prosapient/prosapient-styleguide"

import SvgIcon from "@mui/material/SvgIcon"
import { SvgIconPropsSizeOverrides } from "@mui/material/SvgIcon/SvgIcon"
import { OverridableStringUnion } from "@mui/types"
import { MIconName } from "./types"
import { SVG_ICONS } from "./SvgIcons"
import { GOOGLE_ICONS } from "./GoogleIcons"

type MIconProps = {
  icon: MIconName
  v?: 1 | 2
  color?: string
  style?: any
  hoverColor?: string
  fontSize?: OverridableStringUnion<"inherit" | "large" | "medium" | "small" | string, SvgIconPropsSizeOverrides>
}

export const MIcon = ({ icon, v, color, hoverColor, style = {}, ...props }: MIconProps): JSX.Element => {
  // const hoverColorProps = hoverColor ? { hoverColor } : {}
  // return ICONS[icon]

  const svgIcon = SVG_ICONS[icon]
  if (svgIcon) {
    return svgIcon
  }

  // const googleIcon = googleIcons[icon]
  // if (googleIcon) {
  //   return <GIcon name="error" look="round" />
  // }

  // @ts-ignore
  return v === 2 ? (
    // @ts-ignore
    <Text color={color} hoverColor={hoverColor} style={style}>
      {/*@ts-ignore*/}
      <SvgIcon component={GOOGLE_ICONS[icon]} {...props} inheritViewBox />
    </Text>
  ) : (
    // @ts-ignore
    <SvgIcon component={GOOGLE_ICONS[icon]} {...props} style={style} inheritViewBox />
  )
}
