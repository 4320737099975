import { Box } from "@prosapient/prosapient-styleguide"
import styled from "styled-components"
import { IHeaderProps } from "./types"
import { MobileHeader } from "./MobileHeader"
import { DesktopHeader } from "./DesktopHeader"
import { HEADER_DESKTOP_HEIGHT, HEADER_MOBILE_HEIGHT } from "components/constants"

const HeaderContainer = styled(Box).attrs({ bg: "white", px: 12 })`
  // height: ${() => `${HEADER_DESKTOP_HEIGHT}px`};
  height: ${HEADER_DESKTOP_HEIGHT}px;
  position: sticky;
  top: 0;
  background-color: white;
  box-shadow: 0 0 28px rgba(0, 0, 0, 0.06);
  padding-right: 2rem;
  padding-left: 2rem;
  z-index: 9;

  @media only screen and (max-width: 1024px) {
    height: ${HEADER_MOBILE_HEIGHT}px;
  }

  @media only screen and (max-width: 640px) {
    padding-right: 1rem;
    padding-left: 1rem;
  }
`

export const Header = (props: IHeaderProps) => {
  return (
    <HeaderContainer>
      <DesktopHeader {...props} />

      <MobileHeader {...props} />
    </HeaderContainer>
  )
}
