import styled from "styled-components"
import { Box, Flex } from "@prosapient/prosapient-styleguide"
import { Paragraph } from "components/shared/Layout"
import { HEADER_DESKTOP_HEIGHT, HEADER_MOBILE_HEIGHT } from "components/constants"

export const Wrapper = styled(Flex).attrs({
  bg: "white",
  pb: 0,
})`
  position: ${props => props.position || "static"};
  min-height: calc(var(--vh) - ${HEADER_DESKTOP_HEIGHT}px);

  @media only screen and (max-width: 1024px) {
    padding-bottom: 0;
    min-height: calc(var(--vh) - ${HEADER_MOBILE_HEIGHT}px);
  }
`

export const FirstParagraph = styled(Paragraph)`
  @media only screen and (max-width: 640px) {
    order: -1;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    line-height: 1.5;
  }
`

export const ContentWithBackground = styled(Box).attrs({ shadow: "light", borderRadius: 2, bg: "white" })`
  padding: 2rem;
  max-height: calc(var(--vh) - 2rem - 56px - 50px);

  overflow-y: auto;

  @media only screen and (max-width: 640px) {
    min-height: calc(var(--vh) - ${HEADER_MOBILE_HEIGHT}px);
    max-height: calc(var(--vh) - ${HEADER_MOBILE_HEIGHT}px - 32px);
    overflow-y: auto;
    margin: 0 auto;
    max-width: 100%;
    box-shadow: none;
    padding: 0;
  }
`

export const StyledBox = styled(Box).attrs({ pt: 10, px: 10, width: "100vw" })`
  display: flex;
  flex-wrap: wrap;

  @media only screen and (max-width: 640px) {
    max-width: 100%;
    margin: 0 auto;
    align-self: flex-start;
    box-shadow: none;
    min-height: calc(var(--vh) - 56px);
    padding: 1rem;
  }
`

export const DesktopFooter = styled(Box).attrs({ m: "8px auto auto", color: "beta.600" })`
  max-width: 530px;
  line-height: 1.78;
  text-align: center;
  padding: 0 1rem 1.425rem;

  @media only screen and (max-width: 640px) {
    display: none;
  }
`

export const MobileFooter = styled(Box).attrs({ m: "1rem auto auto", color: "beta.600" })`
  line-height: 1.78;
  text-align: center;
  display: none;
  padding: 0 1rem 1.425rem;

  @media only screen and (max-width: 640px) {
    display: block;
  }
`
