import styled from "styled-components"
import { Box } from "@prosapient/prosapient-styleguide"
import { HEADER_DESKTOP_HEIGHT, HEADER_MOBILE_HEIGHT } from "../../constants"

export const PageMainBox = styled(Box).attrs({ width: "100vw", maxWidth: "100%" })`
  padding: 2rem;
  height: ${({ height }) => height || "100%"};
  min-height: ${({ minHeight }) => minHeight || "calc(var(--vh) - ${HEADER_DESKTOP_HEIGHT}px)"};
  //min-height: calc(var(--vh) - ${HEADER_DESKTOP_HEIGHT}px);
  display: ${({ display }) => display || "flex"}
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: ${({ display }) => (display === "grid" ? "initial" : "space-between")};

  @media only screen and (max-width: 1024px) {
    min-height: calc(var(--vh) - ${HEADER_MOBILE_HEIGHT}px);
  }

  @media only screen and (max-width: 640px) {
    max-width: 100%;
    padding: 10px;
    margin: 0 auto;
  }
`
