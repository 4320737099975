import { Text } from "@prosapient/prosapient-styleguide"

import { Container, StyledLink } from "./styles"

const DEFAULT_CONTACT_EMAIL = "experts@lek.com"

type FooterProps = {
  email?: string
}

export const Footer = ({ email = DEFAULT_CONTACT_EMAIL }: FooterProps) => {
  return (
    <Container>
      <Text color="beta.600" pr={2}>
        Questions? Contact us at
      </Text>
      <StyledLink target="_blank" to={`mailto:${email}`}>
        {email}
      </StyledLink>
    </Container>
  )
}
