import { forwardRef } from "react"
import {
  Box,
  Checkbox as ProsapientCheckbox,
  Checkbox as NewProsapientCheckbox,
  Datepicker as ProsapientDatepicker,
  Daterange as ProsapientDaterange,
  FIcon,
  Flex,
  Hint,
  Input as ProsapientInput,
  Label as ProsapientLabel,
  Popup,
  Radio as ProsapientRadio,
  Slider as ProsapientSlider,
  Text,
  Textarea as ProsapientTextarea,
  TextareaProps,
  Timepicker as ProsapientTimepicker,
  Toggle as ProsapientToggle,
  SelectOption,
} from "@prosapient/prosapient-styleguide"
import { InputFile as ProsapientInputFile } from "shared/form/controls"
import { Field } from "react-final-form"
import { AsYouType } from "libphonenumber-js"
// import { IFeatureStatus } from "graphql-tools/__generated__/schema"
import { useTranslation } from "react-i18next"

const isInvalid = (meta: { touched?: boolean; error?: any; submitError?: any }): boolean =>
  (meta.touched && meta.error) || meta.submitError

const Tooltip = ({ text }: { text: string }) => {
  return (
    <Box ml={2}>
      <Popup width="200px" placement="top" content={() => <Text fontSize={3}>{text}</Text>}>
        <FIcon icon="info" color="greens.1" cursor="pointer" />
      </Popup>
    </Box>
  )
}

type dataTestIdProps = {
  "data-test-id"?: string
}

type Props = {
  name: string
  displayError: boolean
} & dataTestIdProps &
  any

const Checkbox = (props: Props) => (
  <Field
    {...props}
    type="checkbox"
    render={({ input, meta, ...rest }) => (
      <>
        <ProsapientCheckbox {...input} {...rest} invalid={isInvalid(meta)} />
        {meta.error && <Hint invalid={isInvalid(meta)}>{meta.error}</Hint>}
      </>
    )}
  />
)

export const NewCheckbox = (props: Props) => (
  <Field
    {...props}
    type="checkbox"
    render={({ input, meta, ...rest }) => (
      <>
        <NewProsapientCheckbox {...input} {...rest} invalid={isInvalid(meta)} />
        {meta.error && <Hint invalid={isInvalid(meta)}>{meta.error}</Hint>}
      </>
    )}
  />
)

const Datepicker = (props: Props) => (
  <Field
    formatDate="do MMM y"
    {...props}
    render={({ input, meta, ...rest }) => {
      const fieldIsInvalid = isInvalid(meta)

      return (
        <>
          {props.label && (
            <ProsapientLabel required={props.required} invalid={fieldIsInvalid}>
              {props.label}
            </ProsapientLabel>
          )}
          <ProsapientDatepicker {...rest} onChange={input.onChange} value={input.value} invalid={fieldIsInvalid} />
          {fieldIsInvalid ? (
            <Hint invalid={fieldIsInvalid}>{meta.error || meta.submitError}</Hint>
          ) : (
            props.hint && <Hint>{props.hint}</Hint>
          )}
        </>
      )
    }}
  />
)

const Daterange = (props: Props) => (
  <Field
    {...props}
    render={({ input, meta, ...rest }) => {
      const fieldIsInvalid = isInvalid(meta)

      return (
        <>
          {props.label && (
            <ProsapientLabel required={props.required} invalid={fieldIsInvalid}>
              {props.label}
            </ProsapientLabel>
          )}
          <ProsapientDaterange {...rest} onChange={input.onChange} value={input.value} invalid={fieldIsInvalid} />
          {fieldIsInvalid ? (
            <Hint invalid={fieldIsInvalid}>{meta.error || meta.submitError}</Hint>
          ) : (
            props.hint && <Hint>{props.hint}</Hint>
          )}
        </>
      )
    }}
  />
)

const Timepicker = (props: Props) => {
  return (
    <Field
      {...props}
      render={({ input, meta, ...rest }) => {
        const fieldIsInvalid = isInvalid(meta)

        return (
          <>
            {props.label && (
              <ProsapientLabel required={props.required} invalid={fieldIsInvalid}>
                {props.label}
              </ProsapientLabel>
            )}
            <ProsapientTimepicker
              {...rest}
              invalid={fieldIsInvalid}
              onChange={input.onChange}
              value={input.value.stringValue}
            />
            {fieldIsInvalid ? (
              <Hint invalid={fieldIsInvalid}>{meta.error || meta.submitError}</Hint>
            ) : (
              props.hint && <Hint>{props.hint}</Hint>
            )}
          </>
        )
      }}
    />
  )
}

const Input = forwardRef((props: Props, ref) => {
  const { t } = useTranslation()

  return (
    <Field
      {...props}
      render={({ input, meta, ...rest }) => {
        const fieldIsInvalid = isInvalid(meta)
        return (
          <>
            {props.label && (
              <ProsapientLabel required={props.required} invalid={fieldIsInvalid}>
                {props.label}
              </ProsapientLabel>
            )}
            {props.labelHtml && (
              <ProsapientLabel
                required={props.required}
                invalid={fieldIsInvalid}
                dangerouslySetInnerHTML={{ __html: props.labelHtml }}
              />
            )}
            <ProsapientInput {...input} {...rest} {...props} invalid={fieldIsInvalid} required={false} ref={ref} />
            {fieldIsInvalid ? (
              <Hint invalid={fieldIsInvalid}>{t(meta.error || meta.submitError)}</Hint>
            ) : (
              props.hint && <Hint>{props.hint}</Hint>
            )}
          </>
        )
      }}
    />
  )
})

export const InputFile = (props: Props) => {
  return (
    <Field
      {...props}
      render={({ input: { value, onChange, ...input }, meta, ...rest }) => {
        const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
          const files = []

          if (target.files && target.files.length > 0) {
            for (let i = 0; i < target.files.length; i++) {
              files.push(target.files[i])
            }
          }

          onChange(files)
        }

        return (
          <ProsapientInputFile
            {...input}
            onChange={e => {
              handleChange(e)
            }}
            {...rest}
            // resetting target.value to allow to choose the same file after removing it
            onClick={(e: React.ChangeEvent<HTMLInputElement>) => (e.target.value = "")}
            {...props}
          />
        )
      }}
    />
  )
}

const Textarea = forwardRef<
  HTMLTextAreaElement,
  Omit<TextareaProps, "name"> & { label?: string; displayError?: boolean; name: string }
>((props, ref) => {
  const { t } = useTranslation()

  return (
    <Field
      {...props}
      render={({ input, meta, ...rest }) => {
        const fieldIsInvalid = props.displayError && isInvalid(meta)
        const style = (rest as any).style || {}
        const textareaProps = { invalid: fieldIsInvalid, required: false, ref, style }

        return (
          <>
            {props.label && (
              <ProsapientLabel required={props.required} invalid={fieldIsInvalid}>
                {props.label}
              </ProsapientLabel>
            )}
            <ProsapientTextarea {...input} {...rest} {...textareaProps} />
            {fieldIsInvalid && <Hint invalid={fieldIsInvalid}>{t(meta.error || meta.submitError)}</Hint>}
          </>
        )
      }}
    />
  )
})

Textarea.defaultProps = {
  displayError: true,
}

const Radio = (props: Props) => (
  <Field
    {...props}
    type="radio"
    render={({ input, ...rest }) => (
      <ProsapientRadio {...rest} {...input} onChange={() => input.onChange(input.value)} />
    )}
  />
)

const Toggle = (props: Props) => (
  <Field
    {...props}
    type="checkbox"
    render={({ input, ...rest }) => (
      <ProsapientToggle {...rest} {...input} onChange={() => input.onChange(!input.checked)} />
    )}
  />
)

const FieldWrapper = ({ SelectTag, ...props }: Props) => {
  const { t } = useTranslation()

  const { as, ...restProps } = props

  return (
    <Field
      {...restProps}
      render={({ input, meta, ...rest }) => {
        const fieldIsInvalid = isInvalid(meta)

        return (
          <>
            <Flex alignItems="baseline">
              {props.label && (
                <ProsapientLabel required={props.required} invalid={fieldIsInvalid}>
                  {props.label}
                </ProsapientLabel>
              )}
              {props.tooltip && <Tooltip text={props.tooltip} />}
            </Flex>
            <SelectTag
              {...input}
              {...rest}
              invalid={fieldIsInvalid}
              required={false}
              classNamePrefix="select"
              as={as}
            />
            {fieldIsInvalid ? (
              <Hint invalid={fieldIsInvalid}>{t(meta.error || meta.submitError)}</Hint>
            ) : (
              props.hint && <Hint style={{ whiteSpace: "pre" }}>{props.hint}</Hint>
            )}
          </>
        )
      }}
    />
  )
}

const PhoneInput = forwardRef((props: Props, ref) => {
  const formatPhone = (value: string) => new AsYouType().input(value)

  return (
    <Field
      {...props}
      format={formatPhone}
      render={({ input, meta, ...rest }) => {
        const fieldIsInvalid = isInvalid(meta)
        return (
          <>
            {props.label && (
              <ProsapientLabel required={props.required} invalid={fieldIsInvalid}>
                {props.label}
              </ProsapientLabel>
            )}
            {/*  @ts-ignore */}
            <ProsapientInput {...input} {...rest} invalid={fieldIsInvalid} required={false} ref={ref} />
            {fieldIsInvalid ? (
              <Hint invalid={fieldIsInvalid}>{meta.error || meta.submitError}</Hint>
            ) : (
              props.hint && <Hint>{props.hint}</Hint>
            )}
          </>
        )
      }}
    />
  )
})

const Slider = forwardRef((props: Props, ref) => {
  return (
    <Field
      {...props}
      render={({ input, meta, ...rest }) => {
        return <ProsapientSlider {...input} {...rest} {...props} ref={ref} />
      }}
    />
  )
})

// const commonSelectStyle = css`
//   pointer-events: all !important;
//
//   .select__control {
//     cursor: not-allowed;
//     border-bottom-color: ${props => props.theme.colors.grays[3]};
//   }
//
//   .select__placeholder {
//     color: ${props => props.theme.colors.grays[6]};
//   }
//
//   .select__value-container {
//     color: ${props => props.theme.colors.grays[6]};
//   }
// `

// enum SelectType {
//   Default = "Default",
//   // Async = "Async",
//   // AsyncCreatable = "AsyncCreatable",
//   Creatable = "Creatable",
// }
//
// const StyledSelect = styled(ProsapientSelect)<{ isDisabled: boolean }>`
//   ${props => props.isDisabled && commonSelectStyle}
// `
// const StyledCreatableSelect = styled(ProsapientCreatableSelect)<{ isDisabled: boolean }>`
//   ${props => props.isDisabled && commonSelectStyle}
// `
//
// const renderSelectTag = (typeOfSelect: SelectType, props: Props) => {
//   const tags: { [K in keyof typeof SelectType]: React.JSXElementConstructor<any> } = {
//     [SelectType.Default]: StyledSelect,
//     [SelectType.Creatable]: StyledCreatableSelect,
//   }
//   const SelectTag = tags[typeOfSelect]
//
//   if (props.oblique) {
//     // @ts-ignore
//     const rsComponents: { [K in keyof typeof SelectType]: React.JSXElementConstructor<any> } = {
//       // [SelectType.Default]: RSelect,
//       // [SelectType.Async]: RAsyncSelect,
//       // [SelectType.AsyncCreatable]: RAsyncCreatableSelect,
//       [SelectType.Creatable]: RCreatableSelect,
//     }
//
//     const ReactSelectComponent = rsComponents[typeOfSelect]
//
//     if (props.withoutForm) {
//       return <ProsapientSelect {...props} as={ReactSelectComponent} />
//     }
//     return <FieldWrapper as={ReactSelectComponent} {...props} />
//   }
//
//   if (props.withoutForm) {
//     return <SelectTag {...props} />
//   }
//   return <FieldWrapper SelectTag={SelectTag} {...props} />
// }

// const Select = (props: Props) => renderSelectTag(SelectType.Default, props)
// const AsyncCreatableSelect = (props: Props) => renderSelectTag(SelectType.AsyncCreatable, props)
// const AsyncSelect = (props: Props) => renderSelectTag(SelectType.Async, props)

// const CreatableSelect = (props: Props) => renderSelectTag(SelectType.Creatable, props)

export {
  Checkbox,
  Datepicker,
  FieldWrapper,
  Input,
  PhoneInput,
  Radio,
  Slider,
  Textarea,
  // CreatableSelect,
  Timepicker,
  Toggle,
  Daterange,
}
