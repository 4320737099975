// import { Select } from "@prosapient/prosapient-styleguide"
import { useMemo } from "react"
import { listTimeZones } from "timezone-support"
import { StyledSelect } from "../sections/BasicInformation/styles"

const excludedTimezones = [
  "CET",
  "EET",
  "EST",
  "HST",
  "MST",
  "MET",
  "WET",
  "PST",
  "CST6CDT",
  "EST5EDT",
  "MST7MDT",
  "PST8PDT",
  "America/Sao_Paulo",
]

type Props = {
  name: string
  label?: string
  isClearable?: boolean
  isMulti?: boolean
} & any

export interface SelectOption<T> {
  label: string
  value: T
  [key: string]: any
}

export const TimezoneSelect = (props: Props) => {
  const timezones = useMemo(
    () => listTimeZones().filter(timezone => !timezone.includes("Etc") && !excludedTimezones.includes(timezone)),
    []
  )

  const timezonesOptions: SelectOption<string>[] = timezones.map(timezone => ({
    label: timezone,
    value: timezone,
  }))

  return <StyledSelect {...props} options={timezonesOptions} />
}
