import { Flex } from "@prosapient/prosapient-styleguide"
import { MIcon } from "components/shared/MIcon/MIcon"

type Props = {
  children: any
  bg: string
  color: string
  iconColor: string
}

export const Blockquote = ({ children, bg = "alpha.600", color = "white", iconColor = "white" }: Props) => (
  <Flex bg={bg} color={color} flex="1 1 100%" style={{ lineHeight: "25px", padding: "0 20px" }}>
    <MIcon
      v={2}
      icon="error"
      color={iconColor}
      style={{ marginRight: "8px", lineHeight: "inherit", marginTop: "1.15em", minWidth: "21px" }}
    />{" "}
    {children}
  </Flex>
)
