import { Box, Button, Image } from "@prosapient/prosapient-styleguide"
import ImageBG from "accets/images/answers_submitted.svg"
import { SubTitle } from "components/shared/Layout"
import { PageContainer } from "components/shared/Layout/PageContainer"
import styled from "styled-components"
import { PageMain } from "../components/PageMain"
import { useMe } from "hooks"
import { useMemo } from "react"
import { ROUTES } from "../../constants"

const StyledButton = styled(Button)`
  @media only screen and (max-width: 640px) {
    width: 100%;
    margin-top: auto;
    padding-top: 1px;
    padding-bottom: 1px;
    min-height: 40px;
  }
`

export const AnswersSubmitted = () => {
  const {
    me: {
      state: { isSignedUp },
    },
    loading,
  } = useMe()

  const button = useMemo(() => {
    if (loading) return ""

    return isSignedUp ? "Back to Expert Portal" : "Join Expert Portal"
  }, [isSignedUp, loading])

  const clickHandler = () => {
    window.location.href = isSignedUp ? ROUTES.authLogin : ROUTES.authSignUp
  }

  return (
    <PageContainer>
      <PageMain width={540} style={{ textAlign: "center", justifyContent: "center" }}>
        <Box>
          <Image src={ImageBG} alt="answers submitted image" style={{ width: "100%", height: 256 }} />
          <Box mb={10} />

          {isSignedUp ? (
            <SubTitle>Your answers were submitted successfully.</SubTitle>
          ) : (
            <>
              <SubTitle>Your answers were submitted successfully.</SubTitle>
              <SubTitle>You can now join LEK Expert Portal</SubTitle>
            </>
          )}

          <Box mt={6} />
        </Box>

        <StyledButton onClick={clickHandler}>{button}</StyledButton>
      </PageMain>
    </PageContainer>
  )
}
