import {
  CreatableSelectProps as UIKitCreatableSelectProps,
  CreatableSelect,
  SelectOption,
} from "@prosapient/prosapient-styleguide"
import { useState } from "react"
import { useExpertisesQuery } from "api/__generated__/schema"
import { MultiSelectInput } from "components/@profile/@my-profile/sections/BasicInformation/UpdateInfo/MultiSelectInput"
import { ReactSelectOption } from "shared/controls"
import { FormBindings } from "shared/form-bindings-new"
import { splitBySeparator } from "shared/selects/utils"
import { MultiSelectValueContainer } from "components/@profile/@my-profile/sections/BasicInformation/UpdateInfo/MultiSelectValueContainer"

type SelectProps = UIKitCreatableSelectProps<SelectOption<string>, true>

export const ExpertisesSelect = (props: SelectProps) => {
  const { data } = useExpertisesQuery({ fetchPolicy: "cache-and-network" })
  const [value, setValue] = useState<typeof props.value>(props.value)

  const options = data?.expertises.map(value => ({ value, label: value }))

  return (
    <CreatableSelect<SelectOption<string>, true>
      isMulti
      menuPlacement="bottom"
      formatCreateLabel={(value: string) => `Add "${value}"`}
      components={{
        Option: ReactSelectOption,
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        Input: MultiSelectInput,
        MultiValueContainer: MultiSelectValueContainer,
      }}
      {...props}
      value={value}
      options={options}
      onChange={(value, meta) => {
        const values = splitBySeparator(value)
        setValue(values)

        props.onChange && props.onChange(values, meta)
      }}
    />
  )
}

export const FormExpertisesSelect = (props: FormBindings.Props<HTMLSelectElement, SelectProps>) => {
  return <FormBindings.SelectWrapper {...props} SelectComponent={ExpertisesSelect} />
}
