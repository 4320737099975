import { ReactNode, useEffect, useMemo, useState } from "react"
import { useHistory } from "react-router"
import { useLocation } from "react-use"
import { IAuthTokenType } from "api/__generated__/schema"

import { ROUTES } from "components/constants"
import { useMe } from "hooks"
import { useGlobalSpinner } from "hooks/useGlobalSpinner"

type IProps = {
  renderAfterValidation?: boolean
  tokenTypes?: IAuthTokenType[]
  children?: ReactNode | undefined
}

export const LimitedAuthGuard = ({
  tokenTypes = [
    IAuthTokenType.Auth,
    IAuthTokenType.Compliance,
    IAuthTokenType.ProjectInvitation,
    IAuthTokenType.Signup,
  ],
  renderAfterValidation = false,
  children,
}: IProps) => {
  const {
    me: { state },
    loggedIn,
  } = useMe()
  const { showSpinner, hideSpinner } = useGlobalSpinner()
  const { pathname } = useLocation()
  const { error } = useMe()
  const { push } = useHistory()
  const [ready, setReady] = useState(!renderAfterValidation)

  const isLocal = useMemo(() => window.location.hostname === "localhost", [])

  const goToIAM = (isSignedUp?: boolean) => {
    if (isLocal && pathname !== ROUTES.devLogin) {
      push(ROUTES.devLogin)
      return
    }

    window.location.href = isSignedUp === false ? ROUTES.authSignUp : ROUTES.authLogin
  }

  useEffect(() => {
    showSpinner()

    if (tokenTypes?.length > 0) {
      if (state?.authTokenType) {
        if (tokenTypes.includes(state?.authTokenType)) {
          hideSpinner()
        } else {
          showSpinner()
          goToIAM(state?.isSignedUp)
          return
        }
      } else {
        showSpinner()
        // FIX https://prosapient-prod.atlassian.net/browse/EP-513
        // hide spinner on error page when user is not found
        if (!error) return
      }
    }

    hideSpinner()
    if (!ready) setReady(true)
  }, [tokenTypes, loggedIn, state])

  return <>{ready ? children : null}</>
}
