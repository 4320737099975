import { Link, Text, VStack } from "@prosapient/prosapient-styleguide"
import { ContactItem } from "../styles"
import { MIcon } from "components/shared/MIcon/MIcon"
import countries from "shared/countryCodes"
import { useMe } from "hooks"
import styled from "styled-components"

const StyledLink = styled(Link).attrs({ color: "alpha.600", hoverColor: "alpha.800", fontWeight: 2 })`
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`

export const Contacts = () => {
  const { me } = useMe()

  return (
    <VStack>
      <ContactItem>
        <MIcon v={2} icon="place" />
        {me?.countryAlpha3 ? <Text>{countries.getLabelByCode(me!.countryAlpha3)}</Text> : <span>Country</span>}
      </ContactItem>

      <ContactItem>
        <MIcon v={2} icon="schedule" />
        <Text>{me?.timezone || ""}</Text>
      </ContactItem>

      <ContactItem>
        <MIcon v={2} icon="phoneSharp" />
        <Text>{me?.phone}</Text>
      </ContactItem>

      {me?.altPhone && (
        <ContactItem>
          <MIcon v={2} icon="phoneSharp" />
          <Text>{me?.altPhone}</Text>
        </ContactItem>
      )}

      <ContactItem>
        <MIcon v={2} icon="email" />
        <Text title={me?.email}>{me?.email}</Text>
      </ContactItem>

      {me?.altEmail && (
        <ContactItem>
          <MIcon v={2} icon="email" />
          <Text title={me?.altEmail}>{me?.altEmail}</Text>
        </ContactItem>
      )}

      <ContactItem>
        <MIcon icon="linkedIn" />
        {me?.linkedinUrl ? (
          <StyledLink target="_blank" to={me?.linkedinUrl} title={me?.linkedinUrl}>
            LinkedIn profile
          </StyledLink>
        ) : (
          <span>LinkedIn profile</span>
        )}
      </ContactItem>

      <ContactItem>
        <MIcon v={2} icon="link" style={{ transform: "rotate(-45deg)", height: "21px" }} />
        {me?.externalUrl ? (
          <StyledLink target="_blank" to={me?.externalUrl} title={me?.externalUrl}>
            {me?.externalUrl}
          </StyledLink>
        ) : (
          <span>Web-site URL</span>
        )}
      </ContactItem>
    </VStack>
  )
}
