import styled from "styled-components"
import { Flex } from "@prosapient/prosapient-styleguide"
import { IHeaderProps } from "../types"
import { Navbar } from "./Navbar"
import { UserDropdown } from "./UserDropdown"
import { Logo } from "../Logo"

const DesktopWrapper = styled(Flex)`
  height: 100%;
  align-items: center;
  max-width: 100vw;

  @media only screen and (max-width: 1024px) {
    display: none;
  }
`

export const DesktopHeader = ({ navbar, isPublic }: IHeaderProps) => {
  return (
    <DesktopWrapper>
      <Logo withLink={!isPublic} style={{ cursor: isPublic ? "default" : "pointer", width: "92px" }} />

      {navbar && <Navbar />}

      {!isPublic && <UserDropdown />}
    </DesktopWrapper>
  )
}
