import { useEffect, useMemo } from "react"
import { useLocation } from "react-use"
import { Helmet } from "react-helmet"
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom"

// import { breakpointsPx } from "hooks/useScreenSize"
import { IAuthTokenType, useUpdateProfileTimezoneMutation } from "api/__generated__/schema"
import { AppUpgradeStrategy, useAppStaleCheck } from "shared/AppVersionCheck"
// import { sessionStorage } from "shared/storage"
import { useMe } from "hooks"
import { useGlobalSpinner } from "hooks/useGlobalSpinner"
import { ROUTES } from "components/constants"
import { GlobalSpinner } from "components/shared/GlobalSpinner"

import { Auth } from "./@auth"
import { DevLogin } from "./@auth/@dev-login"
import { Screening } from "./@screening"
import { Payments } from "./@payments"
import { Documents } from "./@documents"
import { ProfilePages } from "./@profile"
import { Compliance } from "./@compliance"
import { Projects } from "./@projects"

export const Router = () => {
  const { me, loggedIn, refetch, error } = useMe()

  const { hostname, pathname } = useLocation()
  const { displaySpinner, showSpinner } = useGlobalSpinner()

  const [updateProfileTimezone] = useUpdateProfileTimezoneMutation()

  // @ts-ignore
  const isLocal = useMemo(() => hostname === "localhost", [hostname])

  useEffect(() => {
    if (!loggedIn && !error) showSpinner()
  }, [loggedIn, error])

  const silentUpgradeApp = useAppStaleCheck(AppUpgradeStrategy.SilentUpgrade)
  const softUpgradeApp = useAppStaleCheck(AppUpgradeStrategy.SoftUpgrade)
  const forceRefresh = silentUpgradeApp || softUpgradeApp || false

  useEffect(() => {
    if (!me?.timezone && me?.state?.authTokenType === IAuthTokenType.Auth) {
      let detectedTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone || ""

        if (detectedTimezone === "Europe/Kyiv") detectedTimezone = "Europe/Kiev"

        const variables = { timezone: detectedTimezone }

      updateProfileTimezone({ variables }).then()
    }
  }, [me?.timezone, me?.state?.authTokenType])

  useEffect(() => {
    if (!isLocal && typeof me?.state?.isIamSessionValid === "boolean" && !me?.state.isIamSessionValid) {
      if (me?.state?.authTokenType === IAuthTokenType.Auth) {
        window.location.href = me?.state?.isSignedUp === false ? ROUTES.authSignUp : ROUTES.authLogin
      }
    }
  }, [me?.state])

  useEffect(() => {
    window.scrollTo(0, 0)
    refetch().then(() => {})
  }, [pathname])

  if (!loggedIn && !error) return <GlobalSpinner />

  return (
    <GlobalSpinner show={displaySpinner}>
      <BrowserRouter key={forceRefresh ? 1 : 2} forceRefresh={forceRefresh}>
        {isLocal && (
          <Helmet>
            <title>Local | Expert Portal</title>
          </Helmet>
        )}

        <Switch>
          <Route
            path={ROUTES.workspace}
            render={({ location }) => (
              <Redirect
                to={{
                  pathname: ROUTES.projects,
                  state: { from: location },
                }}
              />
            )}
          />

          <Route path={ROUTES.screening} component={Screening} />
          <Route path={ROUTES.payments} component={Payments} />
          <Route path={ROUTES.documents} component={Documents} />
          <Route path={ROUTES.profile} component={ProfilePages} />
          <Route path={ROUTES.compliance} component={Compliance} />
          <Route path={ROUTES.projects} component={Projects} />

          <Route path={ROUTES.auth} component={Auth} />
          <Route path={ROUTES.welcome} component={Auth} />

          <Route path={ROUTES.devLogin} component={DevLogin} />

          <Route
            path="*"
            render={({ location }) => (
              <Redirect
                to={{
                  pathname: ROUTES.auth,
                  state: { from: location },
                }}
              />
            )}
          />
        </Switch>
      </BrowserRouter>
    </GlobalSpinner>
  )
}
