import { Alert, Box, Button, Flex, Grid, Image, useAlert } from "@prosapient/prosapient-styleguide"
import { StackItem } from "../BasicInformation/styles"
import { useMemo, useState } from "react"
import { EmploymentForm } from "./components/EmploymentForm"
import { IEmploymentPayload, useCreateEmploymentMutation } from "api/__generated__/schema"
import { ActionBox, BodyWrapper, GridContainer, RemoveButton, StyledButton } from "./styles"
import { isEmploymentValid } from "./utils/validate"
import LogoIcon from "accets/images/logo-small.svg"
import { EditEmployment } from "./types"

type IProps = {
  discard: boolean
  onDone: () => void
  onDiscard: () => void
}

const initEmployment: EditEmployment =
  window?.location?.hostname !== "localhost"
    ? { company: "", isCurrent: false }
    : {
        isCurrent: true,
        company: "localhost",
        location: "Ukraine",
        position: "localhost",
        responsibilities: "localhost",
        startMonth: 5,
        startYear: 2021,
      }

export const CreateEmployment = ({ discard, onDone, onDiscard }: IProps) => {
  const alert = useAlert(Alert.Context)

  const [employments, setEmployments] = useState<EditEmployment[]>([initEmployment])
  // const [employments, setEmployments] = useState<EditEmployment[]>([{ company: "", isCurrent: false }])

  const [createEmployment, { loading }] = useCreateEmploymentMutation()

  const addFormHandler = () => {
    const emptyEmployment: EditEmployment = { company: "", isCurrent: false }
    setEmployments([...employments, emptyEmployment])
  }

  const removeFormHandler = (index: number) => {
    const tmpEmployments = JSON.parse(JSON.stringify(employments))
    setEmployments([])
    tmpEmployments.splice(index, 1)

    setTimeout(() => {
      setEmployments([...tmpEmployments])
    }, 0)
  }

  const onSave = async () => {
    try {
      const promises = employments.filter(Boolean).map(employment => {
        const payload: IEmploymentPayload = {
          company: employment.company.trim(),
          location: (employment?.location || "").trim(),
          position: (employment?.position || "").trim(),
          responsibilities: (employment?.responsibilities || "").trim(),
          startMonth: employment?.startMonth || null,
          startYear: employment?.startYear || null,
          endMonth: employment?.endMonth || null,
          endYear: employment?.endYear || null,
        }

        return createEmployment({ variables: { payload } })
      })

      await Promise.all(promises)

      onDone()
    } catch (e) {
      /*eslint-disable */
      console.error(e)
      /*eslint-enable */

      // @ts-ignore
      alert.error(e?.message || e)
    }
  }

  const isFormValid = useMemo(() => {
    return employments.every(employment => isEmploymentValid(employment))
  }, [employments])

  return (
    <BodyWrapper>
      <GridContainer>
        <Box>
          {employments.map((item, index) => (
            <Box key={index}>
              <EmploymentForm
                isExists={item?.isCurrent ?? false}
                key={index}
                data={item}
                mt={index > 0 ? "3rem" : "0"}
                onChange={(data: EditEmployment) => {
                  employments[index] = JSON.parse(JSON.stringify(data))
                  setEmployments([...employments])
                }}
              />
              {employments?.length > 1 && index + 1 < employments?.length && (
                <StackItem>
                  <RemoveButton ghost onClick={() => removeFormHandler(index)}>
                    - Remove employment
                  </RemoveButton>
                </StackItem>
              )}
            </Box>
          ))}

          <StackItem>
            <Button ghost disabled={!isFormValid} style={{ paddingLeft: 0 }} onClick={addFormHandler}>
              + Add another employment
            </Button>
            {employments?.length > 1 && (
              <RemoveButton ghost onClick={() => removeFormHandler(employments.length - 1)}>
                - Remove employment
              </RemoveButton>
            )}
          </StackItem>

          <StackItem style={{ marginBottom: 0 }}>
            <ActionBox>
              <StyledButton variant="primary" type="button" disabled={!isFormValid} loading={loading} onClick={onSave}>
                Save changes
              </StyledButton>
              {discard && (
                <>
                  <Box m={2} />
                  <StyledButton type="button" variant="secondary" onClick={onDiscard}>
                    Discard
                  </StyledButton>
                </>
              )}
            </ActionBox>
          </StackItem>
        </Box>

        <Flex>
          <Box>
            <Grid gridGap="8px" gridTemplateColumns="auto 40px">
              <Box
                bg="alpha.600"
                color="white"
                borderRadius="20px 20px 0px 20px"
                style={{
                  padding: "20px",
                  fontSize: ".857rem",
                  lineHeight: "1.57rem",
                  alignItems: "center",
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.08)",
                }}
              >
                It’s essential that your employment details are up to date. This allows us to match you with the most
                relevant projects.
              </Box>

              <Flex
                bg="alpha.600"
                borderRadius="50px"
                width={40}
                height={40}
                alignSelf="end"
                alignItems="center"
                justifyContent="center"
              >
                <Image src={LogoIcon} alt="logo" width="32px" />
              </Flex>
            </Grid>
          </Box>
        </Flex>
      </GridContainer>
    </BodyWrapper>
  )
}
