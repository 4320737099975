import { CardInfo, CardWrapper } from "../../styles"
import React from "react"
import { Skeleton } from "shared/Skeleton"

export const CardSkeleton = () => {
  return (
    <>
      <CardWrapper pb={0}>
        <CardInfo>
          <Skeleton mb={6} maxWidth="20%" />

          <Skeleton maxWidth="60%" />
          <Skeleton maxWidth="40%" />
        </CardInfo>
      </CardWrapper>
    </>
  )
}
