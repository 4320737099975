import { createContext, ReactNode, useEffect } from "react"
import { useLocation } from "react-use"
import { IMeQuery, useMeQuery } from "api/__generated__/schema"
import { ROUTES } from "components/constants"
import { sessionStorage } from "shared/storage"

export type ContextType = any

export const UserContext = createContext<ContextType>(undefined)

const getExpert = (data: IMeQuery): IMeQuery["expert"] => {
  if (data?.expert) {
    return Object.assign({}, data!.expert)
  }
  return Object.assign({})
}

const Me = ({ children }: { children: ReactNode }) => {
  const { pathname = "" } = useLocation()
  const { data, loading, error, refetch } = useMeQuery({ fetchPolicy: "network-only", errorPolicy: "ignore" })

  if (error) {
    if (error.message.includes("Expert is not authenticated")) {
      if (
        !pathname.startsWith(ROUTES.auth) &&
        // !pathname.startsWith(ROUTES.accountDeactivated) &&
        !pathname.startsWith(ROUTES.linkExpired)
      ) {
        if (pathname === ROUTES.documents) sessionStorage.set("goTo", pathname)

        window.location.href =
          process.env.REACT_APP_ENV === "local" && pathname !== ROUTES.auth
            ? `${ROUTES.auth}?action=logout`
            : ROUTES.authLogin
      }
      // eslint-disable-next-line
      console.info("User is not authenticated")
    } else {
      // eslint-disable-next-line
      console.error(new Error(`Can't get user: ${error}`))
    }
  }

  const browserTabChangeHandler = () => {
    if (document.visibilityState == "visible") {
      refetch()
    }
  }

  useEffect(() => {
    // window.addEventListener("keydown", handleUserKeyPress);
    document?.addEventListener("visibilitychange", browserTabChangeHandler)

    return () => {
      document?.removeEventListener("visibilitychange", browserTabChangeHandler)
    }
  }, [])

  const me = (!loading && data && getExpert(data)) || ({} as IMeQuery["expert"])
  const loggedIn = Boolean(data?.expert?.email)

  return <UserContext.Provider value={{ me, loggedIn, loading, error, refetch }}>{children}</UserContext.Provider>
}

export default Me
