import { ReactNode, useEffect, useMemo, useState } from "react"
import { useHistory } from "react-router"
import { useLocation } from "react-use"
import { IAuthTokenType } from "api/__generated__/schema"

import { ROUTES } from "components/constants"
import { useMe } from "hooks"
import { useGlobalSpinner } from "hooks/useGlobalSpinner"
import { sessionStorage } from "shared/storage"

type IProps = {
  renderAfterValidation?: boolean
  sessionRequired?: boolean
  phoneRequired?: boolean
  tokenTypes?: IAuthTokenType[]
  children?: ReactNode | undefined
}

export const AuthGuard = ({
  tokenTypes = [],
  sessionRequired = true,
  phoneRequired = true,
  renderAfterValidation = false,
  children,
}: IProps) => {
  const {
    me: { state },
    loggedIn,
  } = useMe()
  const { showSpinner, hideSpinner } = useGlobalSpinner()
  const { pathname } = useLocation()
  const { push } = useHistory()
  const [ready, setReady] = useState(!renderAfterValidation)

  const isLocal = useMemo(() => window.location.hostname === "localhost", [])

  const goToIAM = (isSignedUp?: boolean) => {
    if (isLocal && pathname !== ROUTES.devLogin) {
      push(ROUTES.devLogin)
      return
    }

    window.location.href = isSignedUp === false ? ROUTES.authSignUp : ROUTES.authLogin
  }

  useEffect(() => {
    showSpinner()
    if (sessionRequired) {
      if (state?.isIamSessionValid === false && !isLocal) {
        goToIAM(state?.isSignedUp)
        return
      }
    }

    if (tokenTypes?.length > 0) {
      if (state?.authTokenType) {
        if (tokenTypes.includes(state?.authTokenType)) {
          hideSpinner()
        } else {
          showSpinner()
          goToIAM(state?.isSignedUp)
          return
        }
      } else {
        showSpinner()
      }
    }

    if (loggedIn && phoneRequired) {
      if (state?.isPhoneConfirmed === false && pathname !== ROUTES.askPhone) {
        push(ROUTES.askPhone)
        return
      }
    }

    const goTo = sessionStorage.get("goTo")
    if (goTo) {
      if (pathname?.startsWith(ROUTES.askPhone)) return

      if (pathname !== goTo) {
        showSpinner()
        push(goTo)
      } else {
        setTimeout(() => {
          if (window.location.pathname === goTo) sessionStorage.remove("goTo")
        }, 1000)
      }
    }

    hideSpinner()
    if (!ready) setReady(true)
  }, [sessionRequired, tokenTypes, loggedIn, state])

  return <>{loggedIn && ready ? children : null}</>
}
