import { IEmployment, IEmploymentPayload } from "api/__generated__/schema"
import { EditEmployment } from "../types"

export const fillEmploymentPayload = ({
  company,
  location,
  position,
  responsibilities,
  startMonth,
  startYear,
  endMonth,
  endYear,
  isCurrent,
}: EditEmployment): EditEmployment => {
  const result: EditEmployment = {
    company,
    location,
    position,
    responsibilities,
    isCurrent,
  }

  result.startMonth = typeof startMonth === "number" ? startMonth : null
  result.startYear = typeof startYear === "number" ? startYear : null
  result.endMonth = typeof endMonth === "number" ? endMonth : null
  result.endYear = typeof endYear === "number" ? endYear : null

  return result
}

export const fillEmploymentPayloadToSave = (employment: EditEmployment): IEmploymentPayload => {
  const { isCurrent, ...payload } = employment

  return payload
}

type EmploymentKeys = keyof IEmployment & keyof IEmploymentPayload
export const isEmploymentMatchPayload = (employment: IEmployment, payload: IEmploymentPayload | null): boolean => {
  if (payload === null) return false

  const keys: EmploymentKeys[] = [
    "company",
    "location",
    "position",
    "responsibilities",
    "startMonth",
    "startYear",
    "endMonth",
    "endYear",
  ]

  return keys.every(key => employment[key] === payload[key])
}
