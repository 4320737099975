import { useMemo, useState } from "react"
import { Alert, Box, Spinner, TableRow, Text, useAlert } from "@prosapient/prosapient-styleguide"
import { Title } from "components/shared/Layout"
import { PageContainer } from "components/shared/Layout/PageContainer"
import {
  GridContainer,
  StyledDataCell,
  StyledHeaderCell,
  StyledTable,
  StyledTableBody,
  StyledTableHeader,
  StyledTableRow,
} from "./styles"
import { useHistory } from "react-router"
import { ROUTES } from "components/constants"
import { Sidebar } from "components/shared/Sidebar"
import { useDocumentsQuery, useSignDocumentMutation } from "api/__generated__/schema"
import { useMe } from "hooks"
import { Actions } from "./components/Actions"
import { IconStatus } from "./components/IconStatus"
import { TextStatus } from "./components/TextStatus"
import { Footer } from "components/shared/Footer"
import styled from "styled-components"
import { PageMainBox } from "components/shared/Layout/PageMainBox"

export const DocumentsOverview = () => {
  const { push } = useHistory()
  const alert = useAlert(Alert.Context)
  const {
    me: {
      state: { isCompliancePassed },
    },
    refetch: refetchMe,
  } = useMe()
  const { data, refetch } = useDocumentsQuery({ fetchPolicy: "cache-and-network" })
  const [signDocument, { loading: signLoading }] = useSignDocumentMutation()

  const [selectedDocument, setSelectedDocument] = useState("")

  const signDocumentHandler = async (alias: string, name: string) => {
    try {
      setSelectedDocument(name)
      const { data } = await signDocument({ variables: { alias } })

      if (data?.signDocument.success) {
        alert.success(`${name} accepted`)
        await Promise.all([refetch(), refetchMe()]).catch(e => {
          /*eslint-disable */
          console.error(e)
          /*eslint-enable */
        })
      } else {
        alert.error(data?.signDocument.description)
      }
    } catch (e) {
      /*eslint-disable */
      console.error(e)
      /*eslint-enable */

      // @ts-ignore
      alert.error(e?.message || e)
    } finally {
      setSelectedDocument("")
    }
  }

  const displayData = useMemo(() => {
    const docs = []

    docs.push({
      alias: "compliance_training",
      name: "Compliance training",
      actionLabel: "Complete Compliance Training",
      completeLabel: "Review Compliance training",
      complete: isCompliancePassed || false,
      warning:
        "You won’t be able to join the call if you don’t pass the Compliance training. Please, make sure to complete it before the call.",
      link: null,
      onAction: () => push(ROUTES.compliance),
    })

    if (data?.documents?.length) {
      const termsAndConditions = data?.documents.find(({ alias }) => alias === "terms_and_conditions")
      if (termsAndConditions) {
        docs.push({
          alias: termsAndConditions?.alias || "",
          name: termsAndConditions?.name || "",
          actionLabel: "Accept T&Cs",
          completeLabel: "Review document",
          complete: termsAndConditions?.isApproved || false,
          warning:
            "You won’t be able to join the call if you don’t accept the T&Cs. Please, make sure to complete it before the call.",
          link: termsAndConditions?.link || null,
          onAction: () => signDocumentHandler(termsAndConditions?.alias, termsAndConditions?.name),
        })
      }

      const privacyPolicy = data?.documents.find(({ alias }) => alias === "privacy_policy")
      if (privacyPolicy) {
        docs.push({
          alias: privacyPolicy?.alias || "",
          name: privacyPolicy?.name || "",
          actionLabel: "Accept Privacy Policy",
          completeLabel: "Review document",
          complete: privacyPolicy?.isApproved || false,
          warning:
            "You won’t be able to join the call if you don’t accept the Privacy Policy. Please, make sure to complete it before the call.",
          link: privacyPolicy?.link,
          onAction: () => signDocumentHandler(privacyPolicy?.alias, privacyPolicy?.name),
        })
      }

      const termsOfUse = data?.documents.find(({ alias }) => alias === "terms_of_use")
      if (termsOfUse) {
        docs.push({
          alias: termsOfUse?.alias || "",
          name: termsOfUse?.name || "",
          actionLabel: "Accept Terms of Use",
          completeLabel: "Review document",
          complete: termsOfUse?.isApproved || false,
          warning:
            "You won’t be able to join the call if you don’t accept the Terms of Use. Please, make sure to complete it before the call.",
          link: termsOfUse?.link,
          onAction: () => signDocumentHandler(termsOfUse?.alias, termsOfUse?.name),
        })
      }
    }

    return docs
  }, [isCompliancePassed, data])

  const rows = useMemo(
    () =>
      displayData.map(
        ({ name, alias, link, warning = "", actionLabel, complete, completeLabel, onAction, ...rest }) => ({
          document: <Text>{name}</Text>,

          icon: <IconStatus complete={complete} />,
          action: (
            <Actions
              complete={complete}
              link={link}
              completeLabel={completeLabel}
              loading={signLoading && selectedDocument === name}
              actionLabel={actionLabel}
              onAction={onAction}
            />
          ),
          status: <TextStatus complete={complete} warning={warning} />,
          ...rest,
        })
      ),
    [displayData, signLoading, selectedDocument]
  )

  return (
    <Body hasBackground={false} centered={false} pb={0}>
      <PageMainBox minHeight="100%">
        <GridContainer>
          <Box mt="-12px">
            <Title mb={8}>Documents</Title>

            <StyledTable>
              <StyledTableHeader>
                <TableRow>
                  <StyledHeaderCell>Document</StyledHeaderCell>
                  <StyledHeaderCell style={{ padding: 0 }} />
                  <StyledHeaderCell style={{ width: "100%" }}>Status</StyledHeaderCell>
                  <StyledHeaderCell style={{ width: "250px" }} />
                </TableRow>
              </StyledTableHeader>
              <StyledTableBody>
                {rows?.map(({ document, icon, status, action }, idx) => (
                  <StyledTableRow key={idx}>
                    <StyledDataCell width="auto" style={{ whiteSpace: "nowrap" }}>
                      {document}
                    </StyledDataCell>
                    <StyledDataCell style={{ padding: 0 }}>{icon}</StyledDataCell>
                    <StyledDataCell>{status}</StyledDataCell>
                    <StyledDataCell>{action}</StyledDataCell>
                  </StyledTableRow>
                ))}

                {rows?.length < 2 &&
                  [1, 2].map(key => (
                    <StyledTableRow key={key}>
                      <StyledDataCell>
                        <Box height={38} width={38}>
                          <Spinner />
                        </Box>
                      </StyledDataCell>
                      <StyledDataCell />
                      <StyledDataCell />
                      <StyledDataCell />
                    </StyledTableRow>
                  ))}
              </StyledTableBody>
            </StyledTable>
          </Box>

          <Sidebar pb={5} mb={0} />
        </GridContainer>
      </PageMainBox>

      <Footer />
    </Body>
  )
}

const Body = styled(PageContainer)`
  @media only screen and (max-width: 1024px) {
    & > div {
      align-self: flex-start;
    }
  }
`
