// import App, { PublicApp } from "./App"
import { Alert } from "@prosapient/prosapient-styleguide"
// import { NotFound } from "shared/NotFound"
// import { breakpointsPx } from "hooks/useScreenSize"
import * as Sentry from "@sentry/react"
import { Normalize } from "styled-normalize"

// import { ApolloProvider } from "@apollo/react-hooks"
// import { client } from "./api/apollo"
import GlobalStyles from "GlobalStyles"
import { AppThemeProvider } from "styles/AppThemeProvider"

import { Router } from "components/Router"
import { ApolloProvider } from "api/ApolloProvider"
import Me from "./hooks/useMe/Me"
import { GlobalSpinnerProvider } from "hooks/useGlobalSpinner/GlobalSpinnerProvider"
import { useWindowSize } from "./hooks"

if (process.env.REACT_APP_ENV !== "local") {
  Sentry.init({
    dsn: "https://92c09245792045d09f5b5c1f422ae456@o419699.ingest.sentry.io/6517454",

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
}

//
// const clientTheme = {
//   // fontSizes: [10, 12, 13, 14, 16, 18, 20, 22, 24, 28, 32, 36, 40, 44, 48],
// }
// const mobile = 480
// const type = "string"
// const env = "string"

// if (window.location.host === "localhost:3000") {
// }

export const App = () => {
  useWindowSize()
  // const silentUpgradeApp = useAppStaleCheck(AppUpgradeStrategy.SilentUpgrade)
  // const softUpgradeApp = useAppStaleCheck(AppUpgradeStrategy.SoftUpgrade)
  // const forceRefresh = silentUpgradeApp || softUpgradeApp || false

  return (
    <ApolloProvider>
      <AppThemeProvider>
        {/*<NonceProvider nonce="custom-select-styles" cacheKey="cachekey">*/}
        <Alert.Provider containerStyle={{ top: "60px", zIndex: 10001 }} offset="5px">
          <Normalize />
          <GlobalStyles />

          {/*<ErrorsFeedback>*/}
          <GlobalSpinnerProvider>
            <Me>
              <Router />
            </Me>
          </GlobalSpinnerProvider>
          {/*</ErrorsFeedback>*/}
        </Alert.Provider>
        {/*</NonceProvider>*/}
      </AppThemeProvider>
    </ApolloProvider>
  )
}
