import React, { useEffect, useMemo, useState } from "react"
import { Box, Button, Flex, Text, VStack } from "@prosapient/prosapient-styleguide"
import { Paragraph } from "components/shared/Layout"
import { useLocation } from "react-router"
import { IAction, IProjectAction, IProjectStatus } from "api/__generated__/schema"
import { AnswerPending } from "./actions/AnswerPending"
import { useResizeDetector } from "react-resize-detector"
import { CardInfo, CardWrapper, StyledCollapse, StyledLink } from "../../styles"
import { CardTitle } from "../CardTitle"
//
// const TimeSlots = ({ show }: { show: boolean }) => {
//   if (!show) return null
//
//   return (
//     <>
//       <Text lineHeight={7} mb={2}>
//         You have been invited to take part in a consultation from proSapient. Please provide your availability for a 1
//         hour call.
//       </Text>
//       <MobileButton onClick={() => {}} iconSize="sm">
//         Provide timeslots
//       </MobileButton>
//       MobileButton
//     </>
//   )
// }
//
const Scheduling = ({ show }: { show: boolean }) => {
  if (!show) return null

  return <Text lineHeight={7}>Scheduling. Waiting for client</Text>
}

// const JoinToCall = ({ show }: { show: boolean }) => {
//   if (!show) return null
//
//   return (
//     <MobileButton onClick={() => {}} iconSize="sm">
//       Join the call
//     </MobileButton>
//   )
// }
//
// const CompletedCallWrapper = styled(Flex)`
//   @media only screen and (max-width: 640px) {
//     & > *:first-child {
//       text-align: left;
//     }
//
//     & > * {
//       text-align: center;
//     }
//
//     & > *:last-child {
//       text-align: right;
//     }
//   }
// `
//
// const CompletedCall = ({ show }: { show: boolean }) => {
//   if (!show) return null
//
//   return (
//     <Box>
//       <CompletedCallWrapper maxWidth="600px">
//         <VStack flex={1}>
//           <Text fontSize={5} color="beta.600">
//             Rate
//           </Text>
//           <Text>
//             $550.00
//             <Text fontSize={5} color="beta.600" inline>
//               /hour
//             </Text>
//           </Text>
//         </VStack>
//         <VStack flex={1}>
//           <Text fontSize={5} color="beta.600">
//             Finished on
//           </Text>
//           <Text>21 Dec 2021</Text>
//         </VStack>
//         <VStack flex={1}>
//           <Text fontSize={5} color="beta.600">
//             Earned
//           </Text>
//           <Text>$9850</Text>
//         </VStack>
//       </CompletedCallWrapper>
//     </Box>
//   )
// }

const CallDetails = () => {
  const { search, pathname } = useLocation()
  const view = useMemo(() => new URLSearchParams(search).get("view") || "", [search])

  const show = useMemo(() => {
    return view === "3" || view === "4"
  }, [view])

  const contacts = useMemo(() => {
    return view === "4"
  }, [view])

  if (!show) return null

  return (
    <>
      <Box height={1} width="100%" backgroundColor="beta.300" my="20px" />

      <Text inline lineHeight={7} fontWeight={4}>
        Your call on TestProject begins in 30 minutes.
      </Text>

      {contacts && (
        <VStack mt={6} style={{ gridGap: "0.75rem" }}>
          <Text>
            Join conference via{" "}
            <StyledLink color="alpha.600" to={pathname + search}>
              browser
            </StyledLink>
            . Please open the link in one of the following browsers: Chrome, Firefox, Safari. Please ensure a fast &
            stable internet connection by closing unnecessary programs and windows
          </Text>
          <Text>Our conferencing system will call the following number when the consultation is ready to start:</Text>
          <Text>Alternatively, please feel free to dial in using the number and pin code listed below</Text>

          <Text fontWeight={4}>PIN: 823065</Text>

          <Text>One tap phone dial in numbers:</Text>

          <Text>
            US{" "}
            <StyledLink color="alpha.600" to={`tel:+14122754447 ,, 823065#`}>
              +14122754447 ,, 823065#
            </StyledLink>
          </Text>
          <Text>
            DE{" "}
            <StyledLink color="alpha.600" to={`tel:+14122754447 ,, 823065#`}>
              +14122754447 ,, 823065#
            </StyledLink>
          </Text>
          <Text>
            ES{" "}
            <StyledLink color="alpha.600" to={`tel:+14122754447 ,, 823065#`}>
              +14122754447 ,, 823065#
            </StyledLink>{" "}
            (this number is not callable from Zoom)
          </Text>
          <Text>
            ES{" "}
            <StyledLink color="alpha.600" to={`tel:+14122754447 ,, 823065#`}>
              +14122754447 ,, 823065#
            </StyledLink>{" "}
            (this number is not callable from Zoom)
          </Text>
          <Text>
            BG{" "}
            <StyledLink color="alpha.600" to={`tel:+14122754447 ,, 823065#`}>
              +14122754447 ,, 823065#
            </StyledLink>
          </Text>
          <Text>
            IN{" "}
            <StyledLink color="alpha.600" to={`tel:+14122754447 ,, 823065#`}>
              +14122754447 ,, 823065#
            </StyledLink>{" "}
            (local)
          </Text>
          <Text>
            PT{" "}
            <StyledLink color="alpha.600" to={`tel:+14122754447 ,, 823065#`}>
              +14122754447 ,, 823065#
            </StyledLink>{" "}
            (this number is not callable from Zoom)
          </Text>
          <Text>
            PT{" "}
            <StyledLink color="alpha.600" to={`tel:+14122754447 ,, 823065#`}>
              +14122754447 ,, 823065#
            </StyledLink>{" "}
            (this number is not callable from Zoom)
          </Text>
          <Text>
            UA{" "}
            <StyledLink color="alpha.600" to={`tel:+14122754447 ,, 823065#`}>
              +14122754447 ,, 823065#
            </StyledLink>
          </Text>
        </VStack>
      )}
    </>
  )
}

type CardProps = {
  externalId?: string
  title?: string
  description?: string
  status?: IProjectStatus
  actions: IProjectAction[]
  hasScreeningQuestions: boolean
}

export const ProjectCard = ({
  externalId,
  title = "",
  description = "",
  status,
  actions,
  hasScreeningQuestions,
}: CardProps) => {
  const displayActions = useMemo(
    () =>
      actions?.filter(({ action }) => {
        return [IAction.AnswerPending].includes(action)
      }),
    [actions]
  )

  const { height, ref } = useResizeDetector()
  const [expanded, setExpanded] = useState(true)
  const [canExpand, setCanExpand] = useState(true)
  const [heightDetected, setHeightDetected] = useState(false)

  const completedCall = useMemo(() => {
    return status === IProjectStatus.Archived
  }, [status])

  useEffect(() => {
    if (heightDetected) return

    if (height) {
      if (height > 49) {
        setExpanded(false)
        setCanExpand(true)
      } else {
        setExpanded(true)
        setCanExpand(false)
      }

      setHeightDetected(true)
    } else if (!description) {
      setHeightDetected(true)
    }
  }, [height])

  return (
    <CardWrapper style={{ opacity: heightDetected ? 1 : 0 }}>
      <CardInfo>
        <CardTitle title={title} status={status} />

        <Box style={heightDetected ? {} : { maxHeight: "1em", overflow: "hidden" }}>
          <StyledCollapse
            expanded={expanded}
            withGradient={false}
            withButton={true}
            threshold={120}
            expandButtonText="Show more..."
            collapseButtonText="Show less..."
          >
            <Paragraph ref={ref} lineClamp={expanded ? undefined : 2} preserveFormatting style={{ opacity: 1 }}>
              {description}
            </Paragraph>
          </StyledCollapse>
        </Box>

        {canExpand && description?.length > 0 && (
          <Box>
            <Button ghost onClick={() => setExpanded(!expanded)} style={{ padding: 0 }} iconSize="sm">
              {expanded ? "Show less" : "Show more..."}
            </Button>
          </Box>
        )}

        <CallDetails />
      </CardInfo>

      {displayActions?.length > 0 && (
        <Flex flexDirection="column" style={{ gridGap: 8 }} bg="beta.100" px={9} pt={7} pb={8}>
          {/*{[IProjectStatus.Accepted, IProjectStatus.Qualified].includes(status) && <ComplianceAndDocuments />}*/}

          {displayActions?.map(({ action }, index) => (
            <Box key={index}>
              {action === IAction.AnswerPending && (
                <AnswerPending externalId={externalId} hasScreeningQuestions={hasScreeningQuestions} />
              )}
              {action === IAction.Scheduling && <Scheduling show={!completedCall} />}
              {/*{action === IAction.JoinToCall && <JoinToCall show={!completedCall} />}*/}
            </Box>
          ))}
          {/*<TimeSlots show={noTimeSlots && !completedCall} />*/}
          {/*<Scheduling show={scheduling && !completedCall} />*/}
          {/*<JoinDisabled show={canNotJoin && !completedCall} />*/}
          {/*<JoinToCall show={canJoin && !completedCall} />*/}
          {/*<CompletedCall show={completedCall} />*/}
        </Flex>
      )}
    </CardWrapper>
  )
}
