import { ApolloProvider as ReactApolloProvider } from "@apollo/client"
import { httpClient, webSocketClient } from "./clients"

type IProps = {
  client?: "webSocket" | "http"
  children: React.ReactNode
}

export const ApolloProvider = ({ client = "webSocket", children }: IProps) => {
  return (
    <ReactApolloProvider client={client === "webSocket" ? webSocketClient : httpClient}>{children}</ReactApolloProvider>
  )
}
