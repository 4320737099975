import {
  Grid,
  TableBody,
  TableDataCell,
  TableHeader,
  TableHeaderCell,
  TableRaw,
  TableRow,
} from "@prosapient/prosapient-styleguide"
import styled from "styled-components"

export const StyledTable = styled(TableRaw)`
  border-radius: 2px;
  overflow: hidden;
  box-shadow: 0 3px 18px rgb(0 0 0 / 13%);
  @media only screen and (max-width: 1024px) {
    box-shadow: none;
    overflow: unset;
  }
`

export const StyledHeaderCell = styled(TableHeaderCell)`
  font-size: 1rem;
  font-weight: ${props => props.theme.fontWeights[2]};
  background: ${props => props.theme.colors.white};

  padding: 1.42rem 0.71rem;

  &:first-child {
    padding-left: 1.42rem !important;
  }

  &:last-child {
    padding-right: 1.42rem !important;
  }
`
export const StyledDataCell = styled(TableDataCell)`
  padding: 1.42rem 0.71rem;

  &:first-child {
    padding-left: 1.42rem !important;
  }

  &:last-child {
    padding-right: 1.42rem !important;
  }
`

export const StyledTableRow = styled(TableRow)`
  &:nth-child(odd) {
    background: #f9f9f9;
  }

  &:nth-child(even) {
    background: #fff;
  }

  &:hover {
    background: ${props => {
      //  @ts-ignore
      return props.theme.colors.alpha[50]
    }} !important;
  }
`

export const StyledTableHeader = styled(TableHeader)`
  @media only screen and (max-width: 1024px) {
    display: none;
  }
`

export const StyledTableBody = styled(TableBody)`
  .showMobile {
    display: none;
  }

  @media only screen and (max-width: 1024px) {
    display: grid;
    gap: 8px;
    background: transparent;

    ${StyledDataCell} {
      padding: 0 !important;
    }

    .hideMobile {
      display: none;
    }

    .showMobile {
      display: block;
    }
  }

  ${StyledTableRow} {
    @media only screen and (max-width: 1024px) {
      display: flex;
      flex-wrap: wrap;
      background: ${props => props.theme.colors.white};
      box-shadow: 0 3px 18px rgb(0 0 0 / 13%);
      padding: 24px;
      border-radius: 2px;

      &:hover {
        background: ${props => props.theme.colors.white} !important;
      }

      td:nth-child(1) {
        flex: 1 1 100%;
        line-height: 25px;
        padding-bottom: 10px !important;
      }

      td:nth-child(2) {
        width: 30px;
      }

      td:nth-child(3) {
        width: calc(100% - 30px);
      }

      td:nth-child(4) {
        flex: 1 1 100%;
        padding-top: 10px !important;

        button {
          min-width: 240px;
        }
      }
    }

    @media only screen and (max-width: 640px) {
      td:nth-child(4) {
        button {
          width: 100%;
        }
      }
    }
  }
`

export const GridContainer = styled(Grid).attrs({ mb: 0, gridGap: 20 })`
  grid-template-columns: auto 452px;
  grid-template-areas: "main sidebar";

  & > *:nth-child(1) {
    grid-area: main;
  }

  & > *:nth-child(2) {
    grid-area: sidebar;
    margin-bottom: -1rem;
  }

  @media only screen and (max-width: 1024px) {
    grid-template-areas:
      "sidebar"
      "main";
    grid-template-columns: auto;
  }
`
//
// export const PageMain = styled(Box).attrs({ width: "100vw" })`
//   padding: 2rem;
//   min-height: 100%;
//
//   @media only screen and (max-width: 1024px) {
//     display: flex;
//     flex-direction: column;
//     min-height: calc(var(--vh) - ${HEADER_MOBILE_HEIGHT}px);
//   }
//
//   @media only screen and (max-width: 640px) {
//     max-width: 100%;
//     padding: 1rem;
//     margin: 0 auto;
//   }
// `
