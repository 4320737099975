import React from "react"
import styled from "styled-components"
import { Box, Flex, FlexProps } from "@prosapient/prosapient-styleguide"

const StyledTwoColumnStack = styled(Flex)`
  flex-wrap: wrap;
  justify-content: space-between;
`

type Gutter = number | string

interface TwoColumnGutterProps {
  vGutter?: Gutter
  hGutter?: Gutter
}

interface GutterProps {
  gutter?: Gutter
}

type TwoColumnStackProps = React.PropsWithChildren<TwoColumnGutterProps & FlexProps>

const Context = React.createContext<TwoColumnStackProps>({ vGutter: 0, hGutter: 0 })

export const TwoColumnStack = ({ vGutter = 0, hGutter = 0, ...props }: TwoColumnStackProps) => {
  return (
    <Context.Provider value={{ vGutter, hGutter }}>
      <StyledTwoColumnStack {...props} />
    </Context.Provider>
  )
}

type TwoColumnStackItemProps = React.PropsWithChildren<{}>

// for some reason without setting font-size=0 there is a small shift between columns
const StyledTwoColumnStackItem = styled(Box)<GutterProps>`
  font-size: 0;
  flex: ${props => `0 0 calc(50% - ${props.theme.newSpace[props.gutter || 0]})`};
  width: ${props => `calc(50% - ${props.theme.newSpace[props.gutter || 0]})`};

  @media screen and (max-width: 640px) {
    width: 100%;
    flex: 1 1 100%;
  }
`

export const TwoColumnStackItem = React.forwardRef<HTMLDivElement, TwoColumnStackItemProps>(
  (props: TwoColumnStackItemProps, ref) => {
    return (
      <Context.Consumer>
        {value => (
          <StyledTwoColumnStackItem {...props} gutter={value.hGutter} mb={value.vGutter} ref={ref}>
            {props.children}
          </StyledTwoColumnStackItem>
        )}
      </Context.Consumer>
    )
  }
)

export const FullLineStackItem = (props: React.PropsWithChildren<{}>) => {
  return (
    <Context.Consumer>
      {value => (
        <Box {...props} mb={value.vGutter} width={1}>
          {props.children}
        </Box>
      )}
    </Context.Consumer>
  )
}
