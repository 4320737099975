import { MIcon } from "components/shared/MIcon/MIcon"

export const IconStatus = ({ complete }: { complete: boolean }) => (
  <MIcon
    v={2}
    style={{ height: "22px" }}
    icon={complete ? "check_circle" : "info"}
    color={complete ? "alpha.800" : "epsilon.600"}
  />
)
