import styled from "styled-components"
import { TableDataCell, TableHeaderCell, TableRaw, TableRow } from "@prosapient/prosapient-styleguide"

export const StyledTable = styled(TableRaw)`
  border-radius: 2px;
  overflow: hidden;
  box-shadow: 0 3px 18px rgb(0 0 0 / 13%);
  width: 100%;
  max-width: 100%;
`

export const StyledHeaderCell = styled(TableHeaderCell)`
  font-size: 1rem;
  font-weight: ${props => props.theme.fontWeights[2]};
  background: ${props => props.theme.colors.white};

  padding: 1.42rem 0.71rem;

  &:first-child {
    padding-left: 1.42rem !important;
  }

  &:last-child {
    padding-right: 1.42rem !important;
  }
`

export const StyledDataCell = styled(TableDataCell)`
  padding: 1.42rem 0.71rem;

  &:first-child {
    padding-left: 1.42rem !important;
  }

  &:last-child {
    padding-right: 1.42rem !important;
  }
`

export const StyledTableRow = styled(TableRow)`
  &:nth-child(odd) {
    background: #f9f9f9;
  }

  &:nth-child(even) {
    background: #fff;
  }

  &:hover {
    background: ${props => {
      //  @ts-ignore
      return props.theme.colors.alpha[50]
    }} !important;

    .hoverOnly {
      opacity: 1;
    }
  }

  .hoverOnly {
    opacity: 0;
  }

  @media only screen and (max-width: 640px) {
    .hoverOnly {
      opacity: 1;
    }
  }
`
