import { MIcon } from "components/shared/MIcon/MIcon"
import React, { useState } from "react"
import { EditButton, Wrapper } from "./styles"

import { ViewInfo } from "./ViewInfo"
import { UpdateInfo } from "./UpdateInfo"
import useMediaQuery from "@mui/material/useMediaQuery"
import { VStack } from "@prosapient/prosapient-styleguide"
import { ExpertCV } from "./ExpertCV"
import styled from "styled-components"

// export const Stack = styled(VStack).attrs({ width: "100%", minHeight: "100%" })`
export const Stack = styled(VStack).attrs({ width: "100%" })`
  //box-sizing: content-box;
  //display: flex;
  //justify-content: space-between;
  //flex-direction: column;

  @media only screen and (max-width: 640px) {
    flex: 1;
  }
`

export const BasicInformation = ({ style }: { style?: React.CSSProperties }) => {
  const [viewOnly, setViewOnly] = useState(true)
  const isMobile = useMediaQuery("(max-width:1024px)")

  return (
    <Wrapper style={style}>
      {viewOnly && !isMobile && (
        <EditButton title="Edit" onClick={() => setViewOnly(false)}>
          <MIcon icon="edit" v={2} color="beta.400" hoverColor="alpha.600" />
        </EditButton>
      )}

      <Stack>
        <ExpertCV />

        {viewOnly && (
          <ViewInfo
            isMobile={isMobile}
            onClose={() => {
              setViewOnly(false)
            }}
          />
        )}

        {!viewOnly && (
          <UpdateInfo
            onClose={() => {
              setViewOnly(true)
            }}
          />
        )}
      </Stack>
    </Wrapper>
  )
}
