import { useMemo, useState } from "react"
import createUseContext from "constate"

import STEPS from "../steps"

const useTraining = () => {
  const [currentStep, setCurrentStep] = useState(0)
  const [count] = useState(STEPS.length)
  const [steps] = useState<string[]>(STEPS)

  const state = useMemo(() => ({ currentStep, count, steps }), [currentStep, count, steps])

  const nextPage = () => {
    if (state.currentStep < state.count) {
      setCurrentStep(state.currentStep + 1)
    }
  }

  const previousPage = () => {
    if (state.currentStep > 0) {
      setCurrentStep(state.currentStep - 1)
    }
  }

  return { state, nextPage, previousPage }
}

const [TrainingProvider, useTrainingContext] = createUseContext(useTraining)

export { TrainingProvider, useTrainingContext }
