import styled from "styled-components"
import { Box } from "@prosapient/prosapient-styleguide"
import { HEADER_MOBILE_HEIGHT } from "components/constants"

export const PageMain = styled(Box)`
  
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 2rem;
  max-width: calc(100vw - 4rem);

  // @media only screen and (max-width: 1024px) {
  //   min-height: calc(var(--vh) - ${HEADER_MOBILE_HEIGHT}px);
  // }

  @media only screen and (max-width: 640px) {
    max-width: 100%;
    padding: 1rem;
    margin: 0 auto;
    align-self: flex-start;
    box-shadow: none;
    min-height: calc(var(--vh) - ${HEADER_MOBILE_HEIGHT}px);
    //min-height: calc(var(--vh) - 56px);
  }
`
