import { Alert, Box, useAlert } from "@prosapient/prosapient-styleguide"
import { ROUTES } from "components/constants"
import { AccountDeactivationConfirmModal } from "../modals/AccountDeactivationConfirmModal"
import { AccountDeactivationSentModal } from "../modals/AccountDeactivationSentModal"

import { ActionBox, DeactivateButton, StackItem, StyledButton } from "../styles"
import { useState } from "react"
import { useMe } from "hooks"
import { useRequestCancelDeactivationMutation } from "../../../../../../api/__generated__/schema"

type IProps = {
  isMobile: boolean
  onClose: () => void
}

export const ActionPanel = ({ isMobile, onClose }: IProps) => {
  const {
    me: { state },
    refetch,
  } = useMe()
  const alert = useAlert(Alert.Context)
  const [requestCancelDeactivation, { loading: loadingDeactivation }] = useRequestCancelDeactivationMutation()

  const [openSentModal, setOpenSentModal] = useState(false)
  const [openConfirmModal, setOpenConfirmModal] = useState(false)

  const deactivateHandler = async () => {
    state?.isDeactivationRequested ? await submitCancelDeactivation() : setOpenConfirmModal(true)
  }

  const submitCancelDeactivation = async () => {
    try {
      const result = await requestCancelDeactivation()

      const { success, description = "" } = result?.data?.requestCancelDeactivation || {}

      if (success) {
        alert.success(description || "The deactivation request was canceled successfully")
        await cancelAccountDeactivationHandler()
      } else {
        alert.error(description)
      }
    } catch (e) {
      /*eslint-disable */
      console.error(e)
      /*eslint-enable */

      // @ts-ignore
      alert.error(e?.message || e)
    }
  }

  const deactivateAccountHandler = async () => {
    setOpenConfirmModal(false)
    setOpenSentModal(true)

    await refetch()
  }

  const cancelAccountDeactivationHandler = async () => {
    setOpenConfirmModal(false)
    setOpenSentModal(false)

    await refetch()
  }

  return (
    <StackItem>
      <ActionBox>
        {isMobile && (
          <>
            <StyledButton type="button" variant="secondary" onClick={onClose}>
              Edit
            </StyledButton>
            <Box m={2} />
          </>
        )}
        <StyledButton
          type="button"
          variant="secondary"
          onClick={() => {
            // @ts-ignore
            window.location = ROUTES.authChangePassword
          }}
        >
          Change password
        </StyledButton>
        <Box m={2} />
        <DeactivateButton type="button" onClick={deactivateHandler}>
          {state?.isDeactivationRequested ? "Cancel deactivation request" : "Deactivate account"}
        </DeactivateButton>
      </ActionBox>

      <AccountDeactivationConfirmModal
        isOpen={openConfirmModal}
        onSubmit={deactivateAccountHandler}
        onClose={() => {
          setOpenConfirmModal(false)
        }}
      />

      <AccountDeactivationSentModal
        isOpen={openSentModal}
        loading={loadingDeactivation}
        onSubmit={submitCancelDeactivation}
        onClose={() => {
          setOpenSentModal(false)
        }}
      />
    </StackItem>
  )
}
