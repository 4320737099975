import { MIcon } from "components/shared/MIcon/MIcon"
import { Flex } from "@prosapient/prosapient-styleguide"
import styled from "styled-components"

const InfoIcon = <MIcon icon="info" color="white" style={{ marginRight: "8px" }} />

const Wrapper = styled(Flex).attrs({ justifyContent: "center", color: "white", flex: "1 1 100%" })`
  line-height: 25px;
  padding: 7px 8px 8px;
  align-items: center;
  grid-gap: 8px;

  @media only screen and (max-width: 640px) {
    flex-wrap: wrap;
  }
`

type IProps = {
  show: boolean
  hideIcon?: boolean
  variant?: "success" | "error"
  Icon?: JSX.Element
  children: any
}

export const Banner = ({ variant = "success", show = true, hideIcon = false, children, Icon = InfoIcon }: IProps) => {
  if (!show) return null

  return (
    <Wrapper bg={variant === "success" ? "alpha.600" : "delta.500"}>
      {!hideIcon && Icon} {children}
    </Wrapper>
  )
}
