import React, { useEffect, useState } from "react"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import styled from "styled-components"
// @ts-ignore
import startsWith from "lodash.startswith"
import { Box } from "@prosapient/prosapient-styleguide"

export const StyledPhone = styled(PhoneInput).attrs(({ dropdownStyle, country }) => ({
  searchPlaceholder: "Search ",
  placeholder: "Type here using country code",
  country: country || "",
  enableSearch: true,
  disableSearchIcon: true,
  disableDropdown: false,
  inputStyle: {
    zIndex: 0,
    border: "none",
    borderBottom: `1px solid ${(props: any) => (props.isInvalid ? "red" : "#cacaca")}`,
    background: "transparent",
    borderRadius: 0,
  },
  buttonStyle: {
    border: "none",
    borderBottom: "1px solid #cacaca",
    background: "transparent",
    borderRadius: 0,
  },
  dropdownStyle,
  searchStyle: {
    width: "calc(100% - 10px)",
    margin: 0,
    textTransform: "capitalize",
  },
}))`
  .flag-dropdown.invalid-number .selected-flag .flag {
    //background: none;
      // background: ${props => (props.country ? "" : "none")};
  }
`

const list = [
  {
    name: "Afghanistan",
    regions: ["asia"],
    iso2: "af",
    countryCode: "93",
    dialCode: "93",
    format: "+.. ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Albania",
    regions: ["europe"],
    iso2: "al",
    countryCode: "355",
    dialCode: "355",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Algeria",
    regions: ["africa", "north-africa"],
    iso2: "dz",
    countryCode: "213",
    dialCode: "213",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Andorra",
    regions: ["europe"],
    iso2: "ad",
    countryCode: "376",
    dialCode: "376",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Angola",
    regions: ["africa"],
    iso2: "ao",
    countryCode: "244",
    dialCode: "244",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Antigua and Barbuda",
    regions: ["america", "carribean"],
    iso2: "ag",
    countryCode: "1268",
    dialCode: "1268",
    format: "+.... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Argentina",
    regions: ["america", "south-america"],
    iso2: "ar",
    countryCode: "54",
    dialCode: "54",
    format: "+.. (..) ........",
    priority: 0,
    mainCode: true,
  },
  {
    name: "Armenia",
    regions: ["asia", "ex-ussr"],
    iso2: "am",
    countryCode: "374",
    dialCode: "374",
    format: "+... .. ......",
    priority: 0,
  },
  {
    name: "Aruba",
    regions: ["america", "carribean"],
    iso2: "aw",
    countryCode: "297",
    dialCode: "297",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Australia",
    regions: ["oceania"],
    iso2: "au",
    countryCode: "61",
    dialCode: "61",
    format: "+.. (..) .... ....",
    priority: 0,
    mainCode: true,
  },
  {
    name: "Austria",
    regions: ["europe", "eu-union"],
    iso2: "at",
    countryCode: "43",
    dialCode: "43",
    format: "+.. ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Azerbaijan",
    regions: ["asia", "ex-ussr"],
    iso2: "az",
    countryCode: "994",
    dialCode: "994",
    format: "+... (..) ... .. ..",
    priority: 0,
  },
  {
    name: "Bahamas",
    regions: ["america", "carribean"],
    iso2: "bs",
    countryCode: "1242",
    dialCode: "1242",
    format: "+.... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Bahrain",
    regions: ["middle-east"],
    iso2: "bh",
    countryCode: "973",
    dialCode: "973",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Bangladesh",
    regions: ["asia"],
    iso2: "bd",
    countryCode: "880",
    dialCode: "880",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Barbados",
    regions: ["america", "carribean"],
    iso2: "bb",
    countryCode: "1246",
    dialCode: "1246",
    format: "+.... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Belarus",
    regions: ["europe", "ex-ussr"],
    iso2: "by",
    countryCode: "375",
    dialCode: "375",
    format: "+... (..) ... .. ..",
    priority: 0,
  },
  {
    name: "Belgium",
    regions: ["europe", "eu-union"],
    iso2: "be",
    countryCode: "32",
    dialCode: "32",
    format: "+.. ... .. .. ..",
    priority: 0,
  },
  {
    name: "Belize",
    regions: ["america", "central-america"],
    iso2: "bz",
    countryCode: "501",
    dialCode: "501",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Benin",
    regions: ["africa"],
    iso2: "bj",
    countryCode: "229",
    dialCode: "229",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Bhutan",
    regions: ["asia"],
    iso2: "bt",
    countryCode: "975",
    dialCode: "975",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Bolivia",
    regions: ["america", "south-america"],
    iso2: "bo",
    countryCode: "591",
    dialCode: "591",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Bosnia and Herzegovina",
    regions: ["europe", "ex-yugos"],
    iso2: "ba",
    countryCode: "387",
    dialCode: "387",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Botswana",
    regions: ["africa"],
    iso2: "bw",
    countryCode: "267",
    dialCode: "267",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Brazil",
    regions: ["america", "south-america"],
    iso2: "br",
    countryCode: "55",
    dialCode: "55",
    format: "+.. (..) .........",
    priority: 0,
  },
  {
    name: "British Indian Ocean Territory",
    regions: ["asia"],
    iso2: "io",
    countryCode: "246",
    dialCode: "246",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Brunei",
    regions: ["asia"],
    iso2: "bn",
    countryCode: "673",
    dialCode: "673",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Bulgaria",
    regions: ["europe", "eu-union"],
    iso2: "bg",
    countryCode: "359",
    dialCode: "359",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Burkina Faso",
    regions: ["africa"],
    iso2: "bf",
    countryCode: "226",
    dialCode: "226",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Burundi",
    regions: ["africa"],
    iso2: "bi",
    countryCode: "257",
    dialCode: "257",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Cambodia",
    regions: ["asia"],
    iso2: "kh",
    countryCode: "855",
    dialCode: "855",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Cameroon",
    regions: ["africa"],
    iso2: "cm",
    countryCode: "237",
    dialCode: "237",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    countryCode: "1",
    dialCode: "1",
    format: "+. (...) ...-....",
    priority: 1,
    mainCode: true,
  },
  {
    name: "Cape Verde",
    regions: ["africa"],
    iso2: "cv",
    countryCode: "238",
    dialCode: "238",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Caribbean Netherlands",
    regions: ["america", "carribean"],
    iso2: "bq",
    countryCode: "599",
    dialCode: "599",
    format: "+... ... ... ... ... ..",
    priority: 1,
  },
  {
    name: "Central African Republic",
    regions: ["africa"],
    iso2: "cf",
    countryCode: "236",
    dialCode: "236",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Chad",
    regions: ["africa"],
    iso2: "td",
    countryCode: "235",
    dialCode: "235",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Chile",
    regions: ["america", "south-america"],
    iso2: "cl",
    countryCode: "56",
    dialCode: "56",
    format: "+.. ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "China",
    regions: ["asia"],
    iso2: "cn",
    countryCode: "86",
    dialCode: "86",
    format: "+.. ..-.........",
    priority: 0,
  },
  {
    name: "Colombia",
    regions: ["america", "south-america"],
    iso2: "co",
    countryCode: "57",
    dialCode: "57",
    format: "+.. ... ... ....",
    priority: 0,
  },
  {
    name: "Comoros",
    regions: ["africa"],
    iso2: "km",
    countryCode: "269",
    dialCode: "269",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Congo",
    regions: ["africa"],
    iso2: "cd",
    countryCode: "243",
    dialCode: "243",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Congo",
    regions: ["africa"],
    iso2: "cg",
    countryCode: "242",
    dialCode: "242",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Costa Rica",
    regions: ["america", "central-america"],
    iso2: "cr",
    countryCode: "506",
    dialCode: "506",
    format: "+... ....-....",
    priority: 0,
  },
  {
    name: "Côte d’Ivoire",
    regions: ["africa"],
    iso2: "ci",
    countryCode: "225",
    dialCode: "225",
    format: "+... .. .. .. ..",
    priority: 0,
  },
  {
    name: "Croatia",
    regions: ["europe", "eu-union", "ex-yugos"],
    iso2: "hr",
    countryCode: "385",
    dialCode: "385",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Cuba",
    regions: ["america", "carribean"],
    iso2: "cu",
    countryCode: "53",
    dialCode: "53",
    format: "+.. ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Curaçao",
    regions: ["america", "carribean"],
    iso2: "cw",
    countryCode: "599",
    dialCode: "599",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Cyprus",
    regions: ["europe", "eu-union"],
    iso2: "cy",
    countryCode: "357",
    dialCode: "357",
    format: "+... .. ......",
    priority: 0,
  },
  {
    name: "Czech Republic",
    regions: ["europe", "eu-union"],
    iso2: "cz",
    countryCode: "420",
    dialCode: "420",
    format: "+... ... ... ...",
    priority: 0,
  },
  {
    name: "Denmark",
    regions: ["europe", "eu-union", "baltic"],
    iso2: "dk",
    countryCode: "45",
    dialCode: "45",
    format: "+.. .. .. .. ..",
    priority: 0,
  },
  {
    name: "Djibouti",
    regions: ["africa"],
    iso2: "dj",
    countryCode: "253",
    dialCode: "253",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Dominica",
    regions: ["america", "carribean"],
    iso2: "dm",
    countryCode: "1767",
    dialCode: "1767",
    format: "+.... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Dominican Republic",
    regions: ["america", "carribean"],
    iso2: "do",
    countryCode: "1",
    dialCode: "1",
    format: "+. ... ... ... ... ..",
    priority: 2,
    mainCode: true,
  },
  {
    name: "Ecuador",
    regions: ["america", "south-america"],
    iso2: "ec",
    countryCode: "593",
    dialCode: "593",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Egypt",
    regions: ["africa", "north-africa"],
    iso2: "eg",
    countryCode: "20",
    dialCode: "20",
    format: "+.. ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "El Salvador",
    regions: ["america", "central-america"],
    iso2: "sv",
    countryCode: "503",
    dialCode: "503",
    format: "+... ....-....",
    priority: 0,
  },
  {
    name: "Equatorial Guinea",
    regions: ["africa"],
    iso2: "gq",
    countryCode: "240",
    dialCode: "240",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Eritrea",
    regions: ["africa"],
    iso2: "er",
    countryCode: "291",
    dialCode: "291",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Estonia",
    regions: ["europe", "eu-union", "ex-ussr", "baltic"],
    iso2: "ee",
    countryCode: "372",
    dialCode: "372",
    format: "+... .... ......",
    priority: 0,
  },
  {
    name: "Ethiopia",
    regions: ["africa"],
    iso2: "et",
    countryCode: "251",
    dialCode: "251",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Fiji",
    regions: ["oceania"],
    iso2: "fj",
    countryCode: "679",
    dialCode: "679",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Finland",
    regions: ["europe", "eu-union", "baltic"],
    iso2: "fi",
    countryCode: "358",
    dialCode: "358",
    format: "+... .. ... .. ..",
    priority: 0,
  },
  {
    name: "France",
    regions: ["europe", "eu-union"],
    iso2: "fr",
    countryCode: "33",
    dialCode: "33",
    format: "+.. . .. .. .. ..",
    priority: 0,
  },
  {
    name: "French Guiana",
    regions: ["america", "south-america"],
    iso2: "gf",
    countryCode: "594",
    dialCode: "594",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "French Polynesia",
    regions: ["oceania"],
    iso2: "pf",
    countryCode: "689",
    dialCode: "689",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Gabon",
    regions: ["africa"],
    iso2: "ga",
    countryCode: "241",
    dialCode: "241",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Gambia",
    regions: ["africa"],
    iso2: "gm",
    countryCode: "220",
    dialCode: "220",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Georgia",
    regions: ["asia", "ex-ussr"],
    iso2: "ge",
    countryCode: "995",
    dialCode: "995",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Germany",
    regions: ["europe", "eu-union", "baltic"],
    iso2: "de",
    countryCode: "49",
    dialCode: "49",
    format: "+.. .... ........",
    priority: 0,
  },
  {
    name: "Ghana",
    regions: ["africa"],
    iso2: "gh",
    countryCode: "233",
    dialCode: "233",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Greece",
    regions: ["europe", "eu-union"],
    iso2: "gr",
    countryCode: "30",
    dialCode: "30",
    format: "+.. ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Grenada",
    regions: ["america", "carribean"],
    iso2: "gd",
    countryCode: "1473",
    dialCode: "1473",
    format: "+.... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Guadeloupe",
    regions: ["america", "carribean"],
    iso2: "gp",
    countryCode: "590",
    dialCode: "590",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Guam",
    regions: ["oceania"],
    iso2: "gu",
    countryCode: "1671",
    dialCode: "1671",
    format: "+.... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Guatemala",
    regions: ["america", "central-america"],
    iso2: "gt",
    countryCode: "502",
    dialCode: "502",
    format: "+... ....-....",
    priority: 0,
  },
  {
    name: "Guinea",
    regions: ["africa"],
    iso2: "gn",
    countryCode: "224",
    dialCode: "224",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Guinea-Bissau",
    regions: ["africa"],
    iso2: "gw",
    countryCode: "245",
    dialCode: "245",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Guyana",
    regions: ["america", "south-america"],
    iso2: "gy",
    countryCode: "592",
    dialCode: "592",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Haiti",
    regions: ["america", "carribean"],
    iso2: "ht",
    countryCode: "509",
    dialCode: "509",
    format: "+... ....-....",
    priority: 0,
  },
  {
    name: "Honduras",
    regions: ["america", "central-america"],
    iso2: "hn",
    countryCode: "504",
    dialCode: "504",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Hong Kong",
    regions: ["asia"],
    iso2: "hk",
    countryCode: "852",
    dialCode: "852",
    format: "+... .... ....",
    priority: 0,
  },
  {
    name: "Hungary",
    regions: ["europe", "eu-union"],
    iso2: "hu",
    countryCode: "36",
    dialCode: "36",
    format: "+.. ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Iceland",
    regions: ["europe"],
    iso2: "is",
    countryCode: "354",
    dialCode: "354",
    format: "+... ... ....",
    priority: 0,
  },
  {
    name: "India",
    regions: ["asia"],
    iso2: "in",
    countryCode: "91",
    dialCode: "91",
    format: "+.. .....-.....",
    priority: 0,
  },
  {
    name: "Indonesia",
    regions: ["asia"],
    iso2: "id",
    countryCode: "62",
    dialCode: "62",
    format: "+.. ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Iran",
    regions: ["middle-east"],
    iso2: "ir",
    countryCode: "98",
    dialCode: "98",
    format: "+.. ... ... ....",
    priority: 0,
  },
  {
    name: "Iraq",
    regions: ["middle-east"],
    iso2: "iq",
    countryCode: "964",
    dialCode: "964",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Ireland",
    regions: ["europe", "eu-union"],
    iso2: "ie",
    countryCode: "353",
    dialCode: "353",
    format: "+... .. .......",
    priority: 0,
  },
  {
    name: "Israel",
    regions: ["middle-east"],
    iso2: "il",
    countryCode: "972",
    dialCode: "972",
    format: "+... ... ... ....",
    priority: 0,
  },
  {
    name: "Italy",
    regions: ["europe", "eu-union"],
    iso2: "it",
    countryCode: "39",
    dialCode: "39",
    format: "+.. ... .......",
    priority: 0,
  },
  {
    name: "Jamaica",
    regions: ["america", "carribean"],
    iso2: "jm",
    countryCode: "1876",
    dialCode: "1876",
    format: "+.... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Japan",
    regions: ["asia"],
    iso2: "jp",
    countryCode: "81",
    dialCode: "81",
    format: "+.. .. .... ....",
    priority: 0,
  },
  {
    name: "Jordan",
    regions: ["middle-east"],
    iso2: "jo",
    countryCode: "962",
    dialCode: "962",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Kazakhstan",
    regions: ["asia", "ex-ussr"],
    iso2: "kz",
    countryCode: "7",
    dialCode: "7",
    format: "+. ... ...-..-..",
    priority: 1,
    mainCode: true,
  },
  {
    name: "Kenya",
    regions: ["africa"],
    iso2: "ke",
    countryCode: "254",
    dialCode: "254",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Kiribati",
    regions: ["oceania"],
    iso2: "ki",
    countryCode: "686",
    dialCode: "686",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Kosovo",
    regions: ["europe", "ex-yugos"],
    iso2: "xk",
    countryCode: "383",
    dialCode: "383",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Kuwait",
    regions: ["middle-east"],
    iso2: "kw",
    countryCode: "965",
    dialCode: "965",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Kyrgyzstan",
    regions: ["asia", "ex-ussr"],
    iso2: "kg",
    countryCode: "996",
    dialCode: "996",
    format: "+... ... ... ...",
    priority: 0,
  },
  {
    name: "Laos",
    regions: ["asia"],
    iso2: "la",
    countryCode: "856",
    dialCode: "856",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Latvia",
    regions: ["europe", "eu-union", "ex-ussr", "baltic"],
    iso2: "lv",
    countryCode: "371",
    dialCode: "371",
    format: "+... .. ... ...",
    priority: 0,
  },
  {
    name: "Lebanon",
    regions: ["middle-east"],
    iso2: "lb",
    countryCode: "961",
    dialCode: "961",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Lesotho",
    regions: ["africa"],
    iso2: "ls",
    countryCode: "266",
    dialCode: "266",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Liberia",
    regions: ["africa"],
    iso2: "lr",
    countryCode: "231",
    dialCode: "231",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Libya",
    regions: ["africa", "north-africa"],
    iso2: "ly",
    countryCode: "218",
    dialCode: "218",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Liechtenstein",
    regions: ["europe"],
    iso2: "li",
    countryCode: "423",
    dialCode: "423",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Lithuania",
    regions: ["europe", "eu-union", "ex-ussr", "baltic"],
    iso2: "lt",
    countryCode: "370",
    dialCode: "370",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Luxembourg",
    regions: ["europe", "eu-union"],
    iso2: "lu",
    countryCode: "352",
    dialCode: "352",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Macau",
    regions: ["asia"],
    iso2: "mo",
    countryCode: "853",
    dialCode: "853",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Macedonia",
    regions: ["europe", "ex-yugos"],
    iso2: "mk",
    countryCode: "389",
    dialCode: "389",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Madagascar",
    regions: ["africa"],
    iso2: "mg",
    countryCode: "261",
    dialCode: "261",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Malawi",
    regions: ["africa"],
    iso2: "mw",
    countryCode: "265",
    dialCode: "265",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Malaysia",
    regions: ["asia"],
    iso2: "my",
    countryCode: "60",
    dialCode: "60",
    format: "+.. ..-....-....",
    priority: 0,
  },
  {
    name: "Maldives",
    regions: ["asia"],
    iso2: "mv",
    countryCode: "960",
    dialCode: "960",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Mali",
    regions: ["africa"],
    iso2: "ml",
    countryCode: "223",
    dialCode: "223",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Malta",
    regions: ["europe", "eu-union"],
    iso2: "mt",
    countryCode: "356",
    dialCode: "356",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Marshall Islands",
    regions: ["oceania"],
    iso2: "mh",
    countryCode: "692",
    dialCode: "692",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Martinique",
    regions: ["america", "carribean"],
    iso2: "mq",
    countryCode: "596",
    dialCode: "596",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Mauritania",
    regions: ["africa"],
    iso2: "mr",
    countryCode: "222",
    dialCode: "222",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Mauritius",
    regions: ["africa"],
    iso2: "mu",
    countryCode: "230",
    dialCode: "230",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Mexico",
    regions: ["america", "central-america"],
    iso2: "mx",
    countryCode: "52",
    dialCode: "52",
    format: "+.. ... ... ....",
    priority: 0,
    mainCode: true,
  },
  {
    name: "Micronesia",
    regions: ["oceania"],
    iso2: "fm",
    countryCode: "691",
    dialCode: "691",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Moldova",
    regions: ["europe"],
    iso2: "md",
    countryCode: "373",
    dialCode: "373",
    format: "+... (..) ..-..-..",
    priority: 0,
  },
  {
    name: "Monaco",
    regions: ["europe"],
    iso2: "mc",
    countryCode: "377",
    dialCode: "377",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Mongolia",
    regions: ["asia"],
    iso2: "mn",
    countryCode: "976",
    dialCode: "976",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Montenegro",
    regions: ["europe", "ex-yugos"],
    iso2: "me",
    countryCode: "382",
    dialCode: "382",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Morocco",
    regions: ["africa", "north-africa"],
    iso2: "ma",
    countryCode: "212",
    dialCode: "212",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Mozambique",
    regions: ["africa"],
    iso2: "mz",
    countryCode: "258",
    dialCode: "258",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Myanmar",
    regions: ["asia"],
    iso2: "mm",
    countryCode: "95",
    dialCode: "95",
    format: "+.. ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Namibia",
    regions: ["africa"],
    iso2: "na",
    countryCode: "264",
    dialCode: "264",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Nauru",
    regions: ["africa"],
    iso2: "nr",
    countryCode: "674",
    dialCode: "674",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Nepal",
    regions: ["asia"],
    iso2: "np",
    countryCode: "977",
    dialCode: "977",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Netherlands",
    regions: ["europe", "eu-union"],
    iso2: "nl",
    countryCode: "31",
    dialCode: "31",
    format: "+.. .. ........",
    priority: 0,
  },
  {
    name: "New Caledonia",
    regions: ["oceania"],
    iso2: "nc",
    countryCode: "687",
    dialCode: "687",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "New Zealand",
    regions: ["oceania"],
    iso2: "nz",
    countryCode: "64",
    dialCode: "64",
    format: "+.. ...-...-....",
    priority: 0,
  },
  {
    name: "Nicaragua",
    regions: ["america", "central-america"],
    iso2: "ni",
    countryCode: "505",
    dialCode: "505",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Niger",
    regions: ["africa"],
    iso2: "ne",
    countryCode: "227",
    dialCode: "227",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Nigeria",
    regions: ["africa"],
    iso2: "ng",
    countryCode: "234",
    dialCode: "234",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "North Korea",
    regions: ["asia"],
    iso2: "kp",
    countryCode: "850",
    dialCode: "850",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Norway",
    regions: ["europe", "baltic"],
    iso2: "no",
    countryCode: "47",
    dialCode: "47",
    format: "+.. ... .. ...",
    priority: 0,
  },
  {
    name: "Oman",
    regions: ["middle-east"],
    iso2: "om",
    countryCode: "968",
    dialCode: "968",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Pakistan",
    regions: ["asia"],
    iso2: "pk",
    countryCode: "92",
    dialCode: "92",
    format: "+.. ...-.......",
    priority: 0,
  },
  {
    name: "Palau",
    regions: ["oceania"],
    iso2: "pw",
    countryCode: "680",
    dialCode: "680",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Palestine",
    regions: ["middle-east"],
    iso2: "ps",
    countryCode: "970",
    dialCode: "970",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Panama",
    regions: ["america", "central-america"],
    iso2: "pa",
    countryCode: "507",
    dialCode: "507",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Papua New Guinea",
    regions: ["oceania"],
    iso2: "pg",
    countryCode: "675",
    dialCode: "675",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Paraguay",
    regions: ["america", "south-america"],
    iso2: "py",
    countryCode: "595",
    dialCode: "595",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Peru",
    regions: ["america", "south-america"],
    iso2: "pe",
    countryCode: "51",
    dialCode: "51",
    format: "+.. ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Philippines",
    regions: ["asia"],
    iso2: "ph",
    countryCode: "63",
    dialCode: "63",
    format: "+.. .... .......",
    priority: 0,
  },
  {
    name: "Poland",
    regions: ["europe", "eu-union", "baltic"],
    iso2: "pl",
    countryCode: "48",
    dialCode: "48",
    format: "+.. ...-...-...",
    priority: 0,
  },
  {
    name: "Portugal",
    regions: ["europe", "eu-union"],
    iso2: "pt",
    countryCode: "351",
    dialCode: "351",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Puerto Rico",
    regions: ["america", "carribean"],
    iso2: "pr",
    countryCode: "1",
    dialCode: "1",
    format: "+. ... ... ... ... ..",
    priority: 3,
    mainCode: true,
  },
  {
    name: "Qatar",
    regions: ["middle-east"],
    iso2: "qa",
    countryCode: "974",
    dialCode: "974",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Réunion",
    regions: ["africa"],
    iso2: "re",
    countryCode: "262",
    dialCode: "262",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Romania",
    regions: ["europe", "eu-union"],
    iso2: "ro",
    countryCode: "40",
    dialCode: "40",
    format: "+.. ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Russia",
    regions: ["europe", "asia", "ex-ussr", "baltic"],
    iso2: "ru",
    countryCode: "7",
    dialCode: "7",
    format: "+. (...) ...-..-..",
    priority: 0,
  },
  {
    name: "Rwanda",
    regions: ["africa"],
    iso2: "rw",
    countryCode: "250",
    dialCode: "250",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Saint Kitts and Nevis",
    regions: ["america", "carribean"],
    iso2: "kn",
    countryCode: "1869",
    dialCode: "1869",
    format: "+.... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Saint Lucia",
    regions: ["america", "carribean"],
    iso2: "lc",
    countryCode: "1758",
    dialCode: "1758",
    format: "+.... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Saint Vincent and the Grenadines",
    regions: ["america", "carribean"],
    iso2: "vc",
    countryCode: "1784",
    dialCode: "1784",
    format: "+.... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Samoa",
    regions: ["oceania"],
    iso2: "ws",
    countryCode: "685",
    dialCode: "685",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "San Marino",
    regions: ["europe"],
    iso2: "sm",
    countryCode: "378",
    dialCode: "378",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "São Tomé and Príncipe",
    regions: ["africa"],
    iso2: "st",
    countryCode: "239",
    dialCode: "239",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Saudi Arabia",
    regions: ["middle-east"],
    iso2: "sa",
    countryCode: "966",
    dialCode: "966",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Senegal",
    regions: ["africa"],
    iso2: "sn",
    countryCode: "221",
    dialCode: "221",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Serbia",
    regions: ["europe", "ex-yugos"],
    iso2: "rs",
    countryCode: "381",
    dialCode: "381",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Seychelles",
    regions: ["africa"],
    iso2: "sc",
    countryCode: "248",
    dialCode: "248",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Sierra Leone",
    regions: ["africa"],
    iso2: "sl",
    countryCode: "232",
    dialCode: "232",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Singapore",
    regions: ["asia"],
    iso2: "sg",
    countryCode: "65",
    dialCode: "65",
    format: "+.. ....-....",
    priority: 0,
  },
  {
    name: "Slovakia",
    regions: ["europe", "eu-union"],
    iso2: "sk",
    countryCode: "421",
    dialCode: "421",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Slovenia",
    regions: ["europe", "eu-union", "ex-yugos"],
    iso2: "si",
    countryCode: "386",
    dialCode: "386",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Solomon Islands",
    regions: ["oceania"],
    iso2: "sb",
    countryCode: "677",
    dialCode: "677",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Somalia",
    regions: ["africa"],
    iso2: "so",
    countryCode: "252",
    dialCode: "252",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "South Africa",
    regions: ["africa"],
    iso2: "za",
    countryCode: "27",
    dialCode: "27",
    format: "+.. ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "South Korea",
    regions: ["asia"],
    iso2: "kr",
    countryCode: "82",
    dialCode: "82",
    format: "+.. ... .... ....",
    priority: 0,
  },
  {
    name: "South Sudan",
    regions: ["africa", "north-africa"],
    iso2: "ss",
    countryCode: "211",
    dialCode: "211",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Spain",
    regions: ["europe", "eu-union"],
    iso2: "es",
    countryCode: "34",
    dialCode: "34",
    format: "+.. ... ... ...",
    priority: 0,
  },
  {
    name: "Sri Lanka",
    regions: ["asia"],
    iso2: "lk",
    countryCode: "94",
    dialCode: "94",
    format: "+.. ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Sudan",
    regions: ["africa"],
    iso2: "sd",
    countryCode: "249",
    dialCode: "249",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Suriname",
    regions: ["america", "south-america"],
    iso2: "sr",
    countryCode: "597",
    dialCode: "597",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Swaziland",
    regions: ["africa"],
    iso2: "sz",
    countryCode: "268",
    dialCode: "268",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Sweden",
    regions: ["europe", "eu-union", "baltic"],
    iso2: "se",
    countryCode: "46",
    dialCode: "46",
    format: "+.. (...) ...-...",
    priority: 0,
  },
  {
    name: "Switzerland",
    regions: ["europe"],
    iso2: "ch",
    countryCode: "41",
    dialCode: "41",
    format: "+.. .. ... .. ..",
    priority: 0,
  },
  {
    name: "Syria",
    regions: ["middle-east"],
    iso2: "sy",
    countryCode: "963",
    dialCode: "963",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Taiwan",
    regions: ["asia"],
    iso2: "tw",
    countryCode: "886",
    dialCode: "886",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Tajikistan",
    regions: ["asia", "ex-ussr"],
    iso2: "tj",
    countryCode: "992",
    dialCode: "992",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Tanzania",
    regions: ["africa"],
    iso2: "tz",
    countryCode: "255",
    dialCode: "255",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Thailand",
    regions: ["asia"],
    iso2: "th",
    countryCode: "66",
    dialCode: "66",
    format: "+.. ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Timor-Leste",
    regions: ["asia"],
    iso2: "tl",
    countryCode: "670",
    dialCode: "670",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Togo",
    regions: ["africa"],
    iso2: "tg",
    countryCode: "228",
    dialCode: "228",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Tonga",
    regions: ["oceania"],
    iso2: "to",
    countryCode: "676",
    dialCode: "676",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Trinidad and Tobago",
    regions: ["america", "carribean"],
    iso2: "tt",
    countryCode: "1868",
    dialCode: "1868",
    format: "+.... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Tunisia",
    regions: ["africa", "north-africa"],
    iso2: "tn",
    countryCode: "216",
    dialCode: "216",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Turkey",
    regions: ["europe"],
    iso2: "tr",
    countryCode: "90",
    dialCode: "90",
    format: "+.. ... ... .. ..",
    priority: 0,
  },
  {
    name: "Turkmenistan",
    regions: ["asia", "ex-ussr"],
    iso2: "tm",
    countryCode: "993",
    dialCode: "993",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Tuvalu",
    regions: ["asia"],
    iso2: "tv",
    countryCode: "688",
    dialCode: "688",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Uganda",
    regions: ["africa"],
    iso2: "ug",
    countryCode: "256",
    dialCode: "256",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Ukraine",
    regions: ["europe", "ex-ussr"],
    iso2: "ua",
    countryCode: "380",
    dialCode: "380",
    format: "+... (..) ... .. ..",
    priority: 0,
  },
  {
    name: "United Arab Emirates",
    regions: ["middle-east"],
    iso2: "ae",
    countryCode: "971",
    dialCode: "971",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "United Kingdom",
    regions: ["europe", "eu-union"],
    iso2: "gb",
    countryCode: "44",
    dialCode: "44",
    format: "+.. .... ......",
    priority: 0,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1",
    format: "+. (...) ...-....",
    priority: 0,
    mainCode: true,
  },
  {
    name: "Uruguay",
    regions: ["america", "south-america"],
    iso2: "uy",
    countryCode: "598",
    dialCode: "598",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Uzbekistan",
    regions: ["asia", "ex-ussr"],
    iso2: "uz",
    countryCode: "998",
    dialCode: "998",
    format: "+... .. ... .. ..",
    priority: 0,
  },
  {
    name: "Vanuatu",
    regions: ["oceania"],
    iso2: "vu",
    countryCode: "678",
    dialCode: "678",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Vatican City",
    regions: ["europe"],
    iso2: "va",
    countryCode: "39",
    dialCode: "39",
    format: "+.. .. .... ....",
    priority: 1,
  },
  {
    name: "Venezuela",
    regions: ["america", "south-america"],
    iso2: "ve",
    countryCode: "58",
    dialCode: "58",
    format: "+.. ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Vietnam",
    regions: ["asia"],
    iso2: "vn",
    countryCode: "84",
    dialCode: "84",
    format: "+.. ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Yemen",
    regions: ["middle-east"],
    iso2: "ye",
    countryCode: "967",
    dialCode: "967",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Zambia",
    regions: ["africa"],
    iso2: "zm",
    countryCode: "260",
    dialCode: "260",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
  {
    name: "Zimbabwe",
    regions: ["africa"],
    iso2: "zw",
    countryCode: "263",
    dialCode: "263",
    format: "+... ... ... ... ... ..",
    priority: 0,
  },
]

type IProps = {
  phone: string
  setPhone: (val: string) => void
  mandatory?: boolean
  isValid: boolean
  dropdownPlacement?: "top" | "bottom"
  setIsValid: (val: boolean) => void
}

export const PhoneSelect = ({
  phone,
  setPhone,
  isValid,
  setIsValid,
  mandatory = false,
  dropdownPlacement = "bottom",
}: IProps) => {
  const [hideFlag, setHideFlag] = useState(true)

  useEffect(() => {
    const phoneValue = phone.replace("+", "")

    const val = list.some(({ dialCode }) => phoneValue.startsWith(dialCode))
    setHideFlag(!val)
  }, [phone])

  return (
    <Box position="relative">
      {hideFlag && <Box position="absolute" width={28} top={0} bottom={1} bg="#fff" zIndex={1} />}
      <StyledPhone
        dropdownStyle={
          dropdownPlacement === "top"
            ? {
                fontWeight: 400,
                bottom: "100%",
                marginBottom: 0,
              }
            : {
                fontWeight: 400,
              }
        }
        value={phone}
        inputProps={{
          name: "phone",
          required: true,
          ref: (e: any) => {
            if (e?.classList) {
              const _valid = !e.classList.contains("invalid-number")
              if (_valid !== isValid) setIsValid(_valid)
            }
          },
          style: {
            border: "none",
            borderBottom: `1px solid ${phone.trim().length && !isValid ? "#ED5262" : "#cacaca"}`,
            background: "transparent",
            borderRadius: 0,
            width: "100%",
          },
        }}
        isValid={(inputNumber, country: any) => {
          let _valid = false

          if (inputNumber && country) {
            const dialCode = country?.dialCode || ""
            const startWithCode = startsWith(inputNumber, dialCode) || startsWith(dialCode, inputNumber)
            const validPhoneLength = inputNumber.slice(dialCode?.length || 0).length >= 8

            _valid = startWithCode && validPhoneLength
          } else if (mandatory) {
            return false
          }

          return _valid
        }}
        onChange={(value: string) => setPhone(value)}
      />
    </Box>
  )
}
