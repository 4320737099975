/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date with time (isoformat) */
  DateTime: any;
  /** Decimal (fixed-point) */
  Decimal: any;
  UUID: any;
  Upload: any;
};

export enum IAction {
  AnswerPending = 'ANSWER_PENDING',
  Cancelled = 'CANCELLED',
  ComplianceTraining = 'COMPLIANCE_TRAINING',
  InvoicePending = 'INVOICE_PENDING',
  JoinToCall = 'JOIN_TO_CALL',
  NextEngagement = 'NEXT_ENGAGEMENT',
  RequestedAvailability = 'REQUESTED_AVAILABILITY',
  ResponseWaiting = 'RESPONSE_WAITING',
  Scheduled = 'SCHEDULED',
  Scheduling = 'SCHEDULING',
  SignPending = 'SIGN_PENDING',
  SuggestedTimeslots = 'SUGGESTED_TIMESLOTS'
}

export enum IAuthTokenType {
  Auth = 'AUTH',
  Compliance = 'COMPLIANCE',
  ProjectInvitation = 'PROJECT_INVITATION',
  Signup = 'SIGNUP'
}

export type IDocument = {
  __typename?: 'Document';
  alias: Scalars['String'];
  isApproved: Scalars['Boolean'];
  link: Scalars['String'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  version: Scalars['Int'];
};

export type IEmployment = {
  __typename?: 'Employment';
  company: Scalars['String'];
  endMonth?: Maybe<Scalars['Int']>;
  endYear?: Maybe<Scalars['Int']>;
  externalId: Scalars['UUID'];
  location?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  responsibilities?: Maybe<Scalars['String']>;
  startMonth?: Maybe<Scalars['Int']>;
  startYear?: Maybe<Scalars['Int']>;
};

export type IEmploymentPayload = {
  company: Scalars['String'];
  endMonth?: InputMaybe<Scalars['Int']>;
  endYear?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['String']>;
  responsibilities?: InputMaybe<Scalars['String']>;
  startMonth?: InputMaybe<Scalars['Int']>;
  startYear?: InputMaybe<Scalars['Int']>;
};

export type IEngagement = {
  __typename?: 'Engagement';
  details?: Maybe<Scalars['String']>;
  endAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['UUID'];
  joinUrl?: Maybe<Scalars['String']>;
  paymentItem?: Maybe<IPaymentItem>;
  provider: IEngagementProvider;
  startAt?: Maybe<Scalars['DateTime']>;
  status: IEngagementStatus;
  type: IEngagementType;
};

export enum IEngagementProvider {
  NoProvider = 'NO_PROVIDER',
  Phone = 'PHONE',
  Zoom = 'ZOOM'
}

export enum IEngagementStatus {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Live = 'LIVE',
  RequestedAvailability = 'REQUESTED_AVAILABILITY',
  Scheduled = 'SCHEDULED',
  Scheduling = 'SCHEDULING',
  SuggestedTimeslots = 'SUGGESTED_TIMESLOTS'
}

export enum IEngagementType {
  Call = 'CALL',
  Meeting = 'MEETING'
}

export type IExpert = {
  __typename?: 'Expert';
  altEmail?: Maybe<Scalars['String']>;
  altPhone?: Maybe<Scalars['String']>;
  countryAlpha3?: Maybe<Scalars['String']>;
  currency: Scalars['String'];
  cvFilename?: Maybe<Scalars['String']>;
  cvUrl?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  employments: Array<IEmployment>;
  expertise: Array<Scalars['String']>;
  externalId: Scalars['UUID'];
  externalUrl?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  industries: Array<Scalars['String']>;
  lastName: Scalars['String'];
  linkedinUrl?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  rate: Scalars['Decimal'];
  state: IExpertState;
  timezone?: Maybe<Scalars['String']>;
};


export type IExpertEmploymentsArgs = {
  isRecentOnly?: Scalars['Boolean'];
};

export type IExpertPayload = {
  altEmail?: InputMaybe<Scalars['String']>;
  altPhone?: InputMaybe<Scalars['String']>;
  countryAlpha3?: InputMaybe<Scalars['String']>;
  expertise?: InputMaybe<Array<Scalars['String']>>;
  externalUrl?: InputMaybe<Scalars['String']>;
  industries?: InputMaybe<Array<Scalars['String']>>;
  linkedinUrl?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
};

export type IExpertState = {
  __typename?: 'ExpertState';
  authTokenType?: Maybe<IAuthTokenType>;
  deactivationReason?: Maybe<Scalars['String']>;
  employmentsConfirmedAt?: Maybe<Scalars['DateTime']>;
  employmentsUpdatedAt: Scalars['DateTime'];
  isAllDocumentsSigned: Scalars['Boolean'];
  isCompliancePassed: Scalars['Boolean'];
  isDeactivationRequested: Scalars['Boolean'];
  isIamSessionValid: Scalars['Boolean'];
  isPhoneConfirmed: Scalars['Boolean'];
  isRecentEmploymentsConfirmed: Scalars['Boolean'];
  isSignedUp: Scalars['Boolean'];
  isTagsSkipped: Scalars['Boolean'];
};

export type IGeneralResponse = IIGeneralResponse & {
  __typename?: 'GeneralResponse';
  description?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type IIGeneralResponse = {
  description?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type IMutation = {
  __typename?: 'Mutation';
  acceptProject: IGeneralResponse;
  completeComplianceTraining: IGeneralResponse;
  confirmRecentEmployment: IGeneralResponse;
  createEmployment: IGeneralResponse;
  deleteCv: IGeneralResponse;
  rejectProject: IGeneralResponse;
  requestCancelDeactivation: IGeneralResponse;
  requestDeactivation: IGeneralResponse;
  revertProjectDecline: IGeneralResponse;
  signDocument: IGeneralResponse;
  skipExpertTags: IGeneralResponse;
  updateEmployment: IGeneralResponse;
  updateProfile: IGeneralResponse;
  updateProfileTimezone: IGeneralResponse;
  uploadCv: IGeneralResponse;
};


export type IMutationAcceptProjectArgs = {
  answers: Array<IScreeningQuestionAnswer>;
  phone?: InputMaybe<Scalars['String']>;
  projectExternalId: Scalars['UUID'];
};


export type IMutationCreateEmploymentArgs = {
  payload: IEmploymentPayload;
};


export type IMutationRejectProjectArgs = {
  answers: Array<IScreeningQuestionAnswer>;
  projectExternalId: Scalars['UUID'];
};


export type IMutationRequestDeactivationArgs = {
  deactivationReason?: InputMaybe<Scalars['String']>;
};


export type IMutationRevertProjectDeclineArgs = {
  projectExternalId: Scalars['UUID'];
};


export type IMutationSignDocumentArgs = {
  alias: Scalars['String'];
};


export type IMutationUpdateEmploymentArgs = {
  employmentExternalId: Scalars['UUID'];
  payload: IEmploymentPayload;
};


export type IMutationUpdateProfileArgs = {
  payload: IExpertPayload;
};


export type IMutationUpdateProfileTimezoneArgs = {
  timezone: Scalars['String'];
};


export type IMutationUploadCvArgs = {
  file: Scalars['Upload'];
};

export type IPayment = {
  __typename?: 'Payment';
  amount: Scalars['String'];
  currency: Scalars['String'];
  externalId: Scalars['UUID'];
  invoicePublicId?: Maybe<Scalars['String']>;
  items: Array<IPaymentItem>;
  paymentDate: Scalars['DateTime'];
  status: IPaymentStatus;
};

export type IPaymentItem = {
  __typename?: 'PaymentItem';
  amount: Scalars['String'];
  createdAt: Scalars['DateTime'];
  currency: Scalars['String'];
};

export enum IPaymentStatus {
  Failed = 'FAILED',
  InPayment = 'IN_PAYMENT',
  NotPaid = 'NOT_PAID',
  Paid = 'PAID'
}

export type IProject = {
  __typename?: 'Project';
  actions: Array<IProjectAction>;
  currency: Scalars['String'];
  externalId: Scalars['UUID'];
  invitationBrief: Scalars['String'];
  isLive: Scalars['Boolean'];
  name: Scalars['String'];
  rate: Scalars['Decimal'];
  screeningQuestions: Array<IScreeningQuestion>;
  status: IProjectStatus;
  unansweredScreeningQuestions: Scalars['Int'];
};

export type IProjectAction = {
  __typename?: 'ProjectAction';
  action: IAction;
  engagement?: Maybe<IEngagement>;
};

export enum IProjectCategory {
  ActionNeeded = 'ACTION_NEEDED',
  ComingSoon = 'COMING_SOON',
  FollowUp = 'FOLLOW_UP',
  Waiting = 'WAITING'
}

export enum IProjectStatus {
  Accepted = 'ACCEPTED',
  Archived = 'ARCHIVED',
  Pending = 'PENDING',
  Qualified = 'QUALIFIED',
  Rejected = 'REJECTED',
  RejectedByExpert = 'REJECTED_BY_EXPERT'
}

export enum IProjectUpdateEventAction {
  Archived = 'ARCHIVED',
  Created = 'CREATED',
  EngagementsUpdated = 'ENGAGEMENTS_UPDATED',
  Rejected = 'REJECTED',
  Updated = 'UPDATED'
}

export type IProjectUpdatedEvent = {
  __typename?: 'ProjectUpdatedEvent';
  action: IProjectUpdateEventAction;
  projectExternalId: Scalars['UUID'];
};

export type IQuery = {
  __typename?: 'Query';
  documents: Array<IDocument>;
  engagements: Array<IEngagement>;
  expert: IExpert;
  expertises: Array<Scalars['String']>;
  industries: Array<Scalars['String']>;
  payments: Array<IPayment>;
  project?: Maybe<IProject>;
  projects: Array<IProject>;
};


export type IQueryEngagementsArgs = {
  externalId?: InputMaybe<Scalars['UUID']>;
  projectExternalId?: InputMaybe<Scalars['UUID']>;
  provider?: InputMaybe<IEngagementProvider>;
  status?: InputMaybe<IEngagementStatus>;
  type?: InputMaybe<IEngagementType>;
};


export type IQueryPaymentsArgs = {
  externalId?: InputMaybe<Scalars['UUID']>;
  status?: InputMaybe<IPaymentStatus>;
};


export type IQueryProjectArgs = {
  externalId: Scalars['UUID'];
};


export type IQueryProjectsArgs = {
  category: IProjectCategory;
};

export type IScreeningQuestion = {
  __typename?: 'ScreeningQuestion';
  answer?: Maybe<Scalars['String']>;
  answeredAt?: Maybe<Scalars['DateTime']>;
  question: Scalars['String'];
};

export type IScreeningQuestionAnswer = {
  answer: Scalars['String'];
  question: Scalars['String'];
};

export type ISubscription = {
  __typename?: 'Subscription';
  expertEvents: IProjectUpdatedEvent;
};

export type IAcceptProjectMutationVariables = Exact<{
  externalId: Scalars['UUID'];
  answers: Array<IScreeningQuestionAnswer> | IScreeningQuestionAnswer;
  phone?: InputMaybe<Scalars['String']>;
}>;


export type IAcceptProjectMutation = { __typename?: 'Mutation', acceptProject: { __typename?: 'GeneralResponse', success: boolean, description?: string | null } };

export type IRejectProjectMutationVariables = Exact<{
  externalId: Scalars['UUID'];
  answers: Array<IScreeningQuestionAnswer> | IScreeningQuestionAnswer;
}>;


export type IRejectProjectMutation = { __typename?: 'Mutation', rejectProject: { __typename?: 'GeneralResponse', success: boolean, description?: string | null } };

export type ISkipExpertTagsMutationVariables = Exact<{ [key: string]: never; }>;


export type ISkipExpertTagsMutation = { __typename?: 'Mutation', skipExpertTags: { __typename?: 'GeneralResponse', success: boolean, description?: string | null } };

export type IUpdatePhoneMutationVariables = Exact<{
  phone: Scalars['String'];
}>;


export type IUpdatePhoneMutation = { __typename?: 'Mutation', updateProfile: { __typename?: 'GeneralResponse', success: boolean, description?: string | null } };

export type IUpdateProfileMutationVariables = Exact<{
  payload: IExpertPayload;
}>;


export type IUpdateProfileMutation = { __typename?: 'Mutation', updateProfile: { __typename?: 'GeneralResponse', success: boolean, description?: string | null } };

export type IUpdateProfileTimezoneMutationVariables = Exact<{
  timezone: Scalars['String'];
}>;


export type IUpdateProfileTimezoneMutation = { __typename?: 'Mutation', updateProfileTimezone: { __typename?: 'GeneralResponse', success: boolean, description?: string | null } };

export type IMeQueryVariables = Exact<{ [key: string]: never; }>;


export type IMeQuery = { __typename?: 'Query', expert: { __typename?: 'Expert', email: string, altEmail?: string | null, firstName: string, lastName: string, timezone?: string | null, industries: Array<string>, expertise: Array<string>, phone?: string | null, altPhone?: string | null, linkedinUrl?: string | null, externalUrl?: string | null, countryAlpha3?: string | null, cvUrl?: string | null, cvFilename?: string | null, state: { __typename?: 'ExpertState', authTokenType?: IAuthTokenType | null, isSignedUp: boolean, isPhoneConfirmed: boolean, isCompliancePassed: boolean, isAllDocumentsSigned: boolean, isDeactivationRequested: boolean, isRecentEmploymentsConfirmed: boolean, employmentsUpdatedAt: any, employmentsConfirmedAt?: any | null, isTagsSkipped: boolean, isIamSessionValid: boolean } } };

export type IProjectQueryVariables = Exact<{
  externalId: Scalars['UUID'];
}>;


export type IProjectQuery = { __typename?: 'Query', project?: { __typename?: 'Project', name: string, status: IProjectStatus, invitationBrief: string, isLive: boolean, screeningQuestions: Array<{ __typename?: 'ScreeningQuestion', question: string, answer?: string | null }> } | null };

export type ICompleteComplianceTrainingMutationVariables = Exact<{ [key: string]: never; }>;


export type ICompleteComplianceTrainingMutation = { __typename?: 'Mutation', completeComplianceTraining: { __typename?: 'GeneralResponse', success: boolean, description?: string | null } };

export type IDocumentsQueryVariables = Exact<{ [key: string]: never; }>;


export type IDocumentsQuery = { __typename?: 'Query', documents: Array<{ __typename?: 'Document', name: string, alias: string, isApproved: boolean, link: string }> };

export type ISignDocumentMutationVariables = Exact<{
  alias: Scalars['String'];
}>;


export type ISignDocumentMutation = { __typename?: 'Mutation', signDocument: { __typename?: 'GeneralResponse', success: boolean, description?: string | null } };

export type IPaymentsQueryVariables = Exact<{ [key: string]: never; }>;


export type IPaymentsQuery = { __typename?: 'Query', payments: Array<{ __typename?: 'Payment', externalId: any, status: IPaymentStatus, amount: string, currency: string, paymentDate: any, invoicePublicId?: string | null }> };

export type IDeleteCvMutationVariables = Exact<{ [key: string]: never; }>;


export type IDeleteCvMutation = { __typename?: 'Mutation', deleteCv: { __typename?: 'GeneralResponse', success: boolean, description?: string | null } };

export type IExpertisesQueryVariables = Exact<{ [key: string]: never; }>;


export type IExpertisesQuery = { __typename?: 'Query', expertises: Array<string> };

export type IIndustriesQueryVariables = Exact<{ [key: string]: never; }>;


export type IIndustriesQuery = { __typename?: 'Query', industries: Array<string> };

export type IRequestCancelDeactivationMutationVariables = Exact<{ [key: string]: never; }>;


export type IRequestCancelDeactivationMutation = { __typename?: 'Mutation', requestCancelDeactivation: { __typename?: 'GeneralResponse', success: boolean, description?: string | null } };

export type IRequestDeactivationMutationVariables = Exact<{ [key: string]: never; }>;


export type IRequestDeactivationMutation = { __typename?: 'Mutation', requestDeactivation: { __typename?: 'GeneralResponse', success: boolean, description?: string | null } };

export type IUploadCvMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;


export type IUploadCvMutation = { __typename?: 'Mutation', uploadCv: { __typename?: 'GeneralResponse', success: boolean, description?: string | null } };

export type IConfirmRecentEmploymentMutationVariables = Exact<{ [key: string]: never; }>;


export type IConfirmRecentEmploymentMutation = { __typename?: 'Mutation', confirmRecentEmployment: { __typename?: 'GeneralResponse', success: boolean, description?: string | null } };

export type ICreateEmploymentMutationVariables = Exact<{
  payload: IEmploymentPayload;
}>;


export type ICreateEmploymentMutation = { __typename?: 'Mutation', createEmployment: { __typename?: 'GeneralResponse', success: boolean, description?: string | null } };

export type IEmploymentsQueryVariables = Exact<{
  isRecentOnly?: InputMaybe<Scalars['Boolean']>;
}>;


export type IEmploymentsQuery = { __typename?: 'Query', expert: { __typename?: 'Expert', employments: Array<{ __typename?: 'Employment', externalId: any, company: string, position?: string | null, responsibilities?: string | null, location?: string | null, startMonth?: number | null, startYear?: number | null, endMonth?: number | null, endYear?: number | null }> } };

export type IUpdateEmploymentMutationVariables = Exact<{
  employmentExternalId: Scalars['UUID'];
  payload: IEmploymentPayload;
}>;


export type IUpdateEmploymentMutation = { __typename?: 'Mutation', updateEmployment: { __typename?: 'GeneralResponse', success: boolean, description?: string | null } };

export type IExpertEventsSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type IExpertEventsSubscription = { __typename?: 'Subscription', expertEvents: { __typename?: 'ProjectUpdatedEvent', projectExternalId: any, action: IProjectUpdateEventAction } };

export type IProjectsQueryVariables = Exact<{
  category: IProjectCategory;
}>;


export type IProjectsQuery = { __typename?: 'Query', projects: Array<{ __typename?: 'Project', externalId: any, status: IProjectStatus, name: string, invitationBrief: string, screeningQuestions: Array<{ __typename?: 'ScreeningQuestion', question: string }>, actions: Array<{ __typename?: 'ProjectAction', action: IAction, engagement?: { __typename?: 'Engagement', externalId: any, status: IEngagementStatus, provider: IEngagementProvider, joinUrl?: string | null, details?: string | null, startAt?: any | null, endAt?: any | null, type: IEngagementType } | null }> }> };

export type IRevertProjectDeclineMutationVariables = Exact<{
  externalId: Scalars['UUID'];
}>;


export type IRevertProjectDeclineMutation = { __typename?: 'Mutation', revertProjectDecline: { __typename?: 'GeneralResponse', success: boolean, description?: string | null } };


export const AcceptProjectDocument = gql`
    mutation AcceptProject($externalId: UUID!, $answers: [ScreeningQuestionAnswer!]!, $phone: String) {
  acceptProject(projectExternalId: $externalId, answers: $answers, phone: $phone) {
    success
    description
  }
}
    `;
export type IAcceptProjectMutationFn = Apollo.MutationFunction<IAcceptProjectMutation, IAcceptProjectMutationVariables>;

/**
 * __useAcceptProjectMutation__
 *
 * To run a mutation, you first call `useAcceptProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptProjectMutation, { data, loading, error }] = useAcceptProjectMutation({
 *   variables: {
 *      externalId: // value for 'externalId'
 *      answers: // value for 'answers'
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useAcceptProjectMutation(baseOptions?: Apollo.MutationHookOptions<IAcceptProjectMutation, IAcceptProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IAcceptProjectMutation, IAcceptProjectMutationVariables>(AcceptProjectDocument, options);
      }
export type AcceptProjectMutationHookResult = ReturnType<typeof useAcceptProjectMutation>;
export type AcceptProjectMutationResult = Apollo.MutationResult<IAcceptProjectMutation>;
export type AcceptProjectMutationOptions = Apollo.BaseMutationOptions<IAcceptProjectMutation, IAcceptProjectMutationVariables>;
export const RejectProjectDocument = gql`
    mutation RejectProject($externalId: UUID!, $answers: [ScreeningQuestionAnswer!]!) {
  rejectProject(projectExternalId: $externalId, answers: $answers) {
    success
    description
  }
}
    `;
export type IRejectProjectMutationFn = Apollo.MutationFunction<IRejectProjectMutation, IRejectProjectMutationVariables>;

/**
 * __useRejectProjectMutation__
 *
 * To run a mutation, you first call `useRejectProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectProjectMutation, { data, loading, error }] = useRejectProjectMutation({
 *   variables: {
 *      externalId: // value for 'externalId'
 *      answers: // value for 'answers'
 *   },
 * });
 */
export function useRejectProjectMutation(baseOptions?: Apollo.MutationHookOptions<IRejectProjectMutation, IRejectProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IRejectProjectMutation, IRejectProjectMutationVariables>(RejectProjectDocument, options);
      }
export type RejectProjectMutationHookResult = ReturnType<typeof useRejectProjectMutation>;
export type RejectProjectMutationResult = Apollo.MutationResult<IRejectProjectMutation>;
export type RejectProjectMutationOptions = Apollo.BaseMutationOptions<IRejectProjectMutation, IRejectProjectMutationVariables>;
export const SkipExpertTagsDocument = gql`
    mutation SkipExpertTags {
  skipExpertTags {
    success
    description
  }
}
    `;
export type ISkipExpertTagsMutationFn = Apollo.MutationFunction<ISkipExpertTagsMutation, ISkipExpertTagsMutationVariables>;

/**
 * __useSkipExpertTagsMutation__
 *
 * To run a mutation, you first call `useSkipExpertTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSkipExpertTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [skipExpertTagsMutation, { data, loading, error }] = useSkipExpertTagsMutation({
 *   variables: {
 *   },
 * });
 */
export function useSkipExpertTagsMutation(baseOptions?: Apollo.MutationHookOptions<ISkipExpertTagsMutation, ISkipExpertTagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ISkipExpertTagsMutation, ISkipExpertTagsMutationVariables>(SkipExpertTagsDocument, options);
      }
export type SkipExpertTagsMutationHookResult = ReturnType<typeof useSkipExpertTagsMutation>;
export type SkipExpertTagsMutationResult = Apollo.MutationResult<ISkipExpertTagsMutation>;
export type SkipExpertTagsMutationOptions = Apollo.BaseMutationOptions<ISkipExpertTagsMutation, ISkipExpertTagsMutationVariables>;
export const UpdatePhoneDocument = gql`
    mutation UpdatePhone($phone: String!) {
  updateProfile(payload: {phone: $phone}) {
    success
    description
  }
}
    `;
export type IUpdatePhoneMutationFn = Apollo.MutationFunction<IUpdatePhoneMutation, IUpdatePhoneMutationVariables>;

/**
 * __useUpdatePhoneMutation__
 *
 * To run a mutation, you first call `useUpdatePhoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePhoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePhoneMutation, { data, loading, error }] = useUpdatePhoneMutation({
 *   variables: {
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useUpdatePhoneMutation(baseOptions?: Apollo.MutationHookOptions<IUpdatePhoneMutation, IUpdatePhoneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IUpdatePhoneMutation, IUpdatePhoneMutationVariables>(UpdatePhoneDocument, options);
      }
export type UpdatePhoneMutationHookResult = ReturnType<typeof useUpdatePhoneMutation>;
export type UpdatePhoneMutationResult = Apollo.MutationResult<IUpdatePhoneMutation>;
export type UpdatePhoneMutationOptions = Apollo.BaseMutationOptions<IUpdatePhoneMutation, IUpdatePhoneMutationVariables>;
export const UpdateProfileDocument = gql`
    mutation UpdateProfile($payload: ExpertPayload!) {
  updateProfile(payload: $payload) {
    success
    description
  }
}
    `;
export type IUpdateProfileMutationFn = Apollo.MutationFunction<IUpdateProfileMutation, IUpdateProfileMutationVariables>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpdateProfileMutation(baseOptions?: Apollo.MutationHookOptions<IUpdateProfileMutation, IUpdateProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IUpdateProfileMutation, IUpdateProfileMutationVariables>(UpdateProfileDocument, options);
      }
export type UpdateProfileMutationHookResult = ReturnType<typeof useUpdateProfileMutation>;
export type UpdateProfileMutationResult = Apollo.MutationResult<IUpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<IUpdateProfileMutation, IUpdateProfileMutationVariables>;
export const UpdateProfileTimezoneDocument = gql`
    mutation UpdateProfileTimezone($timezone: String!) {
  updateProfileTimezone(timezone: $timezone) {
    success
    description
  }
}
    `;
export type IUpdateProfileTimezoneMutationFn = Apollo.MutationFunction<IUpdateProfileTimezoneMutation, IUpdateProfileTimezoneMutationVariables>;

/**
 * __useUpdateProfileTimezoneMutation__
 *
 * To run a mutation, you first call `useUpdateProfileTimezoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileTimezoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileTimezoneMutation, { data, loading, error }] = useUpdateProfileTimezoneMutation({
 *   variables: {
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useUpdateProfileTimezoneMutation(baseOptions?: Apollo.MutationHookOptions<IUpdateProfileTimezoneMutation, IUpdateProfileTimezoneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IUpdateProfileTimezoneMutation, IUpdateProfileTimezoneMutationVariables>(UpdateProfileTimezoneDocument, options);
      }
export type UpdateProfileTimezoneMutationHookResult = ReturnType<typeof useUpdateProfileTimezoneMutation>;
export type UpdateProfileTimezoneMutationResult = Apollo.MutationResult<IUpdateProfileTimezoneMutation>;
export type UpdateProfileTimezoneMutationOptions = Apollo.BaseMutationOptions<IUpdateProfileTimezoneMutation, IUpdateProfileTimezoneMutationVariables>;
export const MeDocument = gql`
    query Me {
  expert {
    email
    altEmail
    firstName
    lastName
    timezone
    industries
    expertise
    phone
    altPhone
    linkedinUrl
    externalUrl
    countryAlpha3
    cvUrl
    cvFilename
    state {
      authTokenType
      isSignedUp
      isPhoneConfirmed
      isCompliancePassed
      isAllDocumentsSigned
      isDeactivationRequested
      isRecentEmploymentsConfirmed
      employmentsUpdatedAt
      employmentsConfirmedAt
      isTagsSkipped
      isIamSessionValid
    }
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<IMeQuery, IMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IMeQuery, IMeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IMeQuery, IMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IMeQuery, IMeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<IMeQuery, IMeQueryVariables>;
export const ProjectDocument = gql`
    query Project($externalId: UUID!) {
  project(externalId: $externalId) {
    name
    status
    invitationBrief
    isLive
    screeningQuestions {
      question
      answer
    }
  }
}
    `;

/**
 * __useProjectQuery__
 *
 * To run a query within a React component, call `useProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectQuery({
 *   variables: {
 *      externalId: // value for 'externalId'
 *   },
 * });
 */
export function useProjectQuery(baseOptions: Apollo.QueryHookOptions<IProjectQuery, IProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IProjectQuery, IProjectQueryVariables>(ProjectDocument, options);
      }
export function useProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IProjectQuery, IProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IProjectQuery, IProjectQueryVariables>(ProjectDocument, options);
        }
export type ProjectQueryHookResult = ReturnType<typeof useProjectQuery>;
export type ProjectLazyQueryHookResult = ReturnType<typeof useProjectLazyQuery>;
export type ProjectQueryResult = Apollo.QueryResult<IProjectQuery, IProjectQueryVariables>;
export const CompleteComplianceTrainingDocument = gql`
    mutation CompleteComplianceTraining {
  completeComplianceTraining {
    success
    description
  }
}
    `;
export type ICompleteComplianceTrainingMutationFn = Apollo.MutationFunction<ICompleteComplianceTrainingMutation, ICompleteComplianceTrainingMutationVariables>;

/**
 * __useCompleteComplianceTrainingMutation__
 *
 * To run a mutation, you first call `useCompleteComplianceTrainingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteComplianceTrainingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeComplianceTrainingMutation, { data, loading, error }] = useCompleteComplianceTrainingMutation({
 *   variables: {
 *   },
 * });
 */
export function useCompleteComplianceTrainingMutation(baseOptions?: Apollo.MutationHookOptions<ICompleteComplianceTrainingMutation, ICompleteComplianceTrainingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ICompleteComplianceTrainingMutation, ICompleteComplianceTrainingMutationVariables>(CompleteComplianceTrainingDocument, options);
      }
export type CompleteComplianceTrainingMutationHookResult = ReturnType<typeof useCompleteComplianceTrainingMutation>;
export type CompleteComplianceTrainingMutationResult = Apollo.MutationResult<ICompleteComplianceTrainingMutation>;
export type CompleteComplianceTrainingMutationOptions = Apollo.BaseMutationOptions<ICompleteComplianceTrainingMutation, ICompleteComplianceTrainingMutationVariables>;
export const DocumentsDocument = gql`
    query Documents {
  documents {
    name
    alias
    isApproved
    link
  }
}
    `;

/**
 * __useDocumentsQuery__
 *
 * To run a query within a React component, call `useDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDocumentsQuery(baseOptions?: Apollo.QueryHookOptions<IDocumentsQuery, IDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IDocumentsQuery, IDocumentsQueryVariables>(DocumentsDocument, options);
      }
export function useDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IDocumentsQuery, IDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IDocumentsQuery, IDocumentsQueryVariables>(DocumentsDocument, options);
        }
export type DocumentsQueryHookResult = ReturnType<typeof useDocumentsQuery>;
export type DocumentsLazyQueryHookResult = ReturnType<typeof useDocumentsLazyQuery>;
export type DocumentsQueryResult = Apollo.QueryResult<IDocumentsQuery, IDocumentsQueryVariables>;
export const SignDocumentDocument = gql`
    mutation SignDocument($alias: String!) {
  signDocument(alias: $alias) {
    success
    description
  }
}
    `;
export type ISignDocumentMutationFn = Apollo.MutationFunction<ISignDocumentMutation, ISignDocumentMutationVariables>;

/**
 * __useSignDocumentMutation__
 *
 * To run a mutation, you first call `useSignDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signDocumentMutation, { data, loading, error }] = useSignDocumentMutation({
 *   variables: {
 *      alias: // value for 'alias'
 *   },
 * });
 */
export function useSignDocumentMutation(baseOptions?: Apollo.MutationHookOptions<ISignDocumentMutation, ISignDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ISignDocumentMutation, ISignDocumentMutationVariables>(SignDocumentDocument, options);
      }
export type SignDocumentMutationHookResult = ReturnType<typeof useSignDocumentMutation>;
export type SignDocumentMutationResult = Apollo.MutationResult<ISignDocumentMutation>;
export type SignDocumentMutationOptions = Apollo.BaseMutationOptions<ISignDocumentMutation, ISignDocumentMutationVariables>;
export const PaymentsDocument = gql`
    query Payments {
  payments {
    externalId
    status
    amount
    currency
    paymentDate
    invoicePublicId
  }
}
    `;

/**
 * __usePaymentsQuery__
 *
 * To run a query within a React component, call `usePaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePaymentsQuery(baseOptions?: Apollo.QueryHookOptions<IPaymentsQuery, IPaymentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IPaymentsQuery, IPaymentsQueryVariables>(PaymentsDocument, options);
      }
export function usePaymentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IPaymentsQuery, IPaymentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IPaymentsQuery, IPaymentsQueryVariables>(PaymentsDocument, options);
        }
export type PaymentsQueryHookResult = ReturnType<typeof usePaymentsQuery>;
export type PaymentsLazyQueryHookResult = ReturnType<typeof usePaymentsLazyQuery>;
export type PaymentsQueryResult = Apollo.QueryResult<IPaymentsQuery, IPaymentsQueryVariables>;
export const DeleteCvDocument = gql`
    mutation DeleteCv {
  deleteCv {
    success
    description
  }
}
    `;
export type IDeleteCvMutationFn = Apollo.MutationFunction<IDeleteCvMutation, IDeleteCvMutationVariables>;

/**
 * __useDeleteCvMutation__
 *
 * To run a mutation, you first call `useDeleteCvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCvMutation, { data, loading, error }] = useDeleteCvMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteCvMutation(baseOptions?: Apollo.MutationHookOptions<IDeleteCvMutation, IDeleteCvMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IDeleteCvMutation, IDeleteCvMutationVariables>(DeleteCvDocument, options);
      }
export type DeleteCvMutationHookResult = ReturnType<typeof useDeleteCvMutation>;
export type DeleteCvMutationResult = Apollo.MutationResult<IDeleteCvMutation>;
export type DeleteCvMutationOptions = Apollo.BaseMutationOptions<IDeleteCvMutation, IDeleteCvMutationVariables>;
export const ExpertisesDocument = gql`
    query Expertises {
  expertises
}
    `;

/**
 * __useExpertisesQuery__
 *
 * To run a query within a React component, call `useExpertisesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpertisesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpertisesQuery({
 *   variables: {
 *   },
 * });
 */
export function useExpertisesQuery(baseOptions?: Apollo.QueryHookOptions<IExpertisesQuery, IExpertisesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IExpertisesQuery, IExpertisesQueryVariables>(ExpertisesDocument, options);
      }
export function useExpertisesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IExpertisesQuery, IExpertisesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IExpertisesQuery, IExpertisesQueryVariables>(ExpertisesDocument, options);
        }
export type ExpertisesQueryHookResult = ReturnType<typeof useExpertisesQuery>;
export type ExpertisesLazyQueryHookResult = ReturnType<typeof useExpertisesLazyQuery>;
export type ExpertisesQueryResult = Apollo.QueryResult<IExpertisesQuery, IExpertisesQueryVariables>;
export const IndustriesDocument = gql`
    query Industries {
  industries
}
    `;

/**
 * __useIndustriesQuery__
 *
 * To run a query within a React component, call `useIndustriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndustriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndustriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useIndustriesQuery(baseOptions?: Apollo.QueryHookOptions<IIndustriesQuery, IIndustriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IIndustriesQuery, IIndustriesQueryVariables>(IndustriesDocument, options);
      }
export function useIndustriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IIndustriesQuery, IIndustriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IIndustriesQuery, IIndustriesQueryVariables>(IndustriesDocument, options);
        }
export type IndustriesQueryHookResult = ReturnType<typeof useIndustriesQuery>;
export type IndustriesLazyQueryHookResult = ReturnType<typeof useIndustriesLazyQuery>;
export type IndustriesQueryResult = Apollo.QueryResult<IIndustriesQuery, IIndustriesQueryVariables>;
export const RequestCancelDeactivationDocument = gql`
    mutation RequestCancelDeactivation {
  requestCancelDeactivation {
    success
    description
  }
}
    `;
export type IRequestCancelDeactivationMutationFn = Apollo.MutationFunction<IRequestCancelDeactivationMutation, IRequestCancelDeactivationMutationVariables>;

/**
 * __useRequestCancelDeactivationMutation__
 *
 * To run a mutation, you first call `useRequestCancelDeactivationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestCancelDeactivationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestCancelDeactivationMutation, { data, loading, error }] = useRequestCancelDeactivationMutation({
 *   variables: {
 *   },
 * });
 */
export function useRequestCancelDeactivationMutation(baseOptions?: Apollo.MutationHookOptions<IRequestCancelDeactivationMutation, IRequestCancelDeactivationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IRequestCancelDeactivationMutation, IRequestCancelDeactivationMutationVariables>(RequestCancelDeactivationDocument, options);
      }
export type RequestCancelDeactivationMutationHookResult = ReturnType<typeof useRequestCancelDeactivationMutation>;
export type RequestCancelDeactivationMutationResult = Apollo.MutationResult<IRequestCancelDeactivationMutation>;
export type RequestCancelDeactivationMutationOptions = Apollo.BaseMutationOptions<IRequestCancelDeactivationMutation, IRequestCancelDeactivationMutationVariables>;
export const RequestDeactivationDocument = gql`
    mutation RequestDeactivation {
  requestDeactivation {
    success
    description
  }
}
    `;
export type IRequestDeactivationMutationFn = Apollo.MutationFunction<IRequestDeactivationMutation, IRequestDeactivationMutationVariables>;

/**
 * __useRequestDeactivationMutation__
 *
 * To run a mutation, you first call `useRequestDeactivationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestDeactivationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestDeactivationMutation, { data, loading, error }] = useRequestDeactivationMutation({
 *   variables: {
 *   },
 * });
 */
export function useRequestDeactivationMutation(baseOptions?: Apollo.MutationHookOptions<IRequestDeactivationMutation, IRequestDeactivationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IRequestDeactivationMutation, IRequestDeactivationMutationVariables>(RequestDeactivationDocument, options);
      }
export type RequestDeactivationMutationHookResult = ReturnType<typeof useRequestDeactivationMutation>;
export type RequestDeactivationMutationResult = Apollo.MutationResult<IRequestDeactivationMutation>;
export type RequestDeactivationMutationOptions = Apollo.BaseMutationOptions<IRequestDeactivationMutation, IRequestDeactivationMutationVariables>;
export const UploadCvDocument = gql`
    mutation UploadCv($file: Upload!) {
  uploadCv(file: $file) {
    success
    description
  }
}
    `;
export type IUploadCvMutationFn = Apollo.MutationFunction<IUploadCvMutation, IUploadCvMutationVariables>;

/**
 * __useUploadCvMutation__
 *
 * To run a mutation, you first call `useUploadCvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadCvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadCvMutation, { data, loading, error }] = useUploadCvMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadCvMutation(baseOptions?: Apollo.MutationHookOptions<IUploadCvMutation, IUploadCvMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IUploadCvMutation, IUploadCvMutationVariables>(UploadCvDocument, options);
      }
export type UploadCvMutationHookResult = ReturnType<typeof useUploadCvMutation>;
export type UploadCvMutationResult = Apollo.MutationResult<IUploadCvMutation>;
export type UploadCvMutationOptions = Apollo.BaseMutationOptions<IUploadCvMutation, IUploadCvMutationVariables>;
export const ConfirmRecentEmploymentDocument = gql`
    mutation ConfirmRecentEmployment {
  confirmRecentEmployment {
    success
    description
  }
}
    `;
export type IConfirmRecentEmploymentMutationFn = Apollo.MutationFunction<IConfirmRecentEmploymentMutation, IConfirmRecentEmploymentMutationVariables>;

/**
 * __useConfirmRecentEmploymentMutation__
 *
 * To run a mutation, you first call `useConfirmRecentEmploymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmRecentEmploymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmRecentEmploymentMutation, { data, loading, error }] = useConfirmRecentEmploymentMutation({
 *   variables: {
 *   },
 * });
 */
export function useConfirmRecentEmploymentMutation(baseOptions?: Apollo.MutationHookOptions<IConfirmRecentEmploymentMutation, IConfirmRecentEmploymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IConfirmRecentEmploymentMutation, IConfirmRecentEmploymentMutationVariables>(ConfirmRecentEmploymentDocument, options);
      }
export type ConfirmRecentEmploymentMutationHookResult = ReturnType<typeof useConfirmRecentEmploymentMutation>;
export type ConfirmRecentEmploymentMutationResult = Apollo.MutationResult<IConfirmRecentEmploymentMutation>;
export type ConfirmRecentEmploymentMutationOptions = Apollo.BaseMutationOptions<IConfirmRecentEmploymentMutation, IConfirmRecentEmploymentMutationVariables>;
export const CreateEmploymentDocument = gql`
    mutation CreateEmployment($payload: EmploymentPayload!) {
  createEmployment(payload: $payload) {
    success
    description
  }
}
    `;
export type ICreateEmploymentMutationFn = Apollo.MutationFunction<ICreateEmploymentMutation, ICreateEmploymentMutationVariables>;

/**
 * __useCreateEmploymentMutation__
 *
 * To run a mutation, you first call `useCreateEmploymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmploymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmploymentMutation, { data, loading, error }] = useCreateEmploymentMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useCreateEmploymentMutation(baseOptions?: Apollo.MutationHookOptions<ICreateEmploymentMutation, ICreateEmploymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ICreateEmploymentMutation, ICreateEmploymentMutationVariables>(CreateEmploymentDocument, options);
      }
export type CreateEmploymentMutationHookResult = ReturnType<typeof useCreateEmploymentMutation>;
export type CreateEmploymentMutationResult = Apollo.MutationResult<ICreateEmploymentMutation>;
export type CreateEmploymentMutationOptions = Apollo.BaseMutationOptions<ICreateEmploymentMutation, ICreateEmploymentMutationVariables>;
export const EmploymentsDocument = gql`
    query Employments($isRecentOnly: Boolean = false) {
  expert {
    employments(isRecentOnly: $isRecentOnly) {
      externalId
      company
      position
      responsibilities
      location
      startMonth
      startYear
      endMonth
      endYear
    }
  }
}
    `;

/**
 * __useEmploymentsQuery__
 *
 * To run a query within a React component, call `useEmploymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmploymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmploymentsQuery({
 *   variables: {
 *      isRecentOnly: // value for 'isRecentOnly'
 *   },
 * });
 */
export function useEmploymentsQuery(baseOptions?: Apollo.QueryHookOptions<IEmploymentsQuery, IEmploymentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IEmploymentsQuery, IEmploymentsQueryVariables>(EmploymentsDocument, options);
      }
export function useEmploymentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IEmploymentsQuery, IEmploymentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IEmploymentsQuery, IEmploymentsQueryVariables>(EmploymentsDocument, options);
        }
export type EmploymentsQueryHookResult = ReturnType<typeof useEmploymentsQuery>;
export type EmploymentsLazyQueryHookResult = ReturnType<typeof useEmploymentsLazyQuery>;
export type EmploymentsQueryResult = Apollo.QueryResult<IEmploymentsQuery, IEmploymentsQueryVariables>;
export const UpdateEmploymentDocument = gql`
    mutation UpdateEmployment($employmentExternalId: UUID!, $payload: EmploymentPayload!) {
  updateEmployment(employmentExternalId: $employmentExternalId, payload: $payload) {
    success
    description
  }
}
    `;
export type IUpdateEmploymentMutationFn = Apollo.MutationFunction<IUpdateEmploymentMutation, IUpdateEmploymentMutationVariables>;

/**
 * __useUpdateEmploymentMutation__
 *
 * To run a mutation, you first call `useUpdateEmploymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmploymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmploymentMutation, { data, loading, error }] = useUpdateEmploymentMutation({
 *   variables: {
 *      employmentExternalId: // value for 'employmentExternalId'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpdateEmploymentMutation(baseOptions?: Apollo.MutationHookOptions<IUpdateEmploymentMutation, IUpdateEmploymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IUpdateEmploymentMutation, IUpdateEmploymentMutationVariables>(UpdateEmploymentDocument, options);
      }
export type UpdateEmploymentMutationHookResult = ReturnType<typeof useUpdateEmploymentMutation>;
export type UpdateEmploymentMutationResult = Apollo.MutationResult<IUpdateEmploymentMutation>;
export type UpdateEmploymentMutationOptions = Apollo.BaseMutationOptions<IUpdateEmploymentMutation, IUpdateEmploymentMutationVariables>;
export const ExpertEventsDocument = gql`
    subscription expertEvents {
  expertEvents {
    projectExternalId
    action
  }
}
    `;

/**
 * __useExpertEventsSubscription__
 *
 * To run a query within a React component, call `useExpertEventsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useExpertEventsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpertEventsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useExpertEventsSubscription(baseOptions?: Apollo.SubscriptionHookOptions<IExpertEventsSubscription, IExpertEventsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<IExpertEventsSubscription, IExpertEventsSubscriptionVariables>(ExpertEventsDocument, options);
      }
export type ExpertEventsSubscriptionHookResult = ReturnType<typeof useExpertEventsSubscription>;
export type ExpertEventsSubscriptionResult = Apollo.SubscriptionResult<IExpertEventsSubscription>;
export const ProjectsDocument = gql`
    query Projects($category: ProjectCategory!) {
  projects(category: $category) {
    externalId
    status
    name
    invitationBrief
    screeningQuestions {
      question
    }
    actions {
      action
      engagement {
        externalId
        status
        provider
        joinUrl
        details
        startAt
        endAt
        type
      }
    }
  }
}
    `;

/**
 * __useProjectsQuery__
 *
 * To run a query within a React component, call `useProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsQuery({
 *   variables: {
 *      category: // value for 'category'
 *   },
 * });
 */
export function useProjectsQuery(baseOptions: Apollo.QueryHookOptions<IProjectsQuery, IProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IProjectsQuery, IProjectsQueryVariables>(ProjectsDocument, options);
      }
export function useProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IProjectsQuery, IProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IProjectsQuery, IProjectsQueryVariables>(ProjectsDocument, options);
        }
export type ProjectsQueryHookResult = ReturnType<typeof useProjectsQuery>;
export type ProjectsLazyQueryHookResult = ReturnType<typeof useProjectsLazyQuery>;
export type ProjectsQueryResult = Apollo.QueryResult<IProjectsQuery, IProjectsQueryVariables>;
export const RevertProjectDeclineDocument = gql`
    mutation RevertProjectDecline($externalId: UUID!) {
  revertProjectDecline(projectExternalId: $externalId) {
    success
    description
  }
}
    `;
export type IRevertProjectDeclineMutationFn = Apollo.MutationFunction<IRevertProjectDeclineMutation, IRevertProjectDeclineMutationVariables>;

/**
 * __useRevertProjectDeclineMutation__
 *
 * To run a mutation, you first call `useRevertProjectDeclineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevertProjectDeclineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revertProjectDeclineMutation, { data, loading, error }] = useRevertProjectDeclineMutation({
 *   variables: {
 *      externalId: // value for 'externalId'
 *   },
 * });
 */
export function useRevertProjectDeclineMutation(baseOptions?: Apollo.MutationHookOptions<IRevertProjectDeclineMutation, IRevertProjectDeclineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IRevertProjectDeclineMutation, IRevertProjectDeclineMutationVariables>(RevertProjectDeclineDocument, options);
      }
export type RevertProjectDeclineMutationHookResult = ReturnType<typeof useRevertProjectDeclineMutation>;
export type RevertProjectDeclineMutationResult = Apollo.MutationResult<IRevertProjectDeclineMutation>;
export type RevertProjectDeclineMutationOptions = Apollo.BaseMutationOptions<IRevertProjectDeclineMutation, IRevertProjectDeclineMutationVariables>;