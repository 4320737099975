type SESSION_STORAGE_KEY = "goTo"

const get = (key: SESSION_STORAGE_KEY): string | null => {
  return window.sessionStorage.getItem(key)
}

const set = (key: SESSION_STORAGE_KEY, value: string): void => {
  window.sessionStorage.setItem(key, value)
}

const remove = (key: SESSION_STORAGE_KEY): void => {
  window.sessionStorage.removeItem(key)
}

export const sessionStorage = {
  get,
  set,
  remove,
}
