import { SelectOption } from "@prosapient/prosapient-styleguide"
import { GroupBase } from "react-select"
import { FormCheckbox } from "shared/form-bindings-new/Checkbox"
import { FormDatepicker, FormDaterange } from "shared/form-bindings-new/Datepicker"
import { FormInput, FormInputProps } from "shared/form-bindings-new/Input"
import { FormPhoneInput } from "shared/form-bindings-new/PhoneInput"
import { FormRadio, FormYesNoRadio } from "shared/form-bindings-new/Radio"
import { FormRichTextEditor } from "shared/form-bindings-new/RichTextEditor"
import {
  FormAutocompleteSelect,
  FormAsyncCreatableSelect,
  FormAsyncSelect,
  FormCreatableSelect,
  FormSelect,
  FormSelectWrapper,
  FormAutocompleteSelectProps,
  FormAsyncCreatableSelectProps,
  FormAsyncSelectProps,
  FormCreatableSelectProps,
  FormSelectProps,
} from "shared/form-bindings-new/Select"
import { FormTextarea, FormTextareaProps } from "shared/form-bindings-new/Textarea"
import { FormTimepicker } from "shared/form-bindings-new/Timepicker"
import { FormToggle } from "shared/form-bindings-new/Toggle"
import { FormBindingsProps } from "shared/form-bindings-new/types"
import { isFormFieldInvalid, omitFormFieldConfigProperties } from "shared/form-bindings-new/utils"
import { StyledFormLabel } from "shared/form-bindings-new/Label"
import { FormExpertisesSelect } from "shared/selects/ExpertisesSelect"
import { FormIndustriesSelect } from "shared/selects/IndustriesSelect"

// eslint-disable-next-line
export namespace FormBindings {
  export type TextareaProps = FormTextareaProps
  export type InputProps = FormInputProps
  export type AutocompleteSelectProps<
    Option extends SelectOption<unknown> = SelectOption<unknown>
  > = FormAutocompleteSelectProps<Option>
  export type AsyncSelectProps<
    Option extends SelectOption<unknown> = SelectOption<unknown>,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>
  > = FormAsyncSelectProps<Option, IsMulti, Group>
  export type SelectProps<
    Option extends SelectOption<unknown> = SelectOption<unknown>,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>
  > = FormSelectProps<Option, IsMulti, Group>
  export type AsyncCreatableSelectProps<
    Option extends SelectOption<unknown> = SelectOption<unknown>,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>
  > = FormAsyncCreatableSelectProps<Option, IsMulti, Group>
  export type CreatableSelectProps<
    Option extends SelectOption<unknown> = SelectOption<unknown>,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>
  > = FormCreatableSelectProps<Option, IsMulti, Group>
  export type Props<
    Element extends HTMLElement,
    ElementProps extends { value?: any } & Record<string, any>
  > = FormBindingsProps<Element, ElementProps>

  export const Checkbox = FormCheckbox
  export const Datepicker = FormDatepicker
  export const Daterange = FormDaterange
  export const Input = FormInput
  export const PhoneInput = FormPhoneInput
  export const Radio = FormRadio
  export const YesNoRadio = FormYesNoRadio
  export const RichTextEditor = FormRichTextEditor
  export const Textarea = FormTextarea
  export const Timepicker = FormTimepicker
  export const Toggle = FormToggle
  export const AutocompleteSelect = FormAutocompleteSelect
  export const AsyncCreatableSelect = FormAsyncCreatableSelect
  export const AsyncSelect = FormAsyncSelect
  export const Select = FormSelect
  export const CreatableSelect = FormCreatableSelect
  export const SelectWrapper = FormSelectWrapper

  export const ExpertisesSelect = FormExpertisesSelect
  export const IndustriesSelect = FormIndustriesSelect

  export const isFieldInvalid = isFormFieldInvalid
  export const omitFieldConfigProperties = omitFormFieldConfigProperties
  export const StyledLabel = StyledFormLabel
}
