import { ThemeInterface, UIKitProvider } from "@prosapient/prosapient-styleguide"
import { ThemeProvider } from "styled-components"
import { DeepPartial } from "shared/MergeUtil"
import { appTheme } from "./AppTheme"
import { FC, useMemo } from "react"
import deepmerge from "deepmerge"

export type AppThemeProviderProps = {
  override?: DeepPartial<ThemeInterface>
}

export const AppThemeProvider: FC<AppThemeProviderProps> = props => {
  const overrideTheme = useMemo<ThemeInterface>(() => {
    // @ts-ignore
    return props.override ? deepmerge<ThemeInterface>({}, appTheme, props.override) : appTheme
  }, [props.override])

  return (
    <ThemeProvider theme={overrideTheme}>
      <UIKitProvider theme={overrideTheme}>{props.children}</UIKitProvider>
    </ThemeProvider>
  )
}
