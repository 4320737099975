import styled, { keyframes } from "styled-components"
import { Box } from "@prosapient/prosapient-styleguide"
import { ComponentPropsWithRef } from "react"
import { BoxCoreProps } from "@prosapient/prosapient-styleguide/src/components/Box"

const wave = keyframes`
  0% {
    transform: translateX(-100%);
  }
  60% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
`

type SkeletonProps = ComponentPropsWithRef<"div"> &
  BoxCoreProps & {
    height?: number
    maxWidth?: string | number
    width?: string | number
    mb?: string | number
  }

export const Skeleton = styled(Box)<SkeletonProps>`
  height: ${props => props.height || 1.5}rem;
  width: ${props => props.width ?? "auto"};
  max-width: ${props => props.maxWidth ?? "auto"};
  margin-bottom: ${props => props.mb ?? 0};
  display: block;
  background-color: rgba(0, 0, 0, 0.11);

  transform: scale(1, 0.6);
  border-radius: 5px;
  transform-origin: 0 60%;
  overflow: hidden;
  position: relative;

  &:before {
    content: "";
  }

  &:after {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    position: absolute;
    animation: ${wave} 1.6s linear 0.5s infinite;

    transform: translateX(-100%);
    background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.08), transparent);
  }
`
