import React, { ChangeEvent } from "react"
import { Box, Flex, Radio as UIKitRadio, RadioProps as UIKitRadioProps } from "@prosapient/prosapient-styleguide"
import { Field, useField } from "react-final-form"
import { FormBindings } from "shared/form-bindings-new"

export const FormRadio = (props: FormBindings.Props<HTMLInputElement, UIKitRadioProps>) => (
  <Field
    {...props}
    type="radio"
    render={({ input, ...rest }) => {
      return <UIKitRadio {...rest} {...input} onChange={() => input.onChange(input.value)} />
    }}
  />
)

export const FormYesNoRadio = ({
  "data-test-id": dataTestId,
  name,
  isBlank,
  ...props
}: FormBindings.Props<HTMLInputElement, UIKitRadioProps> & { isBlank?: boolean }) => {
  const { input, meta } = useField(name, props)
  const fieldIsInvalid = FormBindings.isFieldInvalid(meta)

  return (
    <>
      {props.label && (
        <Box pb={6}>
          <FormBindings.StyledLabel disabled={props.disabled} required={props.required} invalid={fieldIsInvalid}>
            {props.label}
          </FormBindings.StyledLabel>
        </Box>
      )}
      <Flex flexDirection="row">
        <UIKitRadio
          {...FormBindings.omitFieldConfigProperties(props)}
          {...input}
          checked={isBlank && !meta.visited ? false : Boolean(input.value)}
          data-test-id={`${dataTestId}-yes`}
          onChange={(e: ChangeEvent<HTMLInputElement>) => e.target.checked && input.onChange(true)}
          label="Yes"
        />
        <Box pl={10} />
        <UIKitRadio
          {...FormBindings.omitFieldConfigProperties(props)}
          {...input}
          checked={isBlank && !meta.visited ? false : !input.value}
          data-test-id={`${dataTestId}-no`}
          onChange={(e: ChangeEvent<HTMLInputElement>) => e.target.checked && input.onChange(false)}
          label="No"
        />
      </Flex>
    </>
  )
}
