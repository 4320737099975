import styled from "styled-components"
import { Button, Flex, Grid, Text } from "@prosapient/prosapient-styleguide"

export const StyledButton = styled(Button)`
  @media only screen and (max-width: 640px) {
    width: 100%;
    margin-top: auto;
    padding-top: 1px;
    padding-bottom: 1px;
    min-height: 40px;
  }
`

export const ActionBox = styled(Flex)`
  @media only screen and (max-width: 640px) {
    width: 100%;
    flex-wrap: wrap;
    margin-top: auto;
  }
`

export const GridContainer = styled(Grid).attrs({ gridGap: 20 })`
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "form dialog";
  flex: 1;

  & > *:nth-child(1) {
    grid-area: form;
    width: 100%;
  }

  & > *:nth-child(2) {
    grid-area: dialog;
    width: 100%;
  }

  @media only screen and (max-width: 1200px) {
    grid-template-areas:
      "dialog"
      "form";
    grid-template-columns: auto;
  }
`

export const BodyWrapper = styled(Flex).attrs({ p: 9 })`
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;

  @media only screen and (max-width: 1024px) {
    //margin: 0;
    padding-right: 0;

    padding-bottom: 0;
    padding-left: 0;
  }
`

export const SectionTitle = styled(Text).attrs({ fontSize: "1.142rem", fontWeight: 4 })`
  width: 100%;
  line-height: 2rem;
`

export const RemoveButton = styled(Button)`
  color: ${({ theme }) => theme.colors.beta[600]};
  padding: 0;

  &:hover {
    color: ${({ theme }) => theme.colors.delta[800]};
  }
`

export const SectionBody = styled(Flex).attrs({ fontSize: "1.142rem", fontWeight: 4 })`
  flex: 1 1 100%;

  @media only screen and (max-width: 640px) {
    display: none;
  }
`
