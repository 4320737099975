import { differenceInDays, getYear, subMilliseconds } from "date-fns"
import { formatToTimeZone } from "shared/date"
// import { Decimal } from "decimal.js"
import * as R from "ramda"
import { useContext } from "react"
import { ThemeContext } from "styled-components"
import { themeGet } from "@styled-system/theme-get"

export { secondsToTime } from "./time"
// export const secondsToTime= secondsToTime

export const noop = () => {} //eslint-disable-line @typescript-eslint/no-empty-function

export const throttle = (fn: Function, threshold = 250, scope = {}) => {
  let last: number
  let deferTimer: ReturnType<typeof setTimeout>

  return function(this: any, ...args: any) {
    const context = scope || this

    const now = +new Date()
    if (last && now < last + threshold) {
      // hold on to it
      clearTimeout(deferTimer)
      deferTimer = setTimeout(function() {
        last = now
        fn.apply(context, args)
      }, threshold)
    } else {
      last = now
      fn.apply(context, args)
    }
  }
}

export const debounce = (fn: Function, delay: number) => {
  let inDebounce: ReturnType<typeof setTimeout>

  return function(this: any, ...args: any) {
    clearTimeout(inDebounce)
    inDebounce = setTimeout(() => fn.apply(this, args), delay)
  }
}

export const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

export const userFullName = (user: { firstName: string; lastName: string }) => `${user.firstName} ${user.lastName}`

export const userFullNameWithStatus = (user: { firstName: string; lastName: string }) => {
  return [userFullName(user), null].filter(e => e != null).join(" ")
}

interface User {
  id: string
  firstName: string
  lastName: string
}

interface Member {
  enabled: boolean
  user: User
}

export const memberCompare = (owner: { id: string } | null | undefined) => (a: Member, b: Member): number => {
  if (owner != null) {
    if (a.user.id === owner.id) return -1
    if (b.user.id === owner.id) return 1
  }
  return userFullName(a.user).localeCompare(userFullName(b.user))
}

const clientUserRank = (
  user: User,
  owner: { id: string } | null | undefined,
  director: { id: string } | null | undefined
) => {
  if (director && director.id === user.id) return 100
  if (owner && owner.id === user.id) return 10
  return 1
}

export const clientUserCompareByRole = (
  owner: { id: string } | null | undefined,
  director: { id: string } | null | undefined
) => (a: User | null, b: User | null): number => {
  if (a === null || b === null) return 0
  const aRank = clientUserRank(a, owner, director)
  const bRank = clientUserRank(b, owner, director)
  return bRank - aRank
}

export const projectMemberCompareByRole = (
  owner: { id: string } | null | undefined,
  director: { id: string } | null | undefined
) => (a: Member, b: Member) => {
  const sortByRole = clientUserCompareByRole(owner, director)(a.user, b.user)
  if (sortByRole !== 0) return sortByRole
  const sortByEnabled = Number(b.enabled) - Number(a.enabled)
  if (sortByEnabled !== 0) return sortByEnabled
  return userFullName(a.user).localeCompare(userFullName(b.user))
}
const REGION_SORTING = ["WORLDWIDE", "EUROPE", "SAMERICA", "NAMERICA", "ASIA", "AFRICA"]
export const compareLocations = (a: { id: string; name: string }, b: { id: string; name: string }) => {
  const regionIdxA = REGION_SORTING.indexOf(a.id)
  const regionIdxB = REGION_SORTING.indexOf(b.id)
  //compare regions
  if (regionIdxA !== -1 && regionIdxB !== -1) return regionIdxA - regionIdxB
  // compare regions with countries
  if (regionIdxA !== -1 && regionIdxB === -1) return -1
  if (regionIdxA === -1 && regionIdxB !== -1) return 1
  // pop up gbr along countries
  if (a.id === "GBR") return -1
  if (b.id === "GBR") return 1
  return a.name.localeCompare(b.name)
}

export const compareNetworks = (a: { id: string; title: string }, b: { id: string; title: string }) => {
  return a.title.localeCompare(b.title)
}

export const range = (start: number, end: number): number[] => {
  return new Array(end - start + 1).fill(undefined).map((_, i) => i + start)
}

export const getYears = (): {
  value: React.ReactText
  label: React.ReactText
}[] => {
  const currentYear = getYear(new Date())
  return range(currentYear - 100, currentYear)
    .reverse()
    .map(year => ({ value: year, label: year }))
}

export const scrollToTop = () => {
  try {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  } catch (error) {
    window.scrollTo(0, 0)
  }
}

export const formatTime = (seconds: number) => {
  const h = Math.floor(seconds / 3600)
  const m = Math.floor((seconds % 3600) / 60)
  const s = seconds % 60
  return [h, m > 9 ? m : h ? `0${m}` : m || "0", s > 9 ? s : `0${s}`].filter(a => a).join(":")
}

export const makePercentage = R.compose(R.flip(R.concat)("%"), R.invoker(1, "toFixed")(2), R.multiply(100))

export const truncateText = (str: string, length = 100, ending = "...") =>
  str.length > length ? str.substring(0, length - ending.length) + ending : str

export const replaceHTML = (str?: string) => (str ? unescapeHTML(str.replace(/(<[^>]+>|<[^>]>|<\/[^>]>)/g, "")) : "")

export const unescapeHTML = (str?: string) => (str ? str.replace(/&nbsp;/g, " ") : "")

export const capitalize = (text: string) => text.charAt(0).toUpperCase() + text.slice(1)

export const undef = (v: unknown) => v === null || v === undefined
export const notUndef = (v: unknown) => v !== null && v !== undefined

export const dateDistanceFormatted = (startDate: Date, endDate: Date) => {
  const daysLeft = differenceInDays(startDate, endDate)
  const durationLeftAsDate = subMilliseconds(startDate, endDate.getTime())
  const daysString = daysLeft > 0 ? `${daysLeft}d ` : ""
  const timeString = formatToTimeZone(durationLeftAsDate, "HH:mm:ss", { timeZone: "UTC" })

  return `${daysString}${timeString}`
}

// export const toDecimal = (v: Decimal.Value) => new Decimal(v)

const addHeadingZero = (num: number): string => {
  return num > 9 ? num.toString() : `0${num}`
}

export const getDisplayTimeBySeconds = (seconds: number): string => {
  if (!isFinite(seconds)) {
    return "00:00:00"
  }

  const min = addHeadingZero(Math.floor(seconds / 60))
  const sec = addHeadingZero(Math.floor(seconds % 60))
  return `${min}:${sec}`
}

export const getTimezoneOffset = () => {
  const offset = new Date().getTimezoneOffset() / 60
  if (offset < 0) return `GMT+${-offset}`
  if (offset > 0) return `GMT-${offset}`
  if (offset === 0) return `GMT+0`
}
export const browserTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone

export const insertIf = (condition: boolean, ...elements: any[]) => (condition ? elements : [])

export const maybeCoerceToNull = (v: any) => (v === null || v === undefined ? null : v)
export const maybeCoerceToUndef = (v: any) => (v === null || v === undefined ? undefined : v)

export const notEmpty = <T>(value: T | null | undefined): value is T => value != null

// eslint-disable-next-line
const hashCode = function(str: string) {
  let hash = 0
  if (str.length === 0) return hash
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i)

    hash = (hash << 5) - hash + char

    hash = hash & hash
  }
  return hash
}

export const formatDuration = (durationInMinutes: number | undefined | null) => {
  const minutesInHour = 60

  if (durationInMinutes === undefined || durationInMinutes === null) {
    return ""
  }

  if (durationInMinutes < minutesInHour) {
    return `${durationInMinutes}m`
  }
  const hours = Math.floor(durationInMinutes / minutesInHour)
  const minutes = durationInMinutes % minutesInHour

  if (minutes > 0) {
    return `${hours}h ${minutes}m`
  }

  return `${hours}h`
}

type ObjectKey = string | number | symbol

export const groupBy = <K extends ObjectKey, TItem extends Record<K, ObjectKey>>(
  data: TItem[],
  key: K
): Record<ObjectKey, TItem[]> => {
  // `data` is an array of objects, `key` is the key (or property accessor) to group by
  // reduce runs this anonymous function on each element of `data` (the `item` parameter,
  // returning the `storage` parameter at the end
  return data.reduce((storage: Record<ObjectKey, TItem[]>, item) => {
    // get the first instance of the key by which we're grouping
    const group = item[key]

    // set `storage` for this instance of group to the outer scope (if not empty) or initialize it
    storage[group] = storage[group] || []

    // add this item to its group within `storage`
    storage[group].push(item)

    // return the updated storage to the reduce function, which will then loop through the next
    return storage
  }, {}) // {} is the initial value of the storage
}

export const splice = (str: string, idx: number, rem: number, subStr: string) => {
  return str.slice(0, idx) + subStr + str.slice(idx + Math.abs(rem))
}

export const useResolvedColor = (color: string, fallback?: string) => {
  const value = fallback || "beta.600"
  const theme = useContext(ThemeContext)
  return themeGet(`colors.${color}`, value)({ theme })
}

export const median = (arr: number[]) => {
  const middleIdx = Math.floor(arr.length / 2)

  const sortedArr = [...arr].sort((a, b) => a - b)
  const v = sortedArr.length % 2 !== 0 ? sortedArr[middleIdx] : (sortedArr[middleIdx - 1] + sortedArr[middleIdx]) / 2
  return Number(v.toFixed(2))
}
