import Markdown from "markdown-to-jsx"
import { Blockquote } from "./Blockquote"
import styled from "styled-components"

const StyledMarkdown = styled(Markdown)`
  line-height: 2rem;

  & > h2:first-child {
    margin-top: 0;
    margin-bottom: 2em;
  }

  a {
    color: ${({ theme }) => theme.colors.alpha["600"]};
    text-decoration: none;

    &:hover {
      color: ${({ theme }) => theme.colors.alpha["800"]};
      text-decoration: underline;
    }
  }
`

const Ul = styled("ul")`
  list-style: none;
  padding-left: 18px;
`

const Li = styled("li")`
  position: relative;

  &:before {
    content: "";
    display: block;
    width: 6px;
    height: 6px;
    background: #00b852;
    position: absolute;
    top: 11px;
    left: -18px;
  }
`

export const ComplianceMarkdown = ({ md }: { md: string }) => {
  return (
    <StyledMarkdown
      children={md}
      options={{
        overrides: {
          h2: {
            props: { style: { fontSize: `${20 / 14}rem`, fontWeight: 700 } },
          },
          h3: {
            props: { style: { fontSize: `${16 / 14}rem`, fontWeight: 500 } },
          },
          h4: {
            props: { style: { fontSize: `1rem`, fontWeight: 500 } },
          },
          ul: {
            component: Ul,
          },
          li: {
            component: Li,
          },
          blockquote: {
            component: Blockquote,
            props: {
              bg: "epsilon.50",
              iconColor: "epsilon.300",
              color: "beta.800",
            },
          },
        },
      }}
    />
  )
}
