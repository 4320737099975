import React from "react"
import {
  Hint as UIKitHint,
  Timepicker as UIKitTimepicker,
  TimepickerProps as UIKitTimepickerProps,
} from "@prosapient/prosapient-styleguide"
import { useField } from "react-final-form"
import { FormBindings } from "shared/form-bindings-new"

type CustomProps = {
  label?: string
  hint?: string
  disabled?: boolean
  required?: boolean
}

type TimepickerProps = FormBindings.Props<HTMLInputElement, UIKitTimepickerProps> & CustomProps

export const FormTimepicker = ({ name, ...props }: TimepickerProps) => {
  const { input, meta } = useField(name)
  const fieldIsInvalid = FormBindings.isFieldInvalid(meta)

  return (
    <>
      {props.label && (
        <FormBindings.StyledLabel disabled={props.disabled} required={props.required} invalid={fieldIsInvalid}>
          {props.label}
        </FormBindings.StyledLabel>
      )}
      <UIKitTimepicker
        {...FormBindings.omitFieldConfigProperties(props)}
        invalid={fieldIsInvalid}
        onChange={input.onChange}
        value={input.value.stringValue}
      />
      {fieldIsInvalid ? (
        <UIKitHint invalid={fieldIsInvalid}>{meta.error || meta.submitError}</UIKitHint>
      ) : (
        props.hint && <UIKitHint>{props.hint}</UIKitHint>
      )}
    </>
  )
}
