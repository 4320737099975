import styled from "styled-components"
import { Box, Button, Collapse, Link } from "@prosapient/prosapient-styleguide"

export const Container = styled("footer")`
  //position: fixed;
  position: sticky;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  z-index: 10;
  padding: 12px 24px;
  display: flex;
  align-items: center;
  height: 48px;
  background: white;
  border-top: 1px solid ${({ theme }) => theme.colors.beta["300"]};
  white-space: nowrap;
  

  @media only screen and (max-width: 640px) {
    position: static;
    z-index: initial;
    margin-top: 12px;
  }
}
`
export const MobileButton = styled(Button)`
  @media only screen and (max-width: 640px) {
    width: 100%;
    margin-top: auto;
    padding-top: 1px;
    padding-bottom: 1px;
    min-height: 40px;
  }
`

export const StyledLink = styled(Link)`
  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`

export const CardWrapper = styled(Box).attrs({ shadow: "light", borderRadius: 2, mb: 5 })`
  @media only screen and (max-width: 1024px) {
    box-shadow: none;
  }
`

export const CardInfo = styled(Box).attrs({ bg: "white", p: 9, borderRadius: 2 })`
  @media only screen and (max-width: 1024px) {
    padding-left: 0;
    padding-right: 0;
  }
`

export const StyledCollapse = styled(Collapse)`
  & {
    &:last-child {
      margin-top: 0;

      button {
        padding: 0;

        svg {
          display: none;
        }
      }
    }
  }
`
