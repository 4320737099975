import styled from "styled-components"
import { Box, Button, Flex, Grid, Input, Select, Text, CreatableSelect } from "@prosapient/prosapient-styleguide"

export const StackItem = styled(Box).attrs({ mb: "1.428rem" })`
  max-width: calc(100vw - 20px);

  @media only screen and (max-width: 640px) {
    &:last-child {
      margin-top: auto;
      //position: sticky;
      //bottom: 0;
    }
  }
`

export const Label = styled(Text).attrs({ fontSize: "1.142rem", fontWeight: 4, lineHeight: "2rem" })``
export const SubLabel = styled(Text).attrs({ lineHeight: "1.85rem", color: "beta.600" })``
export const Sup = styled(Text).attrs({ color: "delta.400", inline: true })``
export const ContactItem = styled(Flex).attrs({ mb: "5px", lineHeight: "21px", color: "beta.600" })`
  line-height: 21px;

  & > *:first-child {
    margin-right: 10px;
    min-width: 21px;
  }
`

export const StyledInput = styled(Input).attrs({ autoComplete: "off" })`
  flex: 1;
`

export const StyledCreatableSelect = styled(CreatableSelect)`
  [class*="-multiValue"] {
    & > * > * {
      max-width: 100%;
    }
  }
`

export const StyledSelect = styled(Select)`
  flex: 1;
`

export const StyledButton = styled(Button)`
  @media only screen and (max-width: 640px) {
    width: 100%;
    margin-top: auto;
    padding-top: 1px;
    padding-bottom: 1px;
    min-height: 40px;
  }
`

export const EditButton = styled(Box)`
  display: none;
  cursor: pointer;
  position: absolute;
  top: 22px;
  right: 22px;
  background: #ffffff;
`

export const Wrapper = styled(Flex).attrs({
  m: "auto",
  shadow: "light",
  borderRadius: 2,
  bg: "white",
  p: 9,
  minHeight: "100%",
})`
  position: relative;

  &:hover ${EditButton} {
    display: block;
  }

  @media only screen and (max-width: 1024px) {
    box-shadow: none;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;

    &.show-mobile {
      display: flex;
    }

    &.hide-mobile {
      display: none;
    }
  }
`

export const ActionBox = styled(Flex)`
  @media only screen and (max-width: 640px) {
    width: 100%;
    flex-wrap: wrap;
    margin-top: auto;
  }
`

export const DeactivateButton = styled(Button).attrs({ variant: "secondary" })`
  color: ${props => props.theme.colors.beta[400]};
  border-color: ${props => props.theme.colors.beta[400]};

  &:hover,
  &.-active,
  &:focus:not([disabled]) {
    color: ${props => props.theme.colors.beta[600]};
    border-color: ${props => props.theme.colors.beta[600]};
  }

  @media only screen and (max-width: 640px) {
    width: 100%;
    margin-top: auto;
    padding-top: 1px;
    padding-bottom: 1px;
    min-height: 40px;
  }
`

export const EditContactItem = styled(ContactItem).attrs({ mb: "0" })``

export const ContactBox = styled(Grid).attrs({ gridGap: 10 })`
  grid-template-columns: 21px auto;
  grid-template-areas: "icon input";
  flex: 1;

  & > *:nth-child(1) {
    grid-area: icon;
    align-self: center;
  }

  & > *:nth-child(2) {
    grid-area: input;
    width: 100%;
  }
`
