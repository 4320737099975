import { Flex } from "@prosapient/prosapient-styleguide"
import { Banner } from "./Banner"
import { MIcon } from "components/shared/MIcon/MIcon"

type IProps = { show: boolean }

export const BannerNoRecent = ({ show = true }: IProps) => {
  if (!show) return null

  return (
    <Banner show hideIcon>
      <Flex alignItems="center">
        <MIcon icon="info" color="white" style={{ marginRight: "8px" }} /> Please, add information about your recent
        employment below
      </Flex>
    </Banner>
  )
}
