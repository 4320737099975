import React from "react"
import { IProjectAction, IProjectStatus } from "api/__generated__/schema"
import { ProjectCard } from "./components/ProjectCard"
import { CardSkeleton } from "./components/CardSkeleton"

type IProps = {
  externalId?: string
  title?: string
  description?: string
  status?: IProjectStatus
  actions?: IProjectAction[]
  hasScreeningQuestions?: boolean
  loading?: boolean
}

export const Card = ({ loading = false, actions = [], hasScreeningQuestions = false, ...props }: IProps) => {
  return loading ? (
    <CardSkeleton />
  ) : (
    <ProjectCard actions={actions} hasScreeningQuestions={hasScreeningQuestions} {...props} />
  )
}
