import { Alert, Box, Flex, Text, useAlert } from "@prosapient/prosapient-styleguide"
import { ROUTES } from "components/constants"
import { MobileButton } from "../../../../styles"
import { useRejectProjectMutation } from "api/__generated__/schema"
import { DeclineProjectModal } from "components/@screening/@main/DeclineProjectModal"
import { useState } from "react"

type IProps = { externalId?: string; hasScreeningQuestions?: boolean }

export const AnswerPending = ({ externalId }: IProps) => {
  const alert = useAlert(Alert.Context)

  const [openModal, setOpenModal] = useState(false)

  const [rejectProject] = useRejectProjectMutation()

  const openModalHandler = () => {
    setOpenModal(true)
  }

  const closeModalHandler = () => {
    setOpenModal(false)
  }

  const declineProjectHandler = async () => {
    try {
      const result = await rejectProject({ variables: { externalId, answers: [] } })

      if (result?.data?.rejectProject?.success) {
        setOpenModal(false)

        const win = window.open(`${ROUTES.projectInvitationDeclined}?project_external_id=${externalId}`, "_blank")
        if (win?.focus) win.focus()
      } else {
        alert.error(result?.data?.rejectProject?.description)
      }
    } catch (e) {
      /*eslint-disable */
      console.error(e)
      /*eslint-enable */

      // @ts-ignore
      alert.error(e?.message || e)
    }
  }

  return (
    <Box>
      <Text lineHeight={7} mb={2}>
        You have been invited to take part in a consultation from L.E.K.
      </Text>

      <Flex style={{ gridGap: 4 }} mb={1}>
        <a href={`${ROUTES.screeningMain}?project_external_id=${externalId}`} target="_blank">
          <MobileButton onClick={() => {}}>Accept project</MobileButton>
        </a>
        <MobileButton variant="secondary" onClick={openModalHandler}>
          Decline project
        </MobileButton>
      </Flex>

      <DeclineProjectModal isOpen={openModal} close={closeModalHandler} submit={declineProjectHandler} />
    </Box>
  )
}
