import React from "react"
import {
  Hint as UIKitHint,
  Input as UIKitInput,
  InputProps as UIKitInputProps,
  Box,
} from "@prosapient/prosapient-styleguide"
import { useField } from "react-final-form"
import { useTranslation } from "react-i18next"
import { FormBindings } from "shared/form-bindings-new"
import { AsYouType } from "libphonenumber-js"

type HintProps = {
  hint?: string
  hintPositionBehaviour?: "floating" | "fixed"
}

type CustomProps = {
  label?: string
  labelHtml?: string
  validateOnBlur?: boolean
} & HintProps

export type FormInputProps = FormBindings.Props<HTMLInputElement, UIKitInputProps> & CustomProps

export const FormInput = React.forwardRef<HTMLInputElement, FormInputProps>(
  ({ validateOnBlur = true, name, hintPositionBehaviour = "fixed", ...props }, ref) => {
    const { t } = useTranslation()
    const { input, meta } = useField(name, props)
    const fieldIsInvalid = FormBindings.isFieldInvalid(meta)

    const inputProps = {
      ...input,
      ...FormBindings.omitFieldConfigProperties(props),
      onBlur: validateOnBlur ? input.onBlur : () => {},
    }

    return (
      <>
        {props.label && (
          <FormBindings.StyledLabel disabled={props.disabled} required={props.required} invalid={fieldIsInvalid}>
            {props.label}
          </FormBindings.StyledLabel>
        )}
        {props.labelHtml && (
          <FormBindings.StyledLabel
            required={props.required}
            disabled={props.disabled}
            invalid={fieldIsInvalid}
            dangerouslySetInnerHTML={{ __html: props.labelHtml }}
          />
        )}
        <UIKitInput
          {...inputProps}
          invalid={fieldIsInvalid}
          required={false}
          ref={ref}
          autoComplete="off"
          onChange={e => {
            input.onChange(e)
            props.onChange && props.onChange(e)
          }}
        />
        <Box position={hintPositionBehaviour === "floating" ? "absolute" : "static"}>
          {fieldIsInvalid ? (
            <UIKitHint invalid={fieldIsInvalid}>{t(meta.error || meta.submitError)}</UIKitHint>
          ) : (
            props.hint && <UIKitHint>{props.hint}</UIKitHint>
          )}
        </Box>
      </>
    )
  }
)
