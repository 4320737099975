import { Alert, Box, Flex, Grid, useAlert } from "@prosapient/prosapient-styleguide"
import styled from "styled-components"
import { ActionBox, StyledButton } from "./styles"
import { ViewCard } from "./components/ViewCard"
import { IEmployment, IEmploymentPayload, useUpdateEmploymentMutation } from "api/__generated__/schema"
import { useMemo, useState } from "react"
import { getMonthsShortFormat } from "data/months"
import { EmploymentForm } from "./components/EmploymentForm"
import { fillEmploymentPayloadToSave, isEmploymentMatchPayload } from "./utils/mapper"
import { isEmploymentValid } from "./utils/validate"
import { EditEmployment } from "./types"

export const SectionHead = styled(Flex)`
  justify-content: space-between;

  @media only screen and (max-width: 640px) {
    grid-gap: 4px 8px;

    &.wrap {
      flex-wrap: wrap;
    }
  }
`

type DisplayEmployment = {
  externalId: string
  position: string
  company: string
  startDate: string
  endDate: string
  country: string
  description: string
}

type IProps = {
  list: null | IEmployment[]
  edit: null | IEmployment
  canEdit: boolean
  setEdit: (value: null | IEmployment) => void
  onUpdate: () => void
}

export const EmploymentList = ({ list, edit, setEdit, canEdit, onUpdate }: IProps) => {
  const alert = useAlert(Alert.Context)
  const [updateEmployment, { loading: updateEmploymentLoading }] = useUpdateEmploymentMutation()

  const [editedEmployment, setEditedEmployment] = useState<null | EditEmployment>(null)

  const displayData: DisplayEmployment[] = useMemo(() => {
    if (list === null) return [] as DisplayEmployment[]

    return list.map(
      ({
        company,
        endMonth = null,
        endYear,
        externalId,
        location,
        position,
        responsibilities,
        startMonth = null,
        startYear,
      }) => {
        const startDate = [getMonthsShortFormat(startMonth), startYear].filter(Boolean).join(" ")
        const endDate = [getMonthsShortFormat(endMonth), endYear].filter(Boolean).join(" ")

        return {
          externalId,
          position: position || "",
          company: company || "",
          startDate,
          endDate,
          country: location || "",
          description: responsibilities || "",
        }
      }
    )
  }, [list])

  const isFormValid = useMemo(() => editedEmployment !== null && isEmploymentValid(editedEmployment), [
    editedEmployment,
  ])

  const selectEmploymentToEdit = (value: IEmployment | null) => {
    setEditedEmployment(null)
    setEdit(value)
  }

  const resetEmploymentToEdit = () => {
    setEditedEmployment(null)
    setEdit(null)
  }

  const updateEmploymentHandler = async () => {
    if (editedEmployment === null || edit === null) return

    // skip request if no changes
    if (isEmploymentMatchPayload(edit, editedEmployment)) {
      resetEmploymentToEdit()
      return
    }

    try {
      const payload: IEmploymentPayload = fillEmploymentPayloadToSave(editedEmployment)

      const { data } = await updateEmployment({ variables: { employmentExternalId: edit.externalId, payload } })

      if (data?.updateEmployment.success) {
        alert.success("Employment has been updated successfully")
        await onUpdate()
        resetEmploymentToEdit()
      } else {
        alert.error(data?.updateEmployment.description)
      }
    } catch (e) {
      /*eslint-disable */
      console.error(e)
      /*eslint-enable */

      // @ts-ignore
      alert.error(e?.message || e)
    }
  }

  return (
    <Grid p={9} gridGap={20}>
      {displayData!.map((item, index) =>
        edit && edit.externalId === item.externalId ? (
          <Box key={item?.externalId || index} maxWidth={432} position="relative">
            <Box position="absolute" width={2} height="100%" bg="alpha.600" maxHeight={21} marginLeft={-9} />

            <EmploymentForm
              isExists
              data={edit}
              mt="0"
              onChange={(data: IEmploymentPayload) => {
                setEditedEmployment(JSON.parse(JSON.stringify(data)))
              }}
            />

            <ActionBox>
              <StyledButton
                variant="primary"
                type="button"
                disabled={!isFormValid || updateEmploymentLoading}
                loading={updateEmploymentLoading}
                onClick={updateEmploymentHandler}
              >
                Save changes
              </StyledButton>
              <Box m={2} />
              <StyledButton type="button" variant="secondary" onClick={resetEmploymentToEdit}>
                Discard
              </StyledButton>
            </ActionBox>
          </Box>
        ) : (
          <ViewCard
            key={item?.externalId || index}
            {...item}
            editable={canEdit && edit === null}
            onEdit={() => {
              const current = list?.find(listItem => listItem.externalId === item.externalId)
              if (current) selectEmploymentToEdit(current)
            }}
          />
        )
      )}
    </Grid>
  )
}
