import { ReactNode, useState } from "react"
import { ContextType, GlobalSpinnerContext } from "./GlobalSpinnerContext"

export const GlobalSpinnerProvider = ({ children }: { children: ReactNode }) => {
  const [globalSpinner, setGlobalSpinner] = useState(false)

  const showSpinner = () => setGlobalSpinner(true)
  const hideSpinner = () => setGlobalSpinner(false)

  const value: ContextType = {
    displaySpinner: globalSpinner,
    showSpinner,
    hideSpinner,
  }

  return <GlobalSpinnerContext.Provider value={value}>{children}</GlobalSpinnerContext.Provider>
}
