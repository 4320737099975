import { Flex, Text, VStack } from "@prosapient/prosapient-styleguide"
import NumberFormat from "react-number-format"
import { format } from "shared/date"

export const PaymentSummary = () => {
  const balance = 58
  const paymentDate = "2022-05-30T08:17:25.128142+00:00"
  const total = 95300

  return (
    <VStack style={{ gridGap: "1rem" }}>
      <Flex>
        <Text>Current balance:</Text>&nbsp;
        <NumberFormat
          value={balance || 0}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"$"}
          decimalScale={2}
          fixedDecimalScale={true}
          renderText={value => <Text fontWeight={4}>{value}</Text>}
        />
      </Flex>
      <Flex>
        <Text>Next payment date:</Text>&nbsp;
        <Text fontWeight={4}> {paymentDate ? format(paymentDate, "ll") : "-"}</Text>
      </Flex>
      <Flex>
        <Text>Totally earned:</Text>&nbsp;
        <NumberFormat
          value={total || 0}
          displayType={"text"}
          thousandSeparator={" "}
          prefix={"$"}
          decimalScale={2}
          fixedDecimalScale={true}
          renderText={value => <Text fontWeight={4}>{value}</Text>}
        />
      </Flex>
    </VStack>
  )
}
