import { Box, Button, Flex, Modal, Text } from "@prosapient/prosapient-styleguide"
import { Paragraph, Title } from "components/shared/Layout"
import styled from "styled-components"
import { MIcon } from "components/shared/MIcon/MIcon"

const StyledButton = styled(Button)`
  @media only screen and (max-width: 640px) {
    padding-top: 1px;
    padding-bottom: 1px;
    min-height: 40px;
  }
`

const ButtonWrapper = styled(Flex)`
  @media only screen and (max-width: 640px) {
    flex-direction: column;
  }
`

const ContentBody = styled(Box)`
  padding: 30px;
  @media only screen and (max-width: 640px) {
    padding: 10px;
  }
`

const CloseIcon = () => (
  <Text
    color="beta.300"
    hoverColor="beta.600"
    style={{ position: "absolute", right: "0.5rem", top: "0.5rem", padding: "0.5rem" }}
  >
    <MIcon icon="close" />
  </Text>
)

type Props = {
  isOpen: boolean
  close: () => void
  onSubmit: () => void
}

export const SkipComplianceModal = ({ isOpen, close, onSubmit }: Props) => {
  return (
    <>
      <Modal
        closeable
        type="blank"
        onRequestClose={close}
        isOpen={isOpen}
        width={688}
        ariaHideApp={false}
        shouldCloseOnOverlayClick
        style={{ borderRadius: "2px", maxWidth: "100%", padding: "10px" }}
        closeIcon={CloseIcon}
      >
        <ContentBody>
          <Title>Compliance Training</Title>

          <Paragraph mt={6} mb={8}>
            Most experts complete compliance training during the sign up process as it saves time in the future. Please,
            note, you won’t be able to attend the call unless you complete the Compliance Training.
          </Paragraph>

          <ButtonWrapper>
            <StyledButton onClick={close}>Go back to Compliance Training</StyledButton>

            <Box m={2} />

            <StyledButton variant="secondary" onClick={onSubmit}>
              Skip for now
            </StyledButton>
          </ButtonWrapper>
        </ContentBody>
      </Modal>
    </>
  )
}
