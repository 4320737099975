import React from "react"
import { Toggle as ProsapientToggle, ToggleProps as ProsapientToggleProps } from "@prosapient/prosapient-styleguide"
import { Field } from "react-final-form"
import { FormBindings } from "shared/form-bindings-new"

export const FormToggle = (props: FormBindings.Props<HTMLInputElement, ProsapientToggleProps>) => (
  <Field
    {...props}
    type="checkbox"
    render={({ input, ...rest }) => (
      <ProsapientToggle
        {...FormBindings.omitFieldConfigProperties(rest)}
        {...input}
        onChange={() => input.onChange(!input.checked)}
      />
    )}
  />
)
