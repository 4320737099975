import { ApolloProvider } from "api/ApolloProvider"
import { Label, StackItem } from "../styles"
import { UploadCV } from "./UploadCV"
import React, { useMemo } from "react"
import { useMe } from "hooks"
import { Box } from "@prosapient/prosapient-styleguide"
import styled from "styled-components"

export const NameBox = styled(Box).attrs({
  display: "block",
  maxWidth: "calc(100% - 24px)",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
})``

export const ExpertCV = () => {
  const { me } = useMe()

  const fullName = useMemo(() => `${me?.firstName || ""} ${me?.lastName || ""}`, [me?.firstName, me?.lastName])

  return (
    <StackItem>
      <ApolloProvider client="http">
        <NameBox title={fullName}>
          <Label style={{ whiteSpace: "nowrap" }}>{fullName}</Label>
        </NameBox>

        <Label style={{ overflow: "visible" }}>
          <UploadCV />
        </Label>
      </ApolloProvider>
    </StackItem>
  )
}
