import { Box, Button, Flex, Modal } from "@prosapient/prosapient-styleguide"
import { Paragraph, Title } from "components/shared/Layout"
import styled from "styled-components"
import { ROUTES } from "components/constants"
import { useHistory } from "react-router"

type ProposeComplianceTrainingModalProps = {
  isOpen: boolean
  onClose: () => void
}

export const ProposeComplianceTrainingModal = ({ isOpen, onClose }: ProposeComplianceTrainingModalProps) => {
  const { push } = useHistory()

  const goToCT = async () => {
    push(ROUTES.complianceTraining)
  }

  return (
    <Modal
      type="blank"
      onRequestClose={onClose}
      isOpen={isOpen}
      width={520}
      ariaHideApp={false}
      shouldCloseOnOverlayClick
      style={{
        borderRadius: "2px",
        maxWidth: "100%",
        padding: "10px",
      }}
    >
      <ContentBody>
        <Title>Thank you for employment confirmation</Title>

        {/*<Paragraph mt={6} mb={8}>*/}
        {/*  You won’t be able to join the call if you don’t pass the Compliance training. Please, make sure to complete it*/}
        {/*  before the call.*/}
        {/*</Paragraph>*/}

        <Paragraph mt={6} mb={8}>
          Please complete the training before any scheduled calls. If you do not complete the compliance training, you
          will not be able to join any calls.
        </Paragraph>

        <ButtonWrapper>
          <StyledButton onClick={goToCT}>Go to Compliance training</StyledButton>
          <StyledButton variant="secondary" onClick={onClose}>
            Skip for now
          </StyledButton>
        </ButtonWrapper>
      </ContentBody>
    </Modal>
  )
}

const StyledButton = styled(Button)`
  @media only screen and (max-width: 640px) {
    padding-top: 1px;
    padding-bottom: 1px;
    min-height: 40px;
  }
`

const ButtonWrapper = styled(Flex)`
  grid-gap: 4px;
`

const ContentBody = styled(Box)`
  padding: 30px;
  @media only screen and (max-width: 640px) {
    padding: 10px;
  }
`
