import { Alert, Box, Button, Image, Spinner, useAlert } from "@prosapient/prosapient-styleguide"
import ImageBG from "accets/images/opt-out_of_the_project.svg"
import { useHistory, useLocation } from "react-router"
import { HEADER_MOBILE_HEIGHT, ROUTES } from "components/constants"
import { Paragraph, SubTitle, Title } from "components/shared/Layout"
import { PageContainer } from "components/shared/Layout/PageContainer"
import styled from "styled-components"
import { Helmet } from "react-helmet"
import { useEffect, useMemo } from "react"
import {
  IProjectStatus,
  useProjectQuery,
  useRejectProjectMutation,
  useRevertProjectDeclineMutation,
} from "api/__generated__/schema"

const StyledBox = styled(Box).attrs({ p: 10, maxWidth: 640, width: "100vw" })`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;

  // @media only screen and (max-width: 1024px) {
    //   min-height: calc(var(--vh) - ${HEADER_MOBILE_HEIGHT}px);
  // }

  @media only screen and (max-width: 640px) {
    max-width: 100%;
    margin: 0 auto;
    align-self: flex-start;
    box-shadow: none;
    //min-height: calc(var(--vh) - 56px);
    min-height: calc(var(--vh) - ${HEADER_MOBILE_HEIGHT}px);
    padding: 1rem;
  }
`

const StyledButton = styled(Button)`
  @media only screen and (max-width: 640px) {
    width: 100%;
    margin-top: auto;
    padding-top: 1px;
    padding-bottom: 1px;
    min-height: 40px;
  }
`

const StyledParagraph = styled(Paragraph).attrs({ px: 12, my: 4 })`
  @media only screen and (max-width: 640px) {
    padding-right: 0;
    padding-left: 0;
  }
`

export const OptOutProject = () => {
  const { push } = useHistory()
  const { search } = useLocation()
  const alert = useAlert(Alert.Context)

  const [rejectProject] = useRejectProjectMutation()
  const [revertProjectDecline, { loading }] = useRevertProjectDeclineMutation()

  const handleClick = async () => {
    try {
      const result = await revertProjectDecline({ variables: { externalId } })

      if (result?.data?.revertProjectDecline?.success) {
        push(`${ROUTES.screeningMain}?project_external_id=${externalId}`)
      } else {
        push(ROUTES.linkExpired)
      }
    } catch (e) {
      /*eslint-disable */
      console.error(e)
    }
  }

  const externalId = useMemo(() => new URLSearchParams(search).get("project_external_id") || "", [search])

  const { data, loading: projectLoading } = useProjectQuery({ variables: { externalId } })

  useEffect(() => {
    if (projectLoading) return

    if (!projectLoading && !data?.project?.status) push(ROUTES.linkExpired)
    ;(async () => {
      const isPending = data?.project?.status === IProjectStatus.Pending
      // const isRejectedByExpert = data?.project?.status === IProjectStatus.RejectedByExpert
      if (isPending) {
        try {
          const result = await rejectProject({ variables: { externalId, answers: [] } })

          if (!result?.data?.rejectProject?.success) {
            alert.error(result?.data?.rejectProject?.description)
          }
        } catch (e) {
          /*eslint-disable */
          console.error(e)
          /*eslint-enable */

          // @ts-ignore
          alert.error(e?.message || e)
        }
        // } else if (!isRejectedByExpert) {
      } else {
        push(ROUTES.linkExpired)
      }
    })()
  }, [data, projectLoading])

  if (!data && projectLoading)
    return (
      <PageContainer hasBackground={false}>
        <Spinner sizing="lg" />
      </PageContainer>
    )

  return (
    <PageContainer hasBackground={false}>
      <Helmet>
        <link rel="preload" as="image" href={ImageBG} />
      </Helmet>

      <StyledBox>
        <Box width="100%">
          <Title>Opt-out of the project</Title>

          <StyledParagraph>
            Sorry this one wasn’t a good match. We are looking forward to working together in the future projects that
            sit within your area of expertise. Thank you for your time!
          </StyledParagraph>

          <Image src={ImageBG} alt="Invitation declined image" width="100%" />

          <SubTitle mt={10} mb={6}>
            If you clicked to opt-out by mistake, you have 5 minutes to re-apply for this current project now.
          </SubTitle>
        </Box>

        <StyledButton loading={loading} onClick={handleClick}>
          Go to the screening questions
        </StyledButton>
      </StyledBox>
    </PageContainer>
  )
}
