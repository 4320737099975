import { useMemo } from "react"
import { Box, Text, VStack } from "@prosapient/prosapient-styleguide"
import countries from "shared/countryCodes"
import { MIcon } from "components/shared/MIcon/MIcon"
import styled from "styled-components"
import useMediaQuery from "@mui/material/useMediaQuery"
import { StyledButton } from "../../BasicInformation/styles"
import { ActionBox } from "../styles"
import { useMe } from "hooks"

const EditButton = styled(Box).attrs({ cursor: "pointer", ml: 2 })`
  //display: none;
  visibility: hidden;
  line-height: 0;
`

const Wrapper = styled(VStack)`
  line-height: ${25 / 14}rem;

  &:hover ${EditButton} {
    //display: block;
    visibility: visible;
  }
`
const LABEL_NOT_APPLICABLE = "N/A"
const LABEL_PRESENT = "Present"

type ViewCardProps = {
  position: string
  company: string
  startDate: string
  endDate: string
  country: string
  description: string
  editable: boolean
  onEdit: () => void
}

export const ViewCard = ({
  position,
  company,
  startDate,
  endDate,
  country,
  description,
  editable,
  onEdit,
}: ViewCardProps) => {
  const { me } = useMe()
  const hideMobile = useMediaQuery("(max-width:1024px)")

  const employmentsConfirmed = useMemo(() => Boolean(me?.state?.employmentsConfirmedAt), [
    me?.state?.employmentsConfirmedAt,
  ])

  const displayDate = useMemo(() => {
    if (!startDate && !endDate) return LABEL_NOT_APPLICABLE

    const _start = startDate ? startDate : LABEL_NOT_APPLICABLE
    const _end = endDate || (employmentsConfirmed ? LABEL_PRESENT : LABEL_NOT_APPLICABLE)

    return [_start, _end].join(" - ")
  }, [startDate, endDate, employmentsConfirmed])

  return (
    <Wrapper>
      <Box position="relative">
        <Box position="absolute" width={2} height="100%" bg="alpha.600" marginLeft={-9} />

        <Text
          truncated
          fontWeight={2}
          textTransform="uppercase"
          style={{ display: "flex", minHeight: "1.5em", whiteSpace: "normal" }}
        >
          {position}
          {editable && !hideMobile && (
            <EditButton onClick={onEdit}>
              <MIcon icon="edit" v={2} color="beta.400" hoverColor="alpha.600" />
            </EditButton>
          )}
        </Text>
      </Box>

      <Text>{company}</Text>

      <Box color="beta.600">{displayDate}</Box>
      <Box color="beta.600">{countries.getLabelByCode(country)}</Box>
      <Text preserveFormatting mt={2}>
        {description}
      </Text>

      {editable && hideMobile && (
        <ActionBox style={{ marginTop: "10px" }}>
          <StyledButton type="button" variant="secondary" onClick={onEdit}>
            Edit
          </StyledButton>
        </ActionBox>
      )}
    </Wrapper>
  )
}
