import { Route, Switch } from "react-router-dom"
import { AnswersSubmitted } from "components/@screening/@answers-submitted"
import { ROUTES } from "components/constants"
import { InviteAccepted } from "components/@screening/@invite-accepted"
import { InvitationDeclined } from "components/@screening/@invitation-declined"
import { Main } from "components/@screening/@main"
import { AskPhone } from "components/@screening/@ask-phone-number"
import { Header } from "components/shared/Header"
import { OptOutProject } from "components/@screening/@opt-out-project"
import { LinkExpired } from "./@link-expired"
import { LimitedAuthGuard } from "../../hocs/LimitedAuthGuard"

// const defaultRouteForRole = (baseUrl: string) => {
//   return `${baseUrl}/projects`
// }

const routes = [
  { path: ROUTES.screeningMain, title: "Main page", component: Main },
  { path: ROUTES.screeningSubmitted, title: "Answers submitted", component: AnswersSubmitted },
  { path: ROUTES.askPhone, title: "Ask for phone number", component: AskPhone },
  { path: ROUTES.linkExpired, title: "Link has expired", component: LinkExpired },
  { path: ROUTES.inviteAccepted, title: "Invite accepted", component: InviteAccepted },
  { path: ROUTES.projectInvitationDeclined, title: "Project invitation declined", component: InvitationDeclined },
  { path: ROUTES.optOutProject, title: "Opt-out of the project", component: OptOutProject },
]

export const Screening = () => {
  return (
    <LimitedAuthGuard>
      <Header isPublic />

      <Switch>
        <Route path={ROUTES.screeningMain} component={Main} />
        {/*<Route path={ROUTES.askPhone} component={AskPhone} />*/}

        {routes.map(({ path, title, component }) => (
          <Route key={title} path={path} component={component} />
        ))}
      </Switch>
    </LimitedAuthGuard>
  )
}
