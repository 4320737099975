import { Box, Flex, Link, Text, VStack } from "@prosapient/prosapient-styleguide"
import { MIcon } from "components/shared/MIcon/MIcon"
import { ROUTES } from "../../constants"
import styled from "styled-components"
import { useHistory } from "react-router"

const StyledLink = styled(Link).attrs({ color: "alpha.600", hoverColor: "alpha.800", fontWeight: 2 })`
  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`

export const EmploymentReminder = () => {
  const { push } = useHistory()

  return (
    <Flex style={{ gridGap: "10px" }}>
      <Box color="alpha.1000">
        <MIcon v={2} icon="work" fontSize="1.5rem" style={{ width: "20px" }} />
      </Box>

      <VStack style={{ lineHeight: `${25 / 14}rem` }}>
        <Text>Please, always keep your employment history updated</Text>

        <StyledLink
          to={`${ROUTES.myProfile}?tab=history`}
          onClick={e => {
            push(`${ROUTES.myProfile}?tab=history`)
            e.preventDefault()
            e.stopPropagation()
            return null
          }}
        >
          Check my employments
        </StyledLink>
      </VStack>
    </Flex>
  )
}
