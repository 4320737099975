import { Box, Button, Flex } from "@prosapient/prosapient-styleguide"
import { useTrainingContext } from "../hooks/useTraining"
import { useMemo, useState } from "react"
import { useHistory } from "react-router"
import styled from "styled-components"
import { ROUTES } from "components/constants"

import { SkipComplianceModal } from "./SkipComplianceModal"
import { IAuthTokenType, useCompleteComplianceTrainingMutation } from "api/__generated__/schema"
import { useMe } from "hooks"

type FooterProps = { maxWidth: number }

export const Footer = ({ maxWidth }: FooterProps) => {
  const { push } = useHistory()
  const {
    me: { state, industries, expertise },
  } = useMe()
  const {
    state: { count, currentStep },
    nextPage,
    previousPage,
  } = useTrainingContext()

  const [openModal, setOpenModal] = useState(false)

  const showSkipButton = useMemo(() => state?.authTokenType !== IAuthTokenType.Compliance, [state?.authTokenType])

  const currentIndex = useMemo(() => currentStep + 1, [currentStep])
  const progress = useMemo(() => `${currentIndex}/${count}`, [currentIndex, count])
  const isLastStep = useMemo(() => currentIndex === count, [currentIndex, count])

  const [completeComplianceTraining, { loading }] = useCompleteComplianceTrainingMutation()

  const onComplete = async () => {
    if (!state?.isCompliancePassed) {
      const { data, errors } = await completeComplianceTraining()

      if (!data?.completeComplianceTraining?.success) {
        // @ts-ignore
        alert.error("Error occurred")
        throw new Error("User can't complete Compliance Training")
      }
    }

    push(ROUTES.complianceComplete)
  }

  return (
    <Wrapper maxWidth={maxWidth} mx="auto">
      <Box mr="auto">
        <Button
          type="button"
          variant="secondary"
          onClick={previousPage}
          style={{ marginRight: "4px" }}
          disabled={currentStep === 0}
        >
          Previous
        </Button>

        {!isLastStep && (
          <>
            <Button type="button" onClick={() => nextPage()}>
              Next
            </Button>

            {showSkipButton && (
              <ButtonGhost type="button" onClick={() => setOpenModal(true)}>
                Skip for now
              </ButtonGhost>
            )}

            <SkipComplianceModal
              isOpen={openModal}
              close={() => {
                setOpenModal(false)
              }}
              onSubmit={() => {
                setOpenModal(false)

                if (!state?.isRecentEmploymentsConfirmed) {
                  return push(ROUTES.mostRecentEmployment)
                }

                if (!state?.isTagsSkipped && industries.length === 0 && expertise.length === 0) {
                  return push(ROUTES.expertTags)
                }

                return push(ROUTES.myProfile)
              }}
            />
          </>
        )}

        {isLastStep && (
          <Button type="button" onClick={onComplete} loading={loading}>
            Complete
          </Button>
        )}
      </Box>

      <Box color="beta.600">{progress}</Box>
    </Wrapper>
  )
}

const ButtonGhost = styled(Button).attrs({ ghost: true, variant: "secondary" })`
  & > span {
    color: ${props => props.theme.colors.beta[600]};
    transition: transform 300ms, background-color 150ms, color 150ms;
  }

  &:hover > span {
    color: ${props => props.theme.colors.beta[700]};
  }
`

const Wrapper = styled(Flex)`
  align-items: center;
  flex: 1;
  height: 90px;

  @media only screen and (max-width: 640px) {
    height: 60px;
  }
`
