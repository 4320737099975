import React from "react"
import { components } from "react-select"
import styled from "styled-components"
import { useResolvedColor } from "utils"

const Input = styled(components.Input)<{ placeholderColor: string }>`
  width: 100%;

  input {
    width: auto !important;

    &::placeholder {
      color: ${props => props.placeholderColor};
      visibility: ${props =>
        (props.selectProps.value as any[]).length > 0 && props.selectProps.inputValue.length === 0
          ? "visible"
          : "hidden"};
    }
  }
`

export const MultiSelectInput = (props: any) => {
  const placeholderColor = useResolvedColor("beta.600")

  const onPaste = (event: any) => {
    event.currentTarget.value = event.clipboardData.getData("text").replaceAll("\n", ";")
    props.onChange(event)
    event.preventDefault()
  }

  return (
    <Input
      {...props}
      maxLength={255}
      onPaste={onPaste}
      placeholder={props.selectProps.placeholder}
      placeholderColor={placeholderColor}
    />
  )
}
