import { Box, Text } from "@prosapient/prosapient-styleguide"
import { RecentEmploymentType } from "../../types"
import styled from "styled-components"

const Title = styled(Text).attrs({ color: "beta.800", fontWeight: 3, fontSize: 6, lineHeight: 2 })``

const Date = styled(Text).attrs({ color: "beta.600", fontSize: 6, m: 0, mt: 6 })``

type IProps = { employment: RecentEmploymentType }

export const ListItem = ({ employment }: IProps) => {
  return (
    <Box>
      <Title mt={0}>
        {employment.position} <Text inline fontWeight={1} children="at" /> {employment.company}
      </Title>

      {employment.displayDate && <Date>{employment.displayDate}</Date>}
    </Box>
  )
}
