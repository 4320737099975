import { Alert, Box, Spinner, Text, Tooltip, useAlert } from "@prosapient/prosapient-styleguide"
import { EmploymentList, SectionHead } from "./EmploymentList"
import { CreateEmployment } from "./CreateEmployment"
import { useEffect, useMemo, useState } from "react"
import { IEmployment, useConfirmRecentEmploymentMutation, useEmploymentsQuery } from "api/__generated__/schema"
import { SectionTitle, StyledButton } from "./styles"
import { useMe, useWindowSize } from "hooks"
import { format } from "shared/date"
import { BannerConfirmRecent } from "./components/BannerConfirmRecent"
import { BannerNoRecent } from "./components/BannerNoRecent"
import { ProposeComplianceTrainingModal } from "./modals/ProposeComplianceTrainingModal"
import { ROUTES } from "components/constants"
import { useHistory } from "react-router"

type EmploymentHistoryProps = { style?: React.CSSProperties }

export const EmploymentHistory = ({ style }: EmploymentHistoryProps) => {
  const { calcHeight } = useWindowSize()
  const { data, loading, refetch: refetchEmployments } = useEmploymentsQuery({
    fetchPolicy: "cache-and-network",
    errorPolicy: "ignore",
  })
  const alert = useAlert(Alert.Context)
  const [confirmRecentEmployment, { loading: confirmLoading }] = useConfirmRecentEmploymentMutation()

  const {
    me: {
      state: { isRecentEmploymentsConfirmed, employmentsConfirmedAt, isCompliancePassed, isTagsSkipped },
      expertise,
      industries,
    },
    refetch: refetchMe,
  } = useMe()

  const [edit, setEdit] = useState<null | IEmployment>(null)
  const [overview, setOverview] = useState<null | boolean>(null)
  const [showComplianceTrainingModal, setShowComplianceTrainingModal] = useState(false)
  const { push } = useHistory()

  const displayEmployments = useMemo(() => {
    if (data && !loading) return data?.expert?.employments

    return data?.expert?.employments || null
  }, [data?.expert?.employments, loading])

  const isEmployments = useMemo(() => displayEmployments !== null && displayEmployments?.length > 0, [
    displayEmployments,
    loading,
  ])

  const showErrorBanner = useMemo(
    () => !isRecentEmploymentsConfirmed && displayEmployments !== null && !edit && overview === true,
    [displayEmployments, isRecentEmploymentsConfirmed, overview, edit]
  )

  useEffect(() => {
    if (data && !loading && overview === null) setOverview(data?.expert?.employments?.length > 0)
  }, [data, !loading])

  useEffect(() => {
    if (overview) refetchEmployments().then()
  }, [overview])

  const showOverview = () => setOverview(true)

  const hideOverview = () => setOverview(false)

  const addEmploymentHandler = async () => {
    const employmentExists = (!isRecentEmploymentsConfirmed && !displayEmployments?.length) || 0
    await refetchEmployments().then(async r => {
      const employmentCreated = Boolean(r.data?.expert?.employments?.length)

      const runAutoConfirm = employmentExists && employmentCreated

      if (runAutoConfirm) {
        await confirmRecentEmploymentHandler()

        if (!isCompliancePassed) {
          setShowComplianceTrainingModal(true)
        } else if (!isTagsSkipped && expertise.length === 0 && industries.length === 0) {
          push(ROUTES.expertTags)
        }
      } else {
        await refetchMe()
      }

      setTimeout(() => {
        showOverview()
        window.scrollTo(0, 0)
        calcHeight()
      }, 10)
    })
  }

  const updateEmploymentHandler = async () => {
    refetchEmployments().then()
    refetchMe().then()
  }

  const confirmRecentEmploymentHandler = async ({ onSuccess }: { onSuccess: () => void } = { onSuccess: () => {} }) => {
    try {
      const { data } = await confirmRecentEmployment()
      const { success, description } = data?.confirmRecentEmployment || { success: false, description: "Error" }

      if (success) {
        await refetchMe()

        onSuccess()
      } else {
        alert.error(description)
      }
    } catch (e) {
      // eslint-disable-next-line
      console.error(e)

      // @ts-ignore
      alert.error(e?.message || e)
    }
  }

  if (!data && loading) {
    return (
      <Box style={style} shadow="light" borderRadius={2} bg="white" p={9} height="100%">
        <SectionHead>
          <SectionTitle>Employment history</SectionTitle>
        </SectionHead>

        <Spinner sizing="lg" />
      </Box>
    )
  }

  return (
    <Box style={style} shadow="light" borderRadius={2} bg="white" height="100%">
      <BannerConfirmRecent
        show={showErrorBanner}
        loading={confirmLoading}
        onConfirm={() =>
          confirmRecentEmploymentHandler({
            onSuccess: () => {
              if (!isCompliancePassed) setShowComplianceTrainingModal(true)
            },
          })
        }
        onAdd={hideOverview}
      />
      <BannerNoRecent show={!isRecentEmploymentsConfirmed && !displayEmployments} />

      <SectionHead p={9} pb={0} className={employmentsConfirmedAt && "wrap"}>
        <SectionTitle>
          <span>Employment history</span>
          {employmentsConfirmedAt && (
            <Text inline color="beta.600" fontWeight={1} ml={4}>
              Confirmed on {format(employmentsConfirmedAt, "ll")}
            </Text>
          )}
        </SectionTitle>

        {overview && edit === null && !showErrorBanner && (
          <StyledButton variant="secondary" type="submit" onClick={hideOverview}>
            Add employment
          </StyledButton>
        )}
        {overview && edit !== null && (
          <Tooltip width="172px" label="Please, finish editing first">
            <StyledButton variant="secondary" style={{ opacity: 0.5 }}>
              Add employment
            </StyledButton>
          </Tooltip>
        )}
      </SectionHead>

      {!overview && <CreateEmployment discard={isEmployments} onDiscard={showOverview} onDone={addEmploymentHandler} />}

      <EmploymentList
        list={displayEmployments}
        canEdit={Boolean(overview)}
        edit={edit}
        setEdit={setEdit}
        onUpdate={updateEmploymentHandler}
      />

      <ProposeComplianceTrainingModal
        isOpen={showComplianceTrainingModal}
        onClose={() => {
          setShowComplianceTrainingModal(false)

          if (!isTagsSkipped && expertise.length === 0 && industries.length === 0) {
            push(ROUTES.expertTags)
          }
        }}
      />
    </Box>
  )
}
