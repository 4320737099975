import React, { MouseEventHandler } from "react"
import { components } from "react-select"
import styled from "styled-components"

const MultiValueLabelContainer = styled("div")`
  max-width: 100%;

  & > * {
    max-width: 100%;

    & > * {
      max-width: 100%;
    }
  }
`

const MultiValueLabel = styled(components.MultiValueLabel)``

export const MultiSelectValueContainer = (props: any) => {
  const onMouseDown: MouseEventHandler<HTMLDivElement> = e => {
    e.stopPropagation()
    //@ts-ignore
    if (e.target.nodeName === "path") return

    props.selectProps.onInputChange(props.data.label, "input-change")
    // @ts-ignore
    const nextValue = (props.selectProps.value || []).filter(item => item.label !== props.data.label)
    props.selectProps.onChange(nextValue, "input-change")
  }
  const innerProps = { ...props.innerProps, onMouseDown }

  return (
    <MultiValueLabelContainer title={props.data.label}>
      <MultiValueLabel {...props} innerProps={innerProps} />
    </MultiValueLabelContainer>
  )
}
