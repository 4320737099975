import { Flex, Grid, Spinner } from "@prosapient/prosapient-styleguide"
import { Title } from "components/shared/Layout"
import { PageContainer } from "components/shared/Layout/PageContainer"
import styled from "styled-components"
import { Empty } from "./sections/Empty"
import { Overview } from "./sections/Overview"
import { Sidebar } from "components/shared/Sidebar"
import { usePaymentsQuery } from "api/__generated__/schema"
import { Footer } from "components/shared/Footer"
import { PageMainBox } from "components/shared/Layout/PageMainBox"

const StyledTitle = styled(Title).attrs({ mb: 8 })`
  display: none;
  text-align: left;

  @media only screen and (max-width: 1024px) {
    display: block;
  }
`

const Body = styled(PageContainer)`
  @media only screen and (max-width: 1024px) {
    & > div {
      align-self: flex-start;
    }
  }
`

export const PaymentHistory = () => {
  const { data, loading } = usePaymentsQuery({ fetchPolicy: "network-only" })

  return (
    <Body hasBackground={false} centered={false} pb={0} position="relative">
      <PageMainBox display="grid" minHeight="100%">
        <StyledTitle>Payment</StyledTitle>

        <GridContainer>
          {loading && (
            <Flex>
              <Spinner sizing="lg" />
            </Flex>
          )}

          {!loading && <Flex>{data?.payments?.length ? <Overview data={data?.payments} /> : <Empty />}</Flex>}

          <Sidebar pb={5} />
        </GridContainer>
      </PageMainBox>

      <Footer email="payments@lek.com" />
    </Body>
  )
}

const GridContainer = styled(Grid).attrs({ mb: 8, gridGap: 20 })`
  grid-template-columns: auto 452px;
  grid-template-areas: "main sidebar";

  & > *:nth-child(1) {
    grid-area: main;
    width: 100%;
  }

  & > *:nth-child(2) {
    grid-area: sidebar;
  }

  @media only screen and (max-width: 1024px) {
    grid-template-areas:
      "sidebar"
      "main";
    grid-template-columns: auto;
  }
`
//
// const PageMain1 = styled(Grid).attrs({ width: "100vw" })`
//   padding: 2rem;
//   min-height: 100%;
//
//   @media only screen and (max-width: 1024px) {
//     display: flex;
//     flex-direction: column;
//     min-height: calc(var(--vh) - ${HEADER_MOBILE_HEIGHT}px);
//   }
//
//   @media only screen and (max-width: 640px) {
//     max-width: 100%;
//     padding: 1rem;
//     margin: 0 auto;
//   }
// `

// export const PageMain = styled(Box).attrs({ width: "100vw" })`
//   padding: 2rem;
//
//   @media only screen and (max-width: 1024px) {
//     min-height: calc(var(--vh) - ${HEADER_MOBILE_HEIGHT}px);
//   }
//
//   @media only screen and (max-width: 640px) {
//     max-width: 100%;
//     padding: 1rem;
//     margin: 0 auto;
//   }
// `
