import React from "react"
import { useField } from "react-final-form"
import { FormBindings } from "shared/form-bindings-new"
import {
  Hint as UIKitHint,
  Checkbox as UIKitCheckbox,
  CheckboxProps as UIKitCheckboxProps,
} from "@prosapient/prosapient-styleguide"

export type CheckboxProps = FormBindings.Props<HTMLInputElement, UIKitCheckboxProps>

export const FormCheckbox = ({ name, ...props }: CheckboxProps) => {
  const { input, meta } = useField(name, { type: "checkbox", ...props })
  const isFieldInvalid = FormBindings.isFieldInvalid(meta)

  return (
    <>
      <UIKitCheckbox
        {...input}
        {...FormBindings.omitFieldConfigProperties(props)}
        invalid={FormBindings.isFieldInvalid(meta)}
      />
      {isFieldInvalid && <UIKitHint invalid={isFieldInvalid}>{meta.error}</UIKitHint>}
    </>
  )
}
