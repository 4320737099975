const STEPS_PRO = [
  `## Confidential information
### What is Confidential information?

Any non-public information that you are not at liberty to share. It is information that was passed to you in such a way that you know, or should know, that the information is to not be disclosed to others without their consent from the owner of the confidential information.

> You must **never** disclose confidential information in any form of interaction with any LEK clients

### Why do I have to keep certain information confidential?

The obligation to keep information confidential can arise from the following:

- professional obligations (doctor/patient relationship and lawyer/client relationship)
- contracts (non-disclosures, severance agreements, etc.)
- involvement with a scientific advisory board or clinical trials
- employment agreements
- consulting
- because the information belongs to someone else`,
  `## But, how do I know what is classified as confidential information?
### What cannot be discussed under LEK rules:

- information about your current employer's company standing, plans, or any other employer information (you may state your name and current employment)
- information that you agreed (oral and/or written) to not discuss with any third party
- sensitive info about a company that is not publicly disclosed or reported

> LEK has adopted rules to help our Industry Experts avoid any potential for a breach of confidentiality obligations and potential conflicts of interest. At the end of the day, you are the only one who can determine what you can and cannot share.`,
  `## Some examples below of what NOT to discuss on the LEK calls
- revenues, profits, losses, and other financial information, earnings information or projections, and quarterly annual results if you work for a public company. **You may discuss:** trends + margins
- contracts with customers/vendors, changes in auditors or audit notifications, internal forecasts, sales volumes, dividends, stock splits, mergers, acquisitions, tender offers, joint ventures, IPOS
- new product development, new discoveries, regulatory development, clinical trial results, any gains or losses, bankruptcies, trade secrets
- information belonging or pertaining to government agencies or legislative bodies that has not been made public, including rule-making, regulatory decisions and contracts
- information that a specific jurisdiction in which you reside may deem sensitive (state secrets, classified information, etc)`,
  `## What information can be shared with the client?

### Generally, any of the following can be discussed:

- your industry opinion or perspective
- information that is already in the public domain
- information independently developed by the receiving party without use, directly or indirectly, of the **Confidential Information**, received from the disclosing party
- information that is required to be disclosed by law, regulation, or order of any court or other government or regulatory agency`,
  `## Why should I care about confidentiality and conflicts of interest?

### Significant civil or criminal penalties

Disclosing confidential information or ignoring a conflict may:

- violate laws and result in jail time and/or fines
- lead to significant civil or criminal penalties
- sharing confidential information has not only the potential to harm our clients, but can cause reputational damage to yourself and LEK

> If you are currently an employee, office, director, or advisor to any publicly traded company (e.g., a company or subsidiary of a company that is listed on a stock exchange such as NYSE, HKSE, NASDAQ, LSE, etc.,) you are still free to participate in consultations with LEK, however, we expressly forbid you to take part in consultations when your current employer is the target of the consultation.`,
  `## Trade Secrets

### What is a trade secret?

A trade secret is any practice or process of a company that is generally not known outside of the company. Information considered a trade secret gives the company a competitive advantage over its competitors and is often a product of internal research and development.

Trade secrets may differ across jurisdictions but have three common traits:

- not being public
- offering some economic benefit
- and being actively protected

Examples: Coca-Cola's secret recipe, Google's search engine algorithm, and the New York Times bestsellers list

> If there is coercion in your interaction with a client to provide trade secrets that you may be in possession of, please reach out to our compliance team (compliance@lek.com) for further investigation`,
  `## Insider Trading

### What is insider trading?

Insider trading prohibits anyone from trading a security on the basis of material, non-public information in breach of a duty of trust or confidence owed directly or indirectly to an issuer, the issuer's shareholders, or the source of information.

### Penalties for Insider Trading in the United States

For each charge, it can mean 20 years in a federal penitentiary, fines for individuals up to $5,000,000 and the maximum fine for "non-natural" persons (such as an entity whose securities are publicy traded) is $25,000,000

### Penalties for Insider Trading in the United Kingdom

Maximum sentence of 7 years with an uncapped fine. May also include a ban from working within a certain sector altogether and other covenants

> To protect yourself, LEK, and our clients, please do not share any confidential and/or material non-public information with your interactions with clients. If a client is coercing you to provide such information, please reach out to our compliance team (compliance@lek.com) for further investigation.`,
  `## Compliance with Anti-Bribery Laws

You must be aware of, and comply with, all relevant anti-bribery/anti-corruption laws that are applicable when working with LEK and our clients. This includes, but is not limited to the United States: The Foreign Corrupt Practices Act (FCPA) and the United Kingdom: Bribery Act of 2010. Failure to comply may result in reputational damage, large fines, and/or jail time.

### FCPA

- criminalises bribery of a foreign public official for the purpose of obtaining or retaining business
- inducing a foreign official to act or make a decision in his/her official capacity
- inducing a foreign official to violate a lawful duty
- inducing a foreign official to confer an improper advantage upon the payor of the bribes (anything of value)

### Bribery Act of 2010

- it is a crime to offer/pay bribes to any person in the public or private sectors. This arises when one party offers any financial or other advantage with the intention that a person will perform their functions or any activity improperly (misuse their position) as reward
- it is a crime to request and accept bribes`,
  `## Expert False Representation

### Is lying on my LinkedIn profile to become an expert on LEK's platform a big deal?

Yes. Fraud by false representation is a criminal offense and also a breach of our expert terms and conditions

### Under Section 18. Right to Injunction/Limitation on Liability, 18.3 states

"You are solely responsible for your actions, including for any recklessness, willful misconduct or fraudulent misrepresentation to any Client. LEK shall have no obligation to defend you, provide you with legal counsel, or pay legal costs and expenses on your behalf."`,
  `## A few things to note

### Generally, any of the following can be discussed:

- Please regularly update your LEK profile to get paired with even more opportunities, including your Off-Limits topics
- Be accurate when providing information about yourself - if you aren't the right expert for the consultation, this will lead to a low quality experience and may hinder any future consulting work
- Keep each of your consultations strictly confidential - it could be damaging to our clients if you discuss their research, or the mere fact that they are doing research, with others.
- Do not offer to introduce clients to third parties or invite colleagues at your firm or any other third party to participate in or perform work on projects without prior approval from LEK - Your membership is on an individual basis, unless otherwise agreed in writing with LEK. We encourage you to invite your colleagues to apply to join the LEK Industry Expert Network.`,
]

const STEPS_LEK = [
  `## Disclaimers

Experts participating in the L.E.K. Expert Platform are subject to its Terms and Conditions.
Terms and Conditions can be found [here](/documents).
All Experts should review the Terms and Conditions in full.
These materials cover some of the content in the Terms and Conditions, but do not replace them.
`,
  `## Agenda

- Disclaimers
- Confidentiality
- Honoraria
- Recording
`,

  `## Do Not Provide Confidential Information to L.E.K.

Do not provide L.E.K. with confidential information, about your employer or otherwise. L.E.K. is never asking and will never ask for you to divulge confidential information. It is your obligation to comply with your confidentiality obligations.
`,

  `## What Is Confidential Information?

Information that is both not public and which you do not have the right to share. Often it is secret information that does not belong to you, and you have an obligation to keep it secret because of your employment terms, professional obligations and rules (for example, if you are a lawyer or doctor), clinical trial laws and rules, or contracts.

Common Example: non-public information about your employer’s plans or finances.
`,
  `## What Is Typically Ok To Share?

- Public information (Example: information reported by a public company)
- Your industry opinion and perspective
- Industry trends
- Information you have created or own
`,
  `## L.E.K. Interviews Are Confidential

L.E.K. interviews are confidential.

Do not share what L.E.K. interviews you about or asks you in a survey. This includes the questions L.E.K. asks, and any images, concepts, or new product ideas that L.E.K. shares with you.
`,
  `## Honoraria

We often offer an honorarium for participating in an interview.

Honorariums are paid by electronic transfer using a payment provider.

Honorariums are paid pro-rata based on the time spent on the interview.

Example: if the honorarium offered is $200/hour and the interview is 45 minutes, the honorarium for that interview will be $150.
`,
  `## Expert unable to receive honoraria

Some government employees cannot receive honoraria under applicable law.

For example, US federal government employees may not receive honoraria.

Some experts may be restricted by their employment terms or applicable laws or regulations from receiving honoraria. It is your obligation to inform us of these restrictions. Even if you cannot accept an honorarium, we would appreciate interviewing you for our projects.
`,
  `## Recording

### We record our interviews

Our standard practice is to record audio of our interviews.

In certain cases, we may also record video.

We do this to be accurate about what our experts tell us, instead of relying on memory or hastily written notes.

We do not share the recordings outside of L.E.K., unless the expert has given express consent or we are required to do so by applicable law.
`,
]

export default STEPS_LEK
