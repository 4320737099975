import React from "react"
import {
  Box,
  Hint as ProsapientHint,
  RichTextEditor as ProsapientRichTextEditor,
  RichTextEditorProps as ProsapientRichTextEditorProps,
  Label as ProsapientLabel,
} from "@prosapient/prosapient-styleguide"
import { useField } from "react-final-form"
import { FormBindings } from "shared/form-bindings-new"

type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>

type CustomProps = { label?: string }

type RichTextEditorFieldProps = PartialBy<
  FormBindings.Props<HTMLElement, ProsapientRichTextEditorProps>,
  "value" | "onChange"
> &
  CustomProps

export const FormRichTextEditor = ({ name, ...props }: RichTextEditorFieldProps) => {
  const { input, meta } = useField(name)
  const fieldIsInvalid = FormBindings.isFieldInvalid(meta)

  return (
    <>
      {props.label && (
        <Box mb={6}>
          <ProsapientLabel color="beta.500" required={props.required}>
            {props.label}
          </ProsapientLabel>
        </Box>
      )}
      <ProsapientRichTextEditor
        {...input}
        {...FormBindings.omitFieldConfigProperties(props)}
        invalid={fieldIsInvalid}
        onChange={v => input.onChange(v)}
      />
      {fieldIsInvalid && <ProsapientHint invalid={fieldIsInvalid}>{meta.error || meta.submitError}</ProsapientHint>}
    </>
  )
}
